<template>
  <div class="driskFactor">
    <template v-if="hasHhsData">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="hhsItems"
        :value="hhsItems[0].text"
        @update:modelValue="onChange"
        item-title="text"
        item-value="value"
        color="0099D9_1"
        style="color: rgb(84, 84, 84) !important"
      ></v-select>
    </template>
    <template v-else-if="hasV05Data">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="v05Items"
        :value="v05Items[0].text"
        @update:modelValue="onChange"
        item-title="text"
        item-value="value"
        style="color: rgb(84, 84, 84) !important"
        color="0099D9_1"
      ></v-select>
    </template>
    <template v-else-if="hasv21ESRDData">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="v21ESRDItems"
        :value="v21ESRDItems[0].text"
        @update:modelValue="onChange"
        style="color: rgb(84, 84, 84) !important"
        item-title="text"
        item-value="value"
        color="0099D9_1"
      ></v-select>
    </template>
    <template v-else-if="hasv24ESRDData">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="v24ESRDItems"
        :value="v24ESRDItems[0].text"
        @update:modelValue="onChange"
        item-title="text"
        item-value="value"
        style="color: rgb(84, 84, 84) !important"
        color="0099D9_1"
      ></v-select>
    </template>
    <template v-else-if="hasPaceV05Data">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="PaceV05Items"
        :value="PaceV05Items[0].text"
        @update:modelValue="onChange"
        item-title="text"
        item-value="value"
        style="color: rgb(84, 84, 84) !important"
        color="0099D9_1"
      ></v-select>
    </template>
    <template v-else-if="hasPaceV21ESRDData">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="v21PaceESRDItems"
        :value="v21PaceESRDItems[0].text"
        @update:modelValue="onChange"
        item-title="text"
        item-value="value"
        style="color: rgb(84, 84, 84) !important"
        color="0099D9_1"
      ></v-select>
    </template>
    <template v-else-if="hasPaceVData">
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="paceItems"
        :value="paceItems[0].text"
        @update:modelValue="onChange"
        item-title="text"
        item-value="value"
        style="color: rgb(84, 84, 84) !important"
        color="0099D9_1"
      ></v-select>
    </template>
    <template v-else>
      <v-select
        class="drfbox-2 drfbox-2-laptop"
        dense
        flat
        outlined
        :items="items"
        :value="items[0].text"
        @update:modelValue="onChange"
        item-title="text"
        item-value="value"
        style="color: rgb(84, 84, 84) !important"
        color="0099D9_1"
      ></v-select>
    </template>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import riskModelVItems from '../../data/riskModelVItems.json'
import riskModelV05Items from '../../data/riskModelV05Items.json'
import riskModelHHSItems from '../../data/riskModelHHSItems.json'
import riskModelESRDV21Items from '../../data/riskModelESRDV21Items.json'
import riskModelESRDV24Items from '../../data/riskModelESRDV24Items.json'
import riskModelPaceESRDV21Items from '../../data/riskModelPaceESRDV21Items.json'
import riskModelPaceV05Items from '../../data/riskModelPaceV05Items.json'
import riskModelPaceVItems from '../../data/riskModelPaceVItems.json'

const props = defineProps({
  componentDataIndex: Number,
  riskModel: String,
  dosYear: String,
})

const emit = defineEmits(['onRiskModel'])

const store = useStore()
const items = ref(riskModelVItems)
const paceItems = ref(riskModelPaceVItems)
const v05Items = ref(riskModelV05Items)
const PaceV05Items = ref(riskModelPaceV05Items)
const hhsItems = ref(riskModelHHSItems)
const v21ESRDItems = ref(riskModelESRDV21Items)
const v24ESRDItems = ref(riskModelESRDV24Items)
const v21PaceESRDItems = ref(riskModelPaceESRDV21Items)

const hasHhsData = computed(() => store.getters.hasHhsData(props.dosYear))
const hasV05Data = computed(() => store.getters.hasV05Data(props.dosYear))
const hasPaceV05Data = computed(() =>
  store.getters.hasPaceV05Data(props.dosYear)
)
const hasv21ESRDData = computed(() =>
  store.getters.hasv21ESRDData(props.dosYear)
)
const hasPaceV21ESRDData = computed(() =>
  store.getters.hasPaceV21ESRDData(props.dosYear)
)
const hasv24ESRDData = computed(() =>
  store.getters.hasv24ESRDData(props.dosYear)
)
const hasPaceVData = computed(() => store.getters.hasPaceVData(props.dosYear))

const onChange = (value) => {
  emit('onRiskModel', value)
}
</script>
