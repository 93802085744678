/**
 * Scrolls to the bottom of an element with a specific ID based on source and index
 * @param {string} source - Source identifier for the element
 * @param {number|string} index - Index identifier for the element
 */
export const scrollToBottom = (source, index) => {
  const dynamicId = `${source}-result-table-${index}`
  const div = document.getElementById(dynamicId)
  if (div) {
    div.scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    })
  } else {
    console.warn(`Element with id "${dynamicId}" not found`)
  }
}

/**
 * Scrolls to the bottom of a container element with a specific class based on index
 * @param {number|string} index - Index identifier for the element
 */
export const scrollToBottomContainer = (index) => {
  const dynamicClass = `profile-main-${index}`
  const elements = document.getElementsByClassName(dynamicClass)
  if (elements.length > 0) {
    elements[0].scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    })
  } else {
    console.warn(`No elements with class "${dynamicClass}" found`)
  }
}

/**
 * Scrolls to the top of the app container
 */
export const scrollToTop = () => {
  const appElement = document.getElementById('app')

  if (appElement) {
    appElement.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    })
  } else {
    console.warn('App element not found')
  }
}
