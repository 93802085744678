<template>
  <v-layout>
    <v-text-field
      class="baseratetextField compare-baserate"
      dense
      flat
      outlined
      :value="baseRate"
      @input="getBaseRate"
      color="#0099d9"
      type="number"
      :error-messages="invalidBaseRate ? ['Enter valid base rate'] : []"
      autocomplete="off"
    ></v-text-field>
  </v-layout>
</template>
<script>
export default {
  props: {
    baseRate: Number,
    isBaseRateInvalid: Boolean,
  },
  computed: {
    invalidBaseRate: {
      get: function () {
        this.baseRate < 0 || isNaN(parseInt(this.baseRate))
          ? false
          : this.$emit('validBaseRate', true)
        return this.isBaseRateInvalid
      },
      set: function (value) {
        this.isBaseRateInvalid = value
      },
    },
  },
  mounted() {
    this.$emit('onBaseRate', parseInt(this.baseRate))
  },
  data() {
    return {
      errorMessage: 'Enter valid Base rate',
    }
  },
  methods: {
    getBaseRate(value) {
      this.$emit('onBaseRate', parseInt(value))
    },
  },
}
</script>
