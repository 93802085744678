<template>
  <v-layout>
    <v-select
      class="genderfield"
      density="compact"
      variant="outlined"
      :items="items"
      :model-value="modelValue"
      item-title="title"
      item-value="value"
      color="#0099d9"
      bg-color="white"
      base-color="grey"
      style="color: rgb(84, 84, 84)"
      @update:model-value="onChange"
    ></v-select>
  </v-layout>
</template>

<script setup>
import { ref } from 'vue'

// Define props
const props = defineProps({
  modelValue: {
    type: String,
    default: 'M',
  },
  componentDataIndex: {
    type: Number,
    default: null,
  },
})

// Define emits
const emit = defineEmits(['update:modelValue', 'onGender'])

// Define gender items
const items = ref([
  { title: 'F', value: 'f' },
  { title: 'M', value: 'm' },
])

// Handle gender change
const onChange = (value) => {
  emit('update:modelValue', value)
  emit('onGender', value)
}
</script>
