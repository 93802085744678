<template>
  <v-layout>
    <v-text-field
      class="agetextfield"
      density="compact"
      variant="outlined"
      :model-value="modelValue"
      @update:model-value="updateAge"
      color="0099D9_1"
      type="number"
      :error-messages="errorMessages"
      autocomplete="off"
      :min="0"
      :max="110"
    ></v-text-field>
  </v-layout>
</template>
<script setup>
import { ref, computed, watch } from 'vue'
// Define props with default values and validation
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: 65, // Provide a default value of 65 here
  },
  validateOnMount: {
    type: Boolean,
    default: true,
  },
})
// Define emits
const emit = defineEmits(['update:modelValue', 'validAge', 'onAge'])
// Reactive error state
const errorState = ref(false)
// Compute error messages
const errorMessages = computed(() => {
  return errorState.value ? ['Enter valid age'] : []
})
// Validate age function
const validateAge = (value) => {
  const numValue = value === '' ? null : Number(value)
  const isValid =
    numValue !== null && !isNaN(numValue) && numValue >= 0 && numValue <= 110
  errorState.value = !isValid
  emit('validAge', isValid)
  return isValid ? numValue : null
}
// Method to update age
const updateAge = (value) => {
  const validatedAge = validateAge(value)
  if (validatedAge !== null) {
    emit('update:modelValue', validatedAge)
    emit('onAge', validatedAge)
  }
}
// Watch for initial prop value
watch(
  () => props.modelValue,
  (newValue) => {
    if (props.validateOnMount) {
      validateAge(newValue)
    }
  },
  { immediate: true }
)
</script>
