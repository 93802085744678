<template>
  <v-card>
    <div class="search-card">
      <v-btn
        v-bind:style="[
          current_path != '/hccreference/'
            ? current_path != '/'
              ? { display: 'none' }
              : { display: 'block' }
            : { display: 'block' },
        ]"
        small
        class="close-btn-search"
        @click="resetSearch()"
        fab
        right
        absolute
      >
        <!-- <v-icon>mdi-close</v-icon> -->
        <signet-icon icon="close"></signet-icon>
      </v-btn>
      <div class="search-box">
        <div
          v-bind:style="[
            current_path != '/hccreference/'
              ? current_path != '/'
                ? { width: '140%' }
                : { width: '100%' }
              : { width: '100%' },
          ]"
        >
          <div
            class="search-menu"
            v-bind:style="[
              current_path != '/hccreference/'
                ? current_path != '/'
                  ? { width: '100% !important' }
                  : {}
                : {},
            ]"
          >
            <div class="filter-field-container search-block">
              <span class="codingChoiceLabel">
                Conditions
                <v-icon
                  color="black"
                  class="p-1"
                  size="12"
                  dark
                  v-tippy="{
                    theme: 'epicc',
                    arrow: true,
                    content: tooltipDataForCondition,
                  }"
                  >fas fa-question-circle</v-icon
                >
              </span>
              <v-text-field
                class="dxTextField"
                placeholder="Search here"
                color="#0099d9"
                dense
                flat
                outlined
                v-model="queryReset"
                v-on:input="debounceInput"
                autofocus
              ></v-text-field>
            </div>

            <div
              class="filter-field-container"
              v-bind:style="[
                current_path != '/hccreference/'
                  ? current_path != '/'
                    ? { 'padding-left': '16px' }
                    : { width: '100%' }
                  : { width: '100%' },
              ]"
            >
              <span class="codingChoiceLabel">DX/HCC</span>
              <v-select
                class="search-coding-choice"
                flat
                dense
                color="#0099d9"
                style="color: #545454"
                outlined
                :items="codingChoices"
                v-model="codingValue"
                item-title="text"
                item-value="value"
                @change="elasticSearch('typeChange')"
              />
            </div>
            <div class="filter-field-container">
              <span class="search-dos-choice">DOS Year/Model</span>
              <v-select
                class="search-dos-choice"
                flat
                dense
                outlined
                style="color: #545454"
                color="#0099d9"
                :items="dosChoices"
                item-title="text"
                item-value="value"
                v-model="dosValue"
                @change="setDosYear"
              ></v-select>
            </div>
            <div class="filter-field-container">
              <span class="drfChoicelabel">Risk Model</span>
              <template v-if="hasHhsData">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  style="color: #545454"
                  :items="drfhhsItems"
                  v-model="DefaultdrfValue"
                  item-title="text"
                  item-value="value"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasV05Data">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  style="color: #545454"
                  outlined
                  :items="drfv05Items"
                  v-model="DefaultdrfValue"
                  item-title="text"
                  item-value="value"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasv21ESRDData">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  style="color: #545454"
                  :items="drfvEsrdV21Items"
                  item-title="text"
                  item-value="value"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasv24ESRDData">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  style="color: #545454"
                  :items="drfEsrdv24Items"
                  v-model="DefaultdrfValue"
                  item-title="text"
                  item-value="value"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasPaceV05Data">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  :items="drfPaceV05Items"
                  item-title="text"
                  item-value="value"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasPaceVData">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  :items="drfPaceItems"
                  item-title="text"
                  item-value="value"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else-if="hasPaceV21ESRDData">
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  :items="drfPaceEsrdV21Items"
                  item-title="text"
                  item-value="value"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
              <template v-else>
                <v-select
                  class="search-drf-choice"
                  flat
                  dense
                  color="#0099d9"
                  outlined
                  style="color: #545454"
                  :items="drfItems"
                  item-title="text"
                  item-value="value"
                  v-model="DefaultdrfValue"
                  @change="elasticSearch('typeChange')"
                ></v-select>
              </template>
            </div>
            <div class="filter-field-container age-block">
              <span class="drfChoicelabel">Age</span>
              <v-text-field
                :value="age"
                dense
                outlined
                type="number"
                class="agetextfield"
                @input="onChangeAgeInput"
                color="#0099d9"
                :error-messages="invalidAge ? ['Enter valid age'] : []"
                autocomplete="off"
              ></v-text-field>
            </div>
          </div>
          <div class="search-result-box" @scroll="handleLazyLoading">
            <v-progress-circular
              indeterminate
              color="red"
              v-bind:class="[
                current_path != '/hccreference/'
                  ? current_path != '/'
                    ? 'loaderStyleRef'
                    : 'loaderStyle'
                  : 'loaderStyle',
              ]"
              v-if="isActive == true"
            ></v-progress-circular>
            <div
              class="message-over-search"
              v-bind:style="[
                current_path != '/hccreference/'
                  ? current_path != '/'
                    ? { display: 'none' }
                    : { display: 'block' }
                  : { display: 'block' },
              ]"
            >
              <p>Click row to add condition to selection</p>
            </div>

            <table class="search-table">
              <thead>
                <tr>
                  <th
                    v-if="isCodingValueSetToDX"
                    class="search-header-th-left search-inner-th-individual"
                    style="width: 19.1%"
                  >
                    DX Code
                  </th>
                  <th
                    v-if="isCodingValueSetToHCC"
                    class="search-header-th-left search-inner-th-individual"
                    style="width: 19.1%"
                  >
                    HCC
                    <span v-if="current_path == '/hccreference'">
                      <v-icon
                        color="black"
                        class="p-1"
                        size="12"
                        dark
                        v-tippy="{
                          theme: 'epicc',
                          arrow: true,
                          content: tooltipDataForHccRef,
                        }"
                        >fas fa-question-circle</v-icon
                      >
                    </span>
                  </th>
                  <th class="search-inner-th-individual" style="width: 38.6%">
                    Description
                  </th>
                  <th
                    v-if="isCodingValueSetToDX"
                    class="search-inner-th-individual"
                    style="width: 15.7%"
                  >
                    HCC
                  </th>
                  <th
                    v-if="hasHhsData"
                    class="search-inner-th-individual"
                    style="width: 15.7%"
                  >
                    Plate
                  </th>
                  <th
                    v-if="isCodingValueSetToHCC"
                    class="search-inner-th-individual"
                    style="width: 15.7%"
                  >
                    Trump Codes
                  </th>
                  <th class="search-inner-th-individual" style="width: 18.5%">
                    RAF
                  </th>
                  <th
                    class="search-inner-th-individual"
                    style="width: 25.5%"
                    v-bind:class="[
                      current_path != '/hccreference/'
                        ? current_path != '/'
                          ? 'search-header-th-right'
                          : 'search-header-th-right'
                        : 'search-header-th-right',
                    ]"
                  >
                    Currently Selected
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in tableData"
                  :key="item.code"
                  v-bind:class="{
                    selectedRow: item.code === selectedItem.code,
                  }"
                >
                  <td style="cursor: pointer" @click="selectDx(item)">
                    <p class="search-table-left" v-if="isCodingValueSetToDX">
                      {{ item.code }}
                    </p>
                    <p v-if="isCodingValueSetToHCC" class="search-table-left">
                      <span
                        v-if="current_path == '/hccreference'"
                        @click="changeSearch(item.code)"
                        >{{ item.code }}</span
                      >
                      <span v-else>{{ item.code }}</span>
                    </p>
                  </td>
                  <td
                    class="text-xs-right"
                    style="cursor: pointer"
                    @click="selectDx(item)"
                  >
                    <p class="search-table-left">{{ item.description }}</p>
                  </td>
                  <td
                    v-if="isCodingValueSetToDX"
                    style="cursor: pointer"
                    @click="selectDx(item, 'hcc')"
                  >
                    <span v-for="hcc in item.hcc" :key="hcc">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on" @mouseover="showHccDetail(hcc)">
                            {{ hcc }}
                            <span
                              v-bind:style="[
                                item.hcc.length > 1
                                  ? item.hcc.lastIndexOf(hcc) ==
                                    item.hcc.length - 1
                                    ? { display: 'none' }
                                    : {}
                                  : { display: 'none' },
                              ]"
                              >,</span
                            >
                          </span>
                        </template>
                        <v-progress-circular
                          indeterminate
                          color="white"
                          :size="20"
                          v-if="isHoverActive == true"
                        ></v-progress-circular>
                        <span v-else>{{ onHoverData }}</span>
                      </v-tooltip>
                    </span>
                  </td>
                  <td v-if="hasHhsData">
                    <p class="search-table-left">
                      {{ item.plate }}
                    </p>
                  </td>
                  <td v-if="isCodingValueSetToHCC" class="text-xs-right">
                    <span v-for="trump in item.trumps" :key="trump">
                      <span>
                        {{ trump }}
                        <span
                          v-bind:style="[
                            item.trumps.length > 1
                              ? item.trumps.lastIndexOf(trump) ==
                                item.trumps.length - 1
                                ? { display: 'none' }
                                : {}
                              : { display: 'none' },
                          ]"
                          >,</span
                        >
                      </span>
                    </span>
                  </td>
                  <td>
                    <p class="search-table-right">{{ item.raf }}</p>
                  </td>
                  <td
                    v-bind:style="[
                      current_path != '/hccreference/'
                        ? current_path != '/'
                          ? { border: 'none', 'background-color': 'white' }
                          : {
                              padding: '10px',
                              display: table - cell,
                            }
                        : {
                            display: 'flex',
                            'justify-content': 'center',
                            'align-items': 'center',
                            padding: '10px',
                          },
                    ]"
                  >
                    <div class="tickmark-container">
                      <div
                        v-for="data in selectedDxData"
                        v-bind:key="data.code"
                        v-bind:style="[
                          current_path != '/hccreference/'
                            ? current_path != '/'
                              ? { display: 'none' }
                              : {
                                  display: 'flex',
                                  'justify-content': 'center',
                                  'align-items': 'center',
                                }
                            : checkSelectCondition(item, data)
                              ? {
                                  display: 'flex',
                                  'justify-content': 'center',
                                  'align-items': 'center',
                                }
                              : {
                                  display: 'none',
                                },
                        ]"
                      >
                        <p
                          class="tickmark"
                          v-if="checkSelectCondition(item, data)"
                        >
                          <i class="fas fa-check"></i>
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="selected_dx_box"
          v-bind:style="[
            current_path != '/hccreference/'
              ? current_path != '/'
                ? { display: 'none' }
                : { display: 'block' }
              : { display: 'block' },
          ]"
        >
          <div class="selectedDxBox">
            <div
              v-if="selectedRowsIDs.length == 0"
              class="no-condition-selected"
            >
              <p>No Conditions Selected</p>
            </div>
            <div v-else class="selectDxTable">
              <p class="selected-condition">Selected Conditions</p>
              <p class="message-over-search-select">
                Click
                <i class="fas fa-times"></i> to remove condition from list.
              </p>
              <div class="selected-table">
                <table class="selected-table-table">
                  <thead>
                    <tr class="selected-table-head-tr">
                      <th
                        style="width: 29%; border-right: 1px solid white"
                        class="search-header-th-left"
                      >
                        Code
                      </th>
                      <th style="width: 59%" class="search-inner-th-individual">
                        Description
                      </th>
                      <th style="width: 59%" class="search-header-th-right">
                        Remove
                      </th>
                    </tr>
                  </thead>
                  <!-- Table Body -->
                  <tbody>
                    <tr
                      v-for="item in selectedDxData"
                      :key="item.name"
                      class="selected-table-data-tr"
                    >
                      <td v-if="item.hcc_selected">
                        <span v-for="hcc in item.hcc" :key="hcc">
                          {{ hcc }}
                          <span
                            v-bind:style="[
                              item.hcc.length > 1
                                ? item.hcc.lastIndexOf(hcc) ==
                                  item.hcc.length - 1
                                  ? { display: 'none' }
                                  : {}
                                : { display: 'none' },
                            ]"
                          >
                            <br />
                          </span>
                        </span>
                      </td>
                      <td v-else>{{ item.code }}</td>
                      <td
                        v-if="item.hcc_selected"
                        class="text-xs-right"
                        v-html="getHccDescription(item)"
                      ></td>
                      <td v-else class="text-xs-right">
                        {{ item.description.substring(0, 60) + '..' }}
                      </td>
                      <td>
                        <div
                          v-for="data in selectedDxData"
                          :key="data.code"
                          class="tickmark"
                        >
                          <p
                            v-if="item.code == data.code"
                            style="cursor: pointer"
                            @click="removeDx(item)"
                            :class="{
                              removeDxRow: item.code === selectedItem.code,
                            }"
                          >
                            <i class="fas fa-times"></i>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="text-align: right; margin-right: 25px">
              <v-btn
                v-if="selectedRowsIDs.length != 0"
                class="confirm-selected-button submit button"
                color="info"
                @click="fillSelectedDx"
                >{{ confirm }}</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-snackbar
      min-width="auto"
      v-model="snackbar"
      :timeout="timeout"
      class="snackbar"
    >
      <div class="snackbar-text">{{ message }}</div>
    </v-snackbar>
  </v-card>
</template>

<style scoped src="../../assets/css/search.css"></style>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { LRUCache } from 'lru-cache'
import _ from 'lodash'

// Import JSON data
import riskModelVItems from '../../data/riskModelVItemsSearch.json'
import riskModelV05Items from '../../data/riskModelV05Items.json'
import riskModelHHSItems from '../../data/riskModelHHSItems.json'
import riskModelESRDV21Items from '../../data/riskModelESRDV21Items.json'
import riskModelESRDV24Items from '../../data/riskModelESRDV24Items.json'
import riskModelPaceESRDV21Items from '../../data/riskModelPaceESRDV21Items.json'
import riskModelPaceV05Items from '../../data/riskModelPaceV05Items.json'
import riskModelPaceVItems from '../../data/riskModelPaceVItems.json'
import dosYearData from '../../data/dosYearData.json'

// Create LRU cache
const cache = new LRUCache({
  max: 500,
  ttl: 1000 * 60 * 60, // 1 hour in milliseconds
  // Custom length calculation could be added if needed
})

// Store
const store = useStore()

// Reactive state
const age = ref(65)
const current_path = ref(window.location.pathname)
const onHoverData = ref('')
const searchCodeData = ref([])
const searchTerm = ref('')
const queryReset = ref('')
const tooltipDataForHccRef = ref(
  'Click on Hcc to show all the related Dx Codes'
)
const codingChoices = ref([
  { text: 'DX', value: 'dx' },
  { text: 'HCC', value: 'hcc' },
])
const codingValue = ref('dx') // default coding method
const dosChoices = ref(dosYearData)
const buttonStyle = ref({
  cursor: 'default',
})
const dosValue = ref('v24_2022') // default DOS Value
const confirm = ref('Confirm')
const dxTableHeaders = ref([
  {
    text: 'DX Code',
    sortable: false,
    value: 'code',
  },
  { text: 'Description', value: 'description' },
  { text: 'HCC', value: 'hcc' },
  { text: 'RAF', value: 'raf' },
  { text: 'Select', value: 'Select', class: 'blankColHeader' },
])
const local = ref([])
const dxData = ref([])
const selectedDxTableHeaders = ref([
  {
    text: 'DX Code',
    sortable: false,
    value: 'code',
  },
  { text: 'Description', value: 'description' },
])
const selectedDxData = ref([])
const selectedRowsIDs = ref([])
const selectedItem = ref(-1)
const tableData = ref({})
const drfItems = ref(riskModelVItems)
const drfv05Items = ref(riskModelV05Items)
const drfhhsItems = ref(riskModelHHSItems)
const drfPaceItems = ref(riskModelPaceVItems)
const drfPaceV05Items = ref(riskModelPaceV05Items)
const drfPaceEsrdV21Items = ref(riskModelPaceESRDV21Items)
const drfvEsrdV21Items = ref(riskModelESRDV21Items)
const drfEsrdv24Items = ref(riskModelESRDV24Items)
const v05Data = ref([
  'v08_2024',
  'v08_2023',
  'v08_2022',
  'v05_2021',
  'v05_2020',
  'v05_2019',
  'v05_2018',
  'v05_2017',
])
const vData = ref([
  'v28_2024',
  'v24_2024',
  'v28_2023',
  'v24_2023',
  'v24_2022',
  'v24_2021',
  'v24_2020',
  'v24_2019',
  'v23_2018',
  'v22_2017',
])
const hhsData = ref([
  'hhs_v07_2024',
  'hhs_v07_2023',
  'hhs_v07_2022',
  'hhs_v05_2021',
  'hhs_v05_2020',
  'hhs_v05_2019',
  'hhs_v05_2018',
])
const v21_esrd_data = ref(['esrd_v21_2020', 'esrd_v21_2021'])
const v24_esrd_data = ref(['esrd_v24_2022', 'esrd_v24_2023', 'esrd_v24_2024'])
const PaceV05Data = ref(['pace_v05_2023', 'pace_v05_2024'])
const PaceVData = ref(['pace_v22_2023', 'pace_v22_2024'])
const pace_v21_esrd_data = ref(['pace_esrd_v21_2023', 'pace_esrd_v21_2024'])

const DefaultdrfValue = ref('CN')
const search_url = ref('/api/search')
const hover_url = ref('/api/search/hover')
const errorMessage = ref('Enter valid age')
const isActive = ref(false)
const dialog = ref(false)
const totalEntries = ref(0)
const endData = ref(false)
const tooltipDataForCondition = ref(
  'Conditions eligible as per selected dos year model.'
)
const timeout = ref(4000)
const message = ref('Codes are already selected')
const snackbar = ref(false)
const isHoverActive = ref(false)
const isAgeInvalid = ref(false)

// Computed properties
const hasHhsData = computed(() => {
  return hhsData.value.includes(getDosValue(dosValue.value))
})

const hasV05Data = computed(() => {
  return v05Data.value.includes(getDosValue(dosValue.value))
})

const hasv21ESRDData = computed(() => {
  return v21_esrd_data.value.includes(getDosValue(dosValue.value))
})

const hasv24ESRDData = computed(() => {
  return v24_esrd_data.value.includes(getDosValue(dosValue.value))
})

const hasPaceV05Data = computed(() => {
  return PaceV05Data.value.includes(getDosValue(dosValue.value))
})

const hasPaceV21ESRDData = computed(() => {
  return pace_v21_esrd_data.value.includes(getDosValue(dosValue.value))
})

const hasPaceVData = computed(() => {
  return PaceVData.value.includes(getDosValue(dosValue.value))
})

const isCodingValueSetToHCC = computed(() => {
  return store.getters.hccCodingValue(codingValue.value)
})

const isCodingValueSetToDX = computed(() => {
  return store.getters.dxCodingValue(codingValue.value)
})

const returnSearchDrfValue = computed(() => {
  let defaultDrf = DefaultdrfValue.value
  let finalDrfValue = ''

  const search_v21_esrd = {
    C1: 'CG',
    C2: 'CG',
    D: 'CED',
    I2: 'IG',
    G1: 'KT',
    G2: 'KT',
    ED: 'NED',
    E2: 'E2',
  }

  const search_v24_esrd = {
    C3: parseInt(age.value) < 65 ? 'GFD' : 'GFA',
    C4: parseInt(age.value) < 65 ? 'GND' : 'GNA',
    C5: parseInt(age.value) < 65 ? 'GND' : 'GNA',
    C6: parseInt(age.value) < 65 ? 'GFD' : 'GFA',
    C7: parseInt(age.value) < 65 ? 'GND' : 'GNA',
    C8: parseInt(age.value) < 65 ? 'GND' : 'GNA',
    D1: 'CED',
    D2: 'CED',
    ED: 'NED',
    E1: 'E1',
    E2: 'E2',
    G1: 'KT',
    G2: 'KT',
    I3: 'CEDI',
    I4: 'CEDI',
    I5: 'GI',
    I6: 'GI',
    I7: 'GI',
    I8: 'GI',
    I9: 'GI',
    IA: 'GI',
  }

  const search_v05 = {
    D1: parseInt(age.value) < 65 ? 'CNN' : 'CAN',
    D2: parseInt(age.value) < 65 ? 'CLN' : 'CLA',
    D3: 'INS',
    D4: 'NEN',
    D5: 'NEN',
    D6: 'NEL',
    D7: 'NEL',
    D8: 'NEI',
    D9: 'NEI',
  }

  const search_vData = {
    CN: parseInt(age.value) < 65 ? 'CND' : 'CNA',
    CP: parseInt(age.value) < 65 ? 'CPD' : 'CPA',
    CF: parseInt(age.value) < 65 ? 'CFD' : 'CFA',
    I: 'INS',
  }

  const search_pace_v_Data = {
    PK: parseInt(age.value) < 65 ? 'PKD' : 'PKA',
    PI: parseInt(age.value) < 65 ? 'PID' : 'PIA',
    PJ: parseInt(age.value) < 65 ? 'PJD' : 'PJA',
    I: 'INS',
    E: 'NE',
    SE: 'SNPNE',
  }

  const search_pace_v21_esrd = {
    PA: 'CED',
    PB: 'NED',
    PC: 'CG',
    PF: 'CG',
    PD: 'IG',
    PG: 'IG',
    PL: 'KT',
    PM: 'KT',
    PE: 'E1',
    PH: 'E2',
  }

  const search_pace_v05 = {
    P1: 'NEL',
    P2: 'NEN',
    P3: 'NEI',
    P4: 'NEI',
    P5: 'NEN',
    P6: 'NEN',
    P7: parseInt(age.value) < 65 ? 'CNN' : 'CAN',
    P8: parseInt(age.value) < 65 ? 'CLN' : 'CLA',
    P9: 'INS',
  }

  if (defaultDrf in search_vData) {
    finalDrfValue = search_vData[defaultDrf]
  } else if (defaultDrf in search_v05) {
    finalDrfValue = search_v05[defaultDrf]
  } else if (defaultDrf in search_pace_v05) {
    finalDrfValue = search_pace_v05[defaultDrf]
  } else if (defaultDrf in search_pace_v21_esrd) {
    finalDrfValue = search_pace_v21_esrd[defaultDrf]
  } else if (defaultDrf in search_pace_v_Data) {
    finalDrfValue = search_pace_v_Data[defaultDrf]
  } else if (defaultDrf in search_v21_esrd) {
    finalDrfValue = search_v21_esrd[defaultDrf]
  } else if (defaultDrf in search_v24_esrd) {
    finalDrfValue = search_v24_esrd[defaultDrf]
  } else {
    finalDrfValue = defaultDrf
  }

  return finalDrfValue
})

const invalidAge = computed({
  get: () => {
    // Emit event if needed (would need to be defined differently in setup)
    if (parseInt(age.value) > 0) {
      emit('validAge', true)
    }
    return isAgeInvalid.value
  },
  set: (value) => {
    isAgeInvalid.value = value
  },
})

// Methods
const debounceInput = _.debounce((e) => {
  if (e !== 'age') {
    searchTerm.value = e
    totalEntries.value = 0
    tableData.value = {}
    endData.value = false
  }
  elasticSearch()
}, 500)

const showHccDetail = (item) => {
  /*This function is using caching technique to show data on search hover 
  and also fetch data from /api/hover if data is not avail on its cache*/
  const item_hcc = item
  if (cache.has(item_hcc)) {
    onHoverData.value = cache.get(item_hcc)
  } else {
    isHoverActive.value = true
    let drfValue = returnSearchDrfValue.value
    let hover_data = {
      dx_hcc: 'hcc',
      dos_year: dosValue.value,
      drf: drfValue,
      hcc: item_hcc,
    }

    axios.post(hover_url.value, hover_data).then((response) => {
      if (typeof response.data == 'object') {
        let data_received = Object.assign({}, response.data)
        onHoverData.value = data_received.description
        cache.set(data_received.code, data_received.description)
      }
      isHoverActive.value = false
      snackbar.value = false
    })
  }
}

const getHccDescription = (item) => {
  let result = ''
  item.hcc.forEach((hcc) => {
    result += `${cache.get(hcc)}<br>`
  })
  return result
}

const selectDx = (item, label = 'dx') => {
  if (!isActive.value && !isHoverActive.value) {
    if (label == 'hcc') {
      if (
        item.hcc.every((hcc) =>
          selectedDxData.value.some((selected_item) =>
            selected_item.hcc.includes(hcc)
          )
        )
      ) {
        message.value = 'Codes are already selected'
        snackbar.value = true
        return null
      } else {
        selectedItem.value = item
        item.selected = true
        item.hcc_selected = true
        item.hcc.forEach((hcc) => showHccDetail(hcc))
      }
    } else {
      selectedItem.value = item
      item.selected = true
    }

    if (
      snackbar.value == false &&
      selectedRowsIDs.value.indexOf(item.code) === -1
    ) {
      selectedRowsIDs.value.push(item.code)
      selectedDxData.value.push(item)
    }
  } else {
    message.value = 'Please wait for data to load'
    snackbar.value = true
  }
}

const checkSelectCondition = (item, data) => {
  return item.code == data.code
}

const removeDx = (item) => {
  item.hcc_selected = false
  const index0 = selectedRowsIDs.value.indexOf(item.code)
  const index = selectedDxData.value.indexOf(item)
  if (index0 > -1) {
    selectedDxData.value.splice(index, 1)
    selectedRowsIDs.value.splice(index0, 1)
  }
}

const changeTable = () => {
  if (codingValue.value == 'hcc') {
    dxTableHeaders.value[0].text = 'HCC'
    dxTableHeaders.value[2].text = 'Trump Codes'
  }
  if (codingValue.value == 'dx') {
    dxTableHeaders.value[0].text = 'DX Code'
    dxTableHeaders.value[2].text = 'HCC'
  }
}

const getDosValue = (dosValueString) => {
  const start = dosValueString.indexOf('/') + 1 // find first occurrence of / and treat it as a starting point
  const end = dosValueString.length // get length of the dosValue and treat it as end point.
  const updatedDosValue = dosValueString.slice(start, end)
  return updatedDosValue.trim() // return v22 or v23 or v24 respectively from the chosen value.
}

const onChangeAgeInput = (value) => {
  age.value = value
  debounceInput('age')
}

const setDosYear = () => {
  if (hhsData.value.includes(getDosValue(dosValue.value))) {
    DefaultdrfValue.value = 'ADULT'
  } else if (v05Data.value.includes(getDosValue(dosValue.value))) {
    DefaultdrfValue.value = 'D1'
  } else if (v21_esrd_data.value.includes(getDosValue(dosValue.value))) {
    DefaultdrfValue.value = 'C1'
  } else if (PaceVData.value.includes(getDosValue(dosValue.value))) {
    DefaultdrfValue.value = 'PK'
  } else if (pace_v21_esrd_data.value.includes(getDosValue(dosValue.value))) {
    DefaultdrfValue.value = 'PA'
  } else if (PaceV05Data.value.includes(getDosValue(dosValue.value))) {
    DefaultdrfValue.value = 'P7'
  } else if (v24_esrd_data.value.includes(getDosValue(dosValue.value))) {
    DefaultdrfValue.value = 'C3'
  } else {
    DefaultdrfValue.value = 'CN'
  }

  elasticSearch('typeChange')
}

const handleLazyLoading = ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop + clientHeight >= scrollHeight - 150) {
    if (isActive.value == false && endData.value == false) {
      elasticSearch()
    }
  }
}

const elasticSearch = (e) => {
  if (e == 'typeChange') {
    totalEntries.value = 0
    tableData.value = {}
    endData.value = false
  }

  const drfValue = returnSearchDrfValue.value
  changeTable()
  isActive.value = true

  const search_data = {
    dx_hcc: codingValue.value,
    dos_year: getDosValue(dosValue.value),
    drf: drfValue,
    search: searchTerm.value,
    start: totalEntries.value,
  }

  axios.post(search_url.value, search_data).then((response) => {
    isActive.value = false
    if (typeof response.data == 'object') {
      if (Object.keys(response.data).length < 20) endData.value = true
      for (let key in Object.keys(response.data)) {
        tableData.value[Number(totalEntries.value) + Number(key)] =
          response.data[key]
      }
      totalEntries.value = Object.keys(tableData.value).length
    }
  })
}

const resetSearch = () => {
  // Close modal immediately
  dialog.value = false

  // Reset other data
  age.value = 65
  onHoverData.value = ''
  searchCodeData.value = []
  searchTerm.value = ''
  queryReset.value = ''
  codingValue.value = 'dx'
  dosValue.value = 'v24_2022'
  selectedDxData.value = []
  selectedRowsIDs.value = []
  selectedItem.value = -1
  tableData.value = {}
  DefaultdrfValue.value = 'CN'
  isActive.value = false
  totalEntries.value = 0
  endData.value = false
  snackbar.value = false
  isHoverActive.value = false
  searchTerm.value = ' '
  debounceInput('')
  emit('confirmAction')
}

const fillSelectedDx = () => {
  localStorage.setItem('selectedSearchData', '')
  selectedDxData.value.map((data) => {
    if (data.hcc_selected == true) searchCodeData.value.push(data.hcc)
    else searchCodeData.value.push(data.code)
  })
  localStorage.setItem('selectedSearchData', searchCodeData.value)
  resetSearch() // before emit resets all the variables
}

const changeSearch = (item_code) => {
  queryReset.value = item_code
  codingValue.value = 'dx'
  debounceInput(queryReset.value)
  elasticSearch()
}

// Define emits (if needed)
const emit = defineEmits(['validAge', 'confirmAction'])

// Expose methods and variables to template
// In Vue 3 script setup, everything is automatically exposed to the template
</script>
