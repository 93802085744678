<template>
  <div class="relative">
    <form @submit.prevent>
      <div class="search-container">
        <v-text-field
          ref="condition"
          color="#0099d9"
          placeholder="Enter DX/HCC code here"
          v-model="tag"
          @keyup.comma="addTag"
          @keyup.semicolon="addTag"
          @keyup.space="addTag"
          @keydown.enter.prevent="addTag"
          @keydown.tab.prevent="addTag"
          @paste="getPastedCodes"
          @input="debounceInput"
          density="compact"
          variant="outlined"
          clearable
          clear-icon="mdi-close-circle"
          @click:clear="onClear"
          class="dxsearchinputbox"
          autocomplete="off"
          :error-messages="
            updateErrorMessage ? ['Enter conditions to proceed'] : []
          "
        ></v-text-field>
        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-btn
              variant="outlined"
              size="small"
              :class="searchButtonClass"
              @click.prevent="toggleModal(true)"
              v-bind="props"
              color="grey"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </template>
          <span>Search for DX / HCC</span>
        </v-tooltip>
      </div>

      <v-dialog v-model="dialog" max-width="80%">
        <SearchComponent
          v-if="dialog"
          :dialog="dialog"
          @update:dialog="dialog = $event"
          @confirm-action="toggleModal(false)"
        />
      </v-dialog>
    </form>

    <div class="box">
      <v-snackbar v-model="snackbar">
        <div class="snackbar-text">
          {{ errorMessage }}
        </div>
        <template #actions>
          <v-btn color="pink" variant="text" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-card
        class="overflow-y-auto overflow-x-auto mx-auto condition-box"
        outlined
      >
        <v-card-text>
          <v-chip-group multiple column>
            <v-chip
              tabindex="-1"
              ref="textchip"
              class="chip-tag"
              label
              text-color="white"
              v-for="tag in tags"
              :key="tag"
              :id="tag"
              closable
              @click:close="remove(tag)"
              :style="{
                backgroundColor: invalidChips.includes(tag)
                  ? 'red !important'
                  : notFoundCodes.includes(tag)
                    ? '#FFBF00 !important'
                    : '#008C50 !important',
              }"
            >
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <span v-bind="props" @mouseover="showTagInfo(tag)">{{
                    tag
                  }}</span>
                </template>
                <span>{{ hoverDesc }}</span>
                <br />
                <span v-if="!isInvalidTag && hoverHcc != 'NA'"
                  >HCC: {{ hoverHcc }} ,</span
                >
                <span v-if="!isInvalidTag">RAF: {{ hoverRaf }}</span>
              </v-tooltip>
            </v-chip>
          </v-chip-group>
        </v-card-text>
      </v-card>

      <ChipsIndicator
        :invalidChips="invalidChips"
        :tags="tags"
        :notFoundCodes="notFoundCodes"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, nextTick, ref, watch } from 'vue'
import SearchComponent from './SearchComponent.vue'
import api from '../../services/api'
import ChipsIndicator from './ChipsIndicator'
import _ from 'lodash'
import { LRUCache } from 'lru-cache'
import store from '../../store'
import axios from 'axios'

const options = {
  max: 500,
  length: function (n, key) {
    return n * 2 + key.length
  },
  maxAge: 1000 * 60 * 60,
}
const cache = new LRUCache(options)

const props = defineProps({
  componentDataIndex: Number,
  tagList: Array,
  tagstring: String,
  isClearTag: Boolean,
  riskModel: String,
  dosYearModel: String,
  isEmptyTagList: Boolean,
  commandType: String,
  searchBox: String,
})

const emit = defineEmits([
  'clearTagFlag',
  'getTag',
  'emptyTagsFlag',
  'removeTag',
  'invalidChips',
  'codesNotFound',
])

// Use reactive state with computed properties instead of refs
const riskModel_ = computed({
  get: () => props.riskModel,
  set: (value) => (props.riskModel = value),
})

const dosYear_ = computed({
  get: () => props.dosYearModel,
  set: (value) => (props.dosYearModel = value),
})

const tag_ = computed({
  get: () => props.tagstring,
  set: (value) => (props.tagstring = value),
})

const tags = computed({
  get: () => props.tagList,
  set: (value) => (props.tagList = value),
})

// Convert regular variables to refs for reactivity
const dialog = ref(false)
const tag = ref(props.tagstring)
const tagStringFromPaste = ref('')
const searchData = ref([])
const conditionsList = ref([])
const hoverDesc = ref('Loading...')
const hoverRaf = ref(0)
const hoverHcc = ref('Loading...')
const tagCache = ref('')
const isInvalidTag = ref(false)
const snackbar = ref(false)
const notFoundCodes = ref([])
const errorMessage = ref('Enter conditions to proceed')
const search_url = ref('/api/get_codes')

// Use ref for _invalidChips instead of array
const _invalidChips = ref([])
const invalidChips = computed(() => _invalidChips.value)

const isClearTags = computed({
  get: () => props.isClearTag,
  set: (value) => {
    tags.value = []
    emit('clearTagFlag', false)
  },
})

const updateErrorMessage = computed({
  get: () => props.isEmptyTagList,
  set: (value) => (props.isEmptyTagList = value),
})

const searchButtonClass = computed({
  get: () =>
    props.commandType === 'member'
      ? 'member-search-button'
      : 'compare-search-button',
  set: (value) => (props.commandType = value),
})

// Computed for notFoundCodes
const notFoundCodesComputed = computed(() => notFoundCodes.value)

const debounceInput = _.debounce(() => {
  handleChange()
}, 500)

const onClear = () => {
  setTimeout(() => {
    tag.value = ''
    store.state.search_member = []
    store.state.search_member1 = []
    store.state.search_suggestion_loader = false
  }, 500)
}

const toggleModal = (value) => {
  dialog.value = value
  let SearchedCodeString = localStorage.getItem('selectedSearchData')
  if (SearchedCodeString != null) {
    let SearchedCodeList = SearchedCodeString.split(',')
    SearchedCodeList.forEach((item) => {
      if (!tags.value.includes(item) && item.length > 0) {
        tags.value.push(item)
        validateAndCacheTag(item)
      }
      emit('getTag', tags.value)
      emit('emptyTagsFlag', false)
    })
    localStorage.removeItem('selectedSearchData')
  }
}

const isInvalidChar = (string) => !/^[a-z0-9_]+$/i.test(string)
const isInTagList = (tagValue) => tags.value.includes(tagValue)

const convertDecimalCodeToString = (string) => {
  if (isNaN(string)) {
    string = string.replace('.', '')
  }
  return string
}

const addHCCPrefix = (string) => {
  if (!isNaN(Number(string)) && Number(string) != 0) {
    if (
      store.state.componentData[
        props.componentDataIndex
      ].inputDict.dosYearModel.startsWith('hhs')
    )
      string = 'HHS_HCC' + string
    else string = 'HCC' + string
  }
  return string
}

const getPastedCodes = (pasteEvent) => {
  let pastedCode = pasteEvent.clipboardData.getData('text')
  tagStringFromPaste.value =
    tagStringFromPaste.value != ''
      ? tagStringFromPaste.value + ',' + pastedCode
      : pastedCode
}

const addTagFromList = (item) => {
  tag.value = item
  let keyevent = { key: 'Enter' }
  addTag(keyevent)
}

const addTag = (keyevent) => {
  tag.value =
    typeof tag.value != 'undefined'
      ? addHCCPrefix(convertDecimalCodeToString(tag.value.toUpperCase()))
      : ''
  if (
    (keyevent.key == 'Enter' || keyevent.key == 'Tab') &&
    !isInvalidChar(tag.value) &&
    !isInTagList(tag.value) &&
    tagStringFromPaste.value == ''
  ) {
    tags.value.push(tag.value)
    validateAndCacheTag(tag.value)
    emit('getTag', tags.value)
    emit('emptyTagsFlag', false)
  } else if (tagStringFromPaste.value != '') {
    createTagOnPaste(tagStringFromPaste.value)
    tag_.value = ''
    tag.value = ''
  }

  let templist = tag.value.split(keyevent.key)
  for (let tempTag in templist) {
    templist[tempTag] = addHCCPrefix(
      convertDecimalCodeToString(templist[tempTag])
    )
  }

  for (let i = 0; i < templist.length - 1; i++) {
    if (!isInvalidChar(templist[i]) && !isInTagList(templist[i])) {
      tags.value.push(templist[i])
      validateAndCacheTag(templist[i])
    }
    emit('getTag', tags.value)
    emit('emptyTagsFlag', false)
  }

  tag.value = ''
  store.state.search_member = []
  store.state.search_member1 = []
}

const createTagOnBlur = () => {
  addTag({ key: 'Enter' })
}

const removedNotFoundCodes = (item) => {
  notFoundCodes.value = notFoundCodes.value.filter((value) => value != item)
}

const removeInvalidChips = (item) => {
  const index = _invalidChips.value.indexOf(item)
  if (index >= 0) {
    _invalidChips.value.splice(index, 1)
  }
}

const remove = (item) => {
  const index = tags.value.indexOf(item)
  if (index >= 0) {
    tags.value.splice(index, 1)
    removeInvalidChips(item)
    removedNotFoundCodes(item)
    emit('removeTag', tags.value)
  }
}

const revalidateTags = (dos) => {
  _invalidChips.value = []
  notFoundCodes.value = []
  tags.value.forEach((tag) => {
    validateAndCacheTag(tag, dos)
  })
}

const resetInvalidChips = () => {
  _invalidChips.value = []
  notFoundCodes.value = []
}

const validateAndCacheTag = (tagValue, ...restArgs) => {
  let version = restArgs[0] ? restArgs[0] : props.dosYearModel
  let parameter = { code: tagValue, version: version }
  let cacheKey = `${version}:${tagValue}`
  if (!cache.has(cacheKey)) {
    api
      .getHoverData(parameter)
      .then((response) => {
        cache.set(cacheKey, response.data)
        tagCache.value = cache.get(cacheKey)
        if (
          !tagCache.value.available &&
          !tagCache.value.hasOwnProperty('available_in_other_version')
        ) {
          _invalidChips.value.push(tagValue)
        }
        if (
          tagCache.value.hasOwnProperty('available_in_other_version') &&
          tagCache.value.available_in_other_version
        ) {
          notFoundCodes.value.push(response.data.code)
        }
        emit('invalidChips', invalidChips.value)
        emit('codesNotFound', notFoundCodesComputed.value)
      })
      .catch(() => {
        hoverDesc.value = 'Loading...'
        hoverRaf.value = 0
        hoverHcc.value = 'Loading...'
      })
    emit('invalidChips', invalidChips.value)
  }
}

const showTagInfo = (tagValue) => {
  let onHoverTagInfo = cache.get(`${dosYear_.value}:${tagValue}`)
  if (typeof onHoverTagInfo != 'undefined') {
    isInvalidTag.value = onHoverTagInfo.available == false ? true : false
    hoverDesc.value = !onHoverTagInfo.available
      ? 'Invalid Code'
      : onHoverTagInfo.description
    let riskModelValue = riskModel_.value
    let ageValue =
      store.state.componentData[props.componentDataIndex].inputDict.age

    if (
      riskModel_.value == 'CN' ||
      riskModel_.value == 'CP' ||
      riskModel_.value == 'CF'
    ) {
      riskModelValue =
        ageValue >= 65 ? riskModel_.value + 'A' : riskModel_.value + 'D'
    } else if (riskModel_.value == 'D1') {
      riskModelValue =
        'C' +
        ((ageValue || ageValue === 0 || ageValue === '0' || 65) < 65
          ? 'N'
          : 'A') +
        'N'
    } else if (riskModel_.value == 'D2') {
      riskModelValue =
        'CL' +
        ((ageValue || ageValue === 0 || ageValue === '0' || 65) < 65
          ? 'N'
          : 'A')
    } else if (riskModel_.value == 'D3') {
      riskModelValue = 'INS'
    } else if (riskModel_.value == 'D4' || riskModel_.value == 'D5') {
      riskModelValue = 'NEN'
    } else if (riskModel_.value == 'D6' || riskModel_.value == 'D7') {
      riskModelValue = 'NEL'
    } else if (riskModel_.value == 'D8' || riskModel_.value == 'D9') {
      riskModelValue = 'NEI'
    } else if (riskModel_.value == 'C1' || riskModel_.value == 'C2') {
      riskModelValue = 'CG'
    } else if (riskModel_.value == 'D') {
      riskModelValue = 'CED'
    } else if (riskModel_.value == 'I1' || riskModel_.value == 'I2') {
      riskModelValue = 'IG'
    } else if (riskModel_.value == 'G1' || riskModel_.value == 'G2') {
      riskModelValue = 'KT'
    } else if (riskModel_.value == 'ED') {
      riskModelValue = 'NED'
    } else if (riskModel_.value == 'E1') {
      riskModelValue = 'E1'
    } else if (riskModel_.value == 'E2') {
      riskModelValue = 'E2'
    }

    // This condition is checked because from backend these versions are coming as v22 and v23
    // We cannot change this entire mapping in the frontend because of member api that is using these complete versions
    if (dosYear_.value == 'v22_2017' || dosYear_.value == 'v23_2018') {
      dosYear_.value = dosYear_.value.split('_')[0]
    }

    hoverRaf.value = !onHoverTagInfo.available
      ? ''
      : onHoverTagInfo[dosYear_.value][riskModelValue]

    if (!onHoverTagInfo.code.includes('HCC')) {
      hoverHcc.value = !onHoverTagInfo.available
        ? ''
        : onHoverTagInfo.hcc.join(',')
    } else {
      hoverHcc.value = 'NA'
    }
  } else {
    validateAndCacheTag(tagValue)
  }
}

const splitStringAndGetValidTags = (
  string,
  separators = [' ', ',', ':', ';']
) => {
  if (string !== '' || !/\s/g.test(string)) {
    let tempChar = separators[0] // Using the first separator as a temporary join character
    separators.forEach((separator) => {
      string = string.split(separator).join(tempChar)
    })
    let validTags = []
    string.split(tempChar).forEach((el) => {
      el = addHCCPrefix(convertDecimalCodeToString(el))
      if (!isInvalidChar(el)) {
        validTags.push(el)
      }
    })
    if (validTags.length === 0) {
      errorMessage.value = 'The pasted codes have invalid characters.'
      snackbar.value = true
    }
    return validTags
  }
}

const createTagOnPaste = (pastedString) => {
  let tagListFromPaste = splitStringAndGetValidTags(pastedString)
  tagListFromPaste.forEach((pastedTag) => {
    pastedTag = pastedTag.trim().toUpperCase()
    if (!isInvalidChar(pastedTag) && !isInTagList(pastedTag)) {
      validateAndCacheTag(pastedTag)
      tags.value.push(pastedTag)
    }
  })
  emit('getTag', tags.value)
  tagStringFromPaste.value = ''
}

const returnSearchDrfValue = computed(() => {
  let defaultDrf =
    store.state.componentData[props.componentDataIndex].inputDict.riskModel
  let finalDrfValue = ''
  let age = store.state.componentData[props.componentDataIndex].inputDict.age

  let search_v21_esrd = {
    C1: 'CG',
    C2: 'CG',
    D: 'CED',
    I2: 'IG',
    G1: 'KT',
    G2: 'KT',
    ED: 'NED',
    E2: 'E2',
  }

  let search_v24_esrd = {
    C3: parseInt(age) < 65 ? 'GFD' : 'GFA',
    C4: parseInt(age) < 65 ? 'GND' : 'GNA',
    C5: parseInt(age) < 65 ? 'GND' : 'GNA',
    C6: parseInt(age) < 65 ? 'GFD' : 'GFA',
    C7: parseInt(age) < 65 ? 'GND' : 'GNA',
    C8: parseInt(age) < 65 ? 'GND' : 'GNA',
    D1: 'CED',
    D2: 'CED',
    ED: 'NED',
    E1: 'E1',
    E2: 'E2',
    G1: 'KT',
    G2: 'KT',
    I3: 'CEDI',
    I4: 'CEDI',
    I5: 'GI',
    I6: 'GI',
    I7: 'GI',
    I8: 'GI',
    I9: 'GI',
    IA: 'GI',
  }

  let search_v05 = {
    D1: parseInt(age) < 65 ? 'CNN' : 'CAN',
    D2: parseInt(age) < 65 ? 'CLN' : 'CLA',
    D3: 'INS',
    D4: 'NEN',
    D5: 'NEN',
    D6: 'NEL',
    D7: 'NEL',
    D8: 'NEI',
    D9: 'NEI',
  }

  let search_vData = {
    CN: parseInt(age) < 65 ? 'CND' : 'CNA',
    CP: parseInt(age) < 65 ? 'CPD' : 'CPA',
    CF: parseInt(age) < 65 ? 'CFD' : 'CFA',
    I: 'INS',
  }

  let search_pace_v_Data = {
    PK: parseInt(age) < 65 ? 'PKD' : 'PKA',
    PI: parseInt(age) < 65 ? 'PID' : 'PIA',
    PJ: parseInt(age) < 65 ? 'PJD' : 'PJA',
    I: 'INS',
    E: 'NE',
    SE: 'SNPNE',
  }

  let search_pace_v21_esrd = {
    PA: 'CED',
    PB: 'NED',
    PC: 'CG',
    PF: 'CG',
    PD: 'IG',
    PG: 'IG',
    PL: 'KT',
    PM: 'KT',
    PE: 'E1',
    PH: 'E2',
  }

  let search_pace_v05 = {
    P1: 'NEL',
    P2: 'NEN',
    P3: 'NEI',
    P4: 'NEI',
    P5: 'NEN',
    P6: 'NEN',
    P7: parseInt(age) < 65 ? 'CNN' : 'CAN',
    P8: parseInt(age) < 65 ? 'CLN' : 'CLA',
    P9: 'INS',
  }

  if (defaultDrf in search_vData) {
    finalDrfValue = search_vData[defaultDrf]
  } else if (defaultDrf in search_v05) {
    finalDrfValue = search_v05[defaultDrf]
  } else if (defaultDrf in search_pace_v05) {
    finalDrfValue = search_pace_v05[defaultDrf]
  } else if (defaultDrf in search_pace_v21_esrd) {
    finalDrfValue = search_pace_v21_esrd[defaultDrf]
  } else if (defaultDrf in search_pace_v_Data) {
    finalDrfValue = search_pace_v_Data[defaultDrf]
  } else if (defaultDrf in search_v21_esrd) {
    finalDrfValue = search_v21_esrd[defaultDrf]
  } else if (defaultDrf in search_v24_esrd) {
    finalDrfValue = search_v24_esrd[defaultDrf]
  } else {
    finalDrfValue = defaultDrf
  }

  return finalDrfValue
})

const handleChange = () => {
  store.state.search_box = tag.value
  store.state.search_suggestion_loader = true

  if (tag.value.length < 2) {
    store.state.search_member = []
    store.state.search_member1 = []
    store.state.search_box = ''
    store.state.search_suggestion_loader = false
    return
  }

  const val =
    !isNaN(tag.value) ||
    ['HC', 'RX', 'HH', 'hc', 'rx', 'hh'].includes(tag.value.substring(0, 2)) ||
    ['37.1', '37.2'].includes(tag.value)
      ? 'hcc'
      : 'dx'

  const search_data = {
    dx_hcc: val,
    dos_year: dosYear_.value,
    drf: returnSearchDrfValue.value,
    search: tag.value,
    start: 0,
  }
  axios.post(search_url, search_data).then((response) => {
    store.state.search_suggestion_loader = false
    store.state.search_member = []
    store.state.search_member1 = []

    if (tag.value !== '') {
      response.data.forEach((item) => {
        store.state.search_member.push(item.code)
      })
    }
  })
}

const handleClickOutside = () => {
  tag.value = ''
  store.state.search_member = []
  store.state.search_member1 = []
  store.state.search_suggestion_loader = false
}

// Watch for props.tagstring changes
watch(
  () => props.tagstring,
  (newValue) => {
    tag.value = newValue
  }
)

// Lifecycle hooks
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<style>
.snackbar > div > div {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.snackbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.member-search-suggestion {
  width: 86%;
  background-color: white;
  height: auto;
  max-height: 125px;
  overflow: auto;
  position: absolute;
  top: 40px;
  left: 0px;
  padding-left: 0px !important;
  border-radius: 0px 0px 5px 5px;
}
.member-search-suggestion > ul > li {
  list-style: none;
  text-align: left;
  padding-left: 12px;
  cursor: pointer;
  min-height: 40px;
  padding: 0px 16px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  width: 100%;
}
.member-search-suggestion > ul {
  max-height: 114px;
  width: 100%;
  padding: 0px !important;
}
.member-search-suggestion > ul > li:hover {
  color: white;
  background-color: rgb(84, 84, 84);
}
.suggestion-box {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 10px !important;
}
.member-search-loader {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.show-box {
  min-height: 60px !important;
  text-align: center;
}
.search-container {
  display: flex;
}
</style>
