<template>
  <footer class="main-footer">
    <a class="main-footer-email"> © 2024 Episource</a>
    <div class="main-footer-links">
      <a href="https://www.episource.com/privacy/">Privacy</a>
      <a href="https://www.episource.com/terms-of-use/" target="_blank"
        >Terms of Use</a
      >
      <a href="support">Support</a>
    </div>
  </footer>
</template>

<style scoped>
.main-footer {
  height: 50px;
  text-align: center;
  background: #f3f1ee;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.main-footer-email {
  height: 100%;
  padding: 2px;
  color: rgb(84, 84, 84);
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}
.main-footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2px;
  gap: 15px;
}
.main-footer-links a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  color: rgb(84, 84, 84);
  font-size: 16px;
  font-weight: 400;
}
.main-footer-links a:hover {
  color: #008c50 !important;
}
.main-footer-email {
  cursor: default;
}

@media only screen and (max-device-width: 767px) {
  .main-footer-links a {
    font-size: 14px;
    font-weight: 300;
  }
  .main-footer-email {
    font-size: 14px;
    font-weight: 300;
  }
}
</style>
