<template>
  <!-- <v-layout> -->
  <div>
    <v-text-field
      :value="calculationYear"
      dense
      outlined
      type="number"
      class="calculation__year-field"
      @change="emitCalculationYear"
      color="#0099d9"
      placeholder="Enter Calculation year"
      autocomplete="off"
    >
    </v-text-field>
    <p v-if="error" class="error-calculationyear">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    calculationYear: Number,
    dob: String,
    isInvalidCalculationYear: Boolean,
    error: String,
  },

  data() {
    return {
      errorMessage: null,
    }
  },

  watch: {
    error: function (newVal) {
      return newVal
    },
  },

  methods: {
    isValidDate(calculationYear) {
      let date = new Date(this.dob)
      return calculationYear >= date.getFullYear()
    },
    emitCalculationYear(calculationYear) {
      let isDateValid = this.isValidDate(calculationYear)
      if (isDateValid) {
        this.$emit('calculationYearUpdate', Number(calculationYear))
      } else {
        this.$emit('calculationYearUpdate', Number(calculationYear))
      }
    },
  },
}
</script>
