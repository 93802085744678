<template>
  <div :class="['profile-main profile-main-' + props.componentDataIndex]">
    <v-row v-if="showTabs" class="box-header show-tabs">
      <v-col
        cols="4"
        sm="2"
        :class="[
          'select-button show-tabs member-tab',
          {
            active: componentData.inputDict.commandType === 'member',
          },
        ]"
        @click="() => updateCommandAndStore('member')"
      >
        <p class="dropdown-member">Member Profile</p>
      </v-col>

      <v-col
        cols="4"
        sm="2"
        :class="[
          'select-button show-tabs compare-tab',
          {
            active: componentData.inputDict.commandType === 'compare',
          },
        ]"
        @click="() => updateCommandAndStore('compare')"
      >
        <p class="dropdown-member">Compare codes</p>
      </v-col>
    </v-row>

    <v-row v-else class="box-header small">
      <v-col cols="1" sm="3" class="select-button">
        <v-select
          dense
          flat
          outlined
          :items="items"
          class="dropdown-member select-class-tc"
          :model-value="componentData.inputDict.commandType"
          @update:model-value="updateCommandAndStore"
        ></v-select>
      </v-col>
    </v-row>

    <MemberProfile
      v-if="componentData.inputDict.commandType === 'member'"
      :componentDataIndex="componentDataIndex"
    />

    <CompareCode
      v-else-if="componentData.inputDict.commandType === 'compare'"
      :componentDataIndex="componentDataIndex"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import CompareCode from './functions/CompareCode.vue'
import MemberProfile from './functions/MemberProfile.vue'
import '../../assets/css/__global__.css'

const store = useStore()

const props = defineProps({
  componentDataIndex: {
    type: Number,
    required: true,
  },
})

const selectedCommand = ref('')
const items = ref([
  { text: 'Pick a command', value: 'pick', disabled: true },
  { text: 'Member Profile', value: 'member' },
  { text: 'Compare Codes', value: 'compare' },
])

const showTabs = ref(window.innerWidth >= 495)

const componentData = computed(() =>
  store.getters.getComponentDataByIndex(props.componentDataIndex)
)

const updateCommandAndStore = (value) => {
  selectedCommand.value = value

  const propertyNames = [
    'memberProfileConditions',
    'baseConditions',
    'newConditions',
    'commandType',
  ]

  propertyNames.forEach((name) => {
    const propertyValue = name !== 'commandType' ? [] : selectedCommand.value

    store.dispatch('updateStateData', {
      index: props.componentDataIndex,
      data: { name, value: propertyValue },
    })
  })
}

const changeCommandTitle = () => {
  if (props.componentDataIndex > 0) {
    items.value[0].text = 'Pick another command'
  }
}

onMounted(() => {
  changeCommandTitle()
})
</script>

<style>
.dropdown-member,
.dropdown-member * {
  color: #fff !important;
}

.profile-main {
  margin-bottom: 120px;
}

.profile-main.profile-main-0 {
  margin-top: 200px !important;
}

.select-button:hover {
  cursor: pointer;
}
</style>
