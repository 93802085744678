<template>
  <table class="parsed_excel">
    <thead>
      <tr>
        <th v-for="i in _parsedExcel[0]" :key="i">
          {{ i }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, idx) in _parsedExcel.slice(1, _parsedExcel.length)"
        :key="idx"
      >
        <td v-for="(col, id) in row" :key="id">{{ col }}</td>
      </tr>
    </tbody>
  </table>
</template>
<style src="../../assets/css/csvUpload.css"></style>
<script>
export default {
  props: {
    parsedExcel: Array,
  },
  computed: {
    _parsedExcel() {
      return this.parsedExcel
    },
  },
}
</script>
