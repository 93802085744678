import { createApp } from 'vue'
import App from './App.vue'

// Import Vue Router and Store
import router from './router'
import store from './store'

// Import Vuetify Plugin
import vuetify from './plugins/vuetify'

// Import Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

// Add icons to the library
library.add(faMagnifyingGlass)

// Create Vue App
const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

import { applyPolyfills, defineCustomElements } from '@episource/signet/loader'

applyPolyfills().then(() => {
  defineCustomElements()
})

// Use Plugins
app.use(router)
app.use(store)
app.use(vuetify)

// Mount the App
app.mount('#app')
