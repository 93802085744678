<template>
  <div>
    <div
      :class="[
        'header-main',
        current_path === '/privacy'
          ? 'support header-change privacy'
          : current_path === '/eula'
            ? 'support eula header-change'
            : current_path.replace('/', ''),
        source,
      ]"
      :style="getImgUrl"
    >
      <div
        :class="['header-main-block', current_path.replace('/', ''), source]"
        :style="headerLogoStyle"
      >
        <a
          v-if="source !== 'patch'"
          href="https://episource.com"
          :style="logoStyle"
          :class="['header-main-img', source]"
        >
          <img
            v-if="source !== 'patch'"
            :class="['header-main-img', source]"
            src="../../assets/img/epi-logo.svg"
          />
        </a>
        <div :class="['header-main-block-title', source]">
          <a
            :href="current_path"
            :class="[
              'hexader-main-block-title',
              current_path.replace('/eula', 'eula'),
            ]"
          >
            {{ getImgUrl.hcc_text }}
          </a>
        </div>
      </div>

      <div
        v-if="source !== 'patch'"
        :class="['side-nav', current_path.replace('/', '')]"
      >
        <nav
          v-if="source !== 'patch'"
          :class="['header-main-block-link', current_path.replace('/', '')]"
        >
          <a
            v-if="
              source !== 'patch' &&
              current_path != '/reference' &&
              current_path != '/reference/' &&
              reference_host != 'www.hccreference.com' &&
              reference_host != 'hccreference.com'
            "
            :href="
              reference_host == 'hccexplorer.com'
                ? 'https://www.hccreference.com/'
                : '/reference'
            "
            class="episource-home-link"
            target="_blank"
          >
            <p
              style="color: #545454"
              @mouseover="showGif('show_reference_gif')"
              @mouseleave="hideGif('show_reference_gif')"
            >
              HCC Reference
            </p>
          </a>
          <img
            style="opacity: 0"
            :class="[
              show_reference_gif &&
              source !== 'patch' &&
              current_path != '/reference' &&
              reference_host != 'www.hccreference.com' &&
              reference_host != 'hccreference.com'
                ? 'tool-gif'
                : '',
              'gif reference-gif',
              current_path.replace('/', ''),
            ]"
            src="../../assets/img/hcc_reference.gif"
            alt="HCC Reference"
          />
          <a
            v-if="
              source !== 'patch' &&
              (current_path != '/' ||
                reference_host == 'www.hccreference.com' ||
                reference_host == 'hccreference.com')
            "
            :href="
              explorer_host == 'localhost'
                ? '/'
                : 'https://www.hccexplorer.com/'
            "
            class="episource-home-link"
            target="_blank"
          >
            <p
              style="color: #545454"
              @mouseover="showGif('show_explorer_gif')"
              @mouseleave="hideGif('show_explorer_gif')"
            >
              HCC Explorer
            </p>
          </a>
          <img
            style="opacity: 0"
            :class="[
              show_explorer_gif &&
              source !== 'patch' &&
              (current_path != '/' ||
                reference_host == 'www.hccreference.com' ||
                reference_host == 'hccreference.com')
                ? 'tool-gif'
                : '',
              'gif explorer-gif',
            ]"
            src="../../assets/img/hcc_explorer.gif"
            alt="HCC Explorer"
          />
          <a
            v-if="source !== 'patch'"
            href="https://appsource.microsoft.com/en-in/product/office/WA200001947"
            class="episource-home-link"
            target="_blank"
          >
            <p
              style="color: #545454"
              @mouseover="showGif('show_toolkit_gif')"
              @mouseleave="hideGif('show_toolkit_gif')"
              class="p-gif1"
            >
              RA Toolkit
            </p>
          </a>
          <img
            style="opacity: 0"
            :class="[
              show_toolkit_gif && source !== 'patch' ? 'tool-gif' : '',
              'gif',
              current_path.replace('/', ''),
            ]"
            src="../../assets/img/ra_toolkit.gif"
            alt="RA Toolkit"
          />
          <!-- PatchNotesCommenting -->
          <!-- <span
            v-if="current_path == '/support'"
            class="release-notes-title"
            @click="handleToggleModal()"
            >Release Notes</span
          > -->
        </nav>
      </div>
      <!-- Replace Chakra UI components with appropriate Vue 3 components -->
      <div class="navbar-hamburger" v-if="source !== 'patch'">
        <button
          class="hamburger-button"
          @click="isMenuOpen = !isMenuOpen"
          style="
            padding: 8px 16px;
            transition: all 0.2s;
            border-radius: 4px;
            border-width: 1px;
          "
        >
          <span class="hamburger-icon">☰</span>
        </button>
        <div v-if="isMenuOpen" class="hamburger-list">
          <div
            class="hamburger-item"
            v-if="source !== 'patch' && current_path != '/reference'"
          >
            <a
              :href="
                reference_host == 'localhost'
                  ? '/reference'
                  : 'https://www.hccreference.com/'
              "
              class="episource-home-link"
              target="_blank"
            >
              <p style="color: #545454">HCC Reference</p>
            </a>
          </div>
          <div class="hamburger-item" v-if="source !== 'patch'">
            <a
              href="https://appsource.microsoft.com/en-in/product/office/WA200001947"
              class="episource-home-link"
              target="_blank"
            >
              <p style="color: #545454">RA Toolkit</p>
            </a>
          </div>
          <div
            class="hamburger-item"
            v-if="source !== 'patch' && current_path != '/'"
          >
            <a
              :href="
                explorer_host == 'localhost'
                  ? '/'
                  : 'https://www.hccexplorer.com/'
              "
              class="episource-home-link"
              target="_blank"
            >
              <p style="color: #545454">HCC Explorer</p>
            </a>
          </div>
          <!-- PatchNotesCommenting -->
          <!-- <div
            class="hamburger-item"
            v-if="current_path == '/support'"
          >
            <span
              class="episource-home-link"
              @click="handleToggleModal()"
              >Release Notes</span
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.side-nav.support {
  margin-right: 5%;
  vertical-align: center;
}
.side-nav {
  margin-right: 10%;
}
.side-nav > nav {
  display: flex;
  float: right;
}

.side-nav > nav > a {
  padding-left: 30px;
}

.header-main.patch {
  display: flex;
  height: auto;
  width: 100%;
}

.header-main-block.patch {
  justify-content: space-between;
  width: 100%;
}

.header-main-img.patch {
  margin-left: 0 !important;
}

.header-main-block-title.patch {
  /* margin-right: 30px; */
  padding: 0;
  margin-left: -5px;
}

.hexader-main-block-title.eula {
  font-size: 27px !important;
}

.gif {
  display: none !important;
  position: absolute;
  top: 60px;
  width: 400px;
  transition: 0.3s ease;
  right: 50px;
  box-shadow: rgba(0, 140, 80, 0.15) 0 5px 10px;
  border-radius: 7px;
  border: 1px solid rgba(0, 140, 80, 0.3);
  opacity: 0;
  z-index: -1000000200;
}

.tool-gif {
  opacity: 1 !important;
  display: inline !important;
}

.toolkit-gif.support {
  margin-left: 100px;
}

.reference-gif.support {
  display: block;
  right: 170px;
}
.navbar-hamburger {
  display: none !important;
}
.css-1xyqtup {
  display: none !important;
}
#menu-MUz {
  display: none;
}
.hamburger-item:focus {
  background-color: white !important;
}
.hamburger-button:focus {
  box-shadow: none !important;
}
.close-notif {
  position: absolute !important;
  top: 20% !important;
  right: 10px !important;
}
@media only screen and (max-device-width: 767px) {
  .css-1xyqtup {
    display: block !important;
    width: 50px;
    height: 50px;
  }
  .side-nav {
    display: none !important;
  }
  .header-main {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .hamburger-button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important ;
    background-color: #f3f1ee !important;
    margin-right: 10px;
  }
  .hamburger-item {
    background-color: white !important;
    border-bottom: 2px solid #f3f1ee !important;
    padding: 10px;
  }
  .hamburger-list {
    padding: 0px !important;
  }
  .header-main-block-title {
    min-width: 200px !important;
  }
  .hexader-main-block-title {
    font-size: 27px !important;
  }
  .header-main-img {
    margin-left: 0% !important;
  }
  .hamburger-list {
    min-width: 10rem !important ;
  }

  .header-main.privacy {
    margin-top: 0px !important;
  }
  .header-main.eula {
    margin-top: 0px !important;
  }
  .search-menu {
    display: block !important;
  }
  .filter-field-container {
    padding: 5px !important;
  }
  .search-dos-choice {
    margin-left: 0px !important;
  }
  .hexader-main-block-title.eula {
    font-size: 27px !important;
  }
  .eula-container {
    width: 100%;
    margin-top: 50px !important ;
    margin-bottom: 40px;
  }
  .search-result-box {
    margin-left: 25px !important;
  }
  .search-menu {
    margin-left: 10px !important;
    width: 100% !important;
    padding-right: 22px !important;
  }
  .top-review-bar {
    align-items: start !important;
  }
  .selected_dx_box {
    position: static !important;
  }
  .search-box {
    display: block !important;
  }
  .selectedDxBox {
    margin-top: 0px !important;
    padding-bottom: 16px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .search-result-box {
    min-height: 250px !important;
    margin-left: 16px !important;
  }
  .support-page-container {
    padding-top: 50px !important;
  }
  .compare-box {
    display: block !important;
    padding-left: 8% !important;
    padding-right: 8% !important;
    margin: 0% 5% 0% 5% !important;
  }
  .compare-code-calculation-year {
    margin-top: 14px !important;
  }
  .compare-code-dos-year {
    margin-top: 14px !important;
  }
  .header-main-img {
    width: 140px !important;
  }
  .header-main-block-title {
    margin-left: 0px !important;
    padding-right: 0px !important;
  }
  .hexader-main-block-title {
    font-size: 20px !important;
  }
  .hamburger-button {
    position: absolute !important;
    top: 18px !important;
    right: 0px !important;
    margin-right: 0px !important;
    padding: 0px !important;
  }
  .search-inner-box {
    width: 100% !important;
  }
  .header-main-block-title.patch > a {
    font-size: 20px !important;
  }
  .summary {
    display: block !important;
  }
  .summary-table-header {
    padding-bottom: 2.5% !important;
  }
  .loaderStyleRef {
    margin-left: 40% !important;
    margin-top: 40% !important;
  }
  .header-main-block.support {
    margin-left: 0px !important;
    width: 24%;
  }
  .heading-box {
    margin-left: 25px !important;
  }
  .each-topic-container {
    margin-left: 25px !important;
  }
  .confirm-selected-button {
    color: #fff;
    font-weight: 500;
    border: none;
    width: 150px;
    background-color: #008c50 !important;
    height: 48px !important;
  }
  .submitclear-member {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
  }
  .submitclear-member > button {
    margin: 0px !important;
    font-size: 12px !important;
    width: 75px !important;
  }
  .confirm-selected-button {
    width: 85px !important;
    font-size: 12px !important;
    height: 40px !important;
  }
}
@media only screen and (max-device-width: 1100px) {
  .patch-notes-model {
    max-width: 90% !important;
  }
  .patch-notes-title {
    font-size: 16px !important;
  }
  .patch-notes-points {
    font-size: 10px !important;
  }
  .patch-notes-date {
    font-size: 8px !important;
  }
  .rating-msg {
    font-size: 12px !important;
  }
  .dont-show-input {
    font-size: 8px !important;
  }
  .submitclear {
    margin-top: 10px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .css-1xyqtup {
    display: block !important;
    width: 50px;
    height: 50px;
  }
  .hamburger-item {
    background-color: white !important;
    border-bottom: 2px solid #f3f1ee !important;
    padding: 10px;
  }

  .side-nav {
    display: none !important;
  }
  .hamburger-button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important ;
    background-color: #f3f1ee !important;
    margin-right: 10px;
  }

  .header-main {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .header-main.privacy {
    margin-top: 0px !important;
  }
  .header-main.eula {
    margin-top: 0px !important;
  }
  .hexader-main-block-title.eula {
    font-size: 27px !important;
  }

  .eula-container {
    width: 100%;
    margin-top: 50px !important ;
    margin-bottom: 40px;
  }
  .hamburger-list {
    padding: 0px !important;
  }
  .hamburger-list {
    min-width: 10rem !important ;
  }
  .hamburger-item:focus {
    background-color: white !important;
  }
  .header-main-block.support {
    margin-left: 45px !important;
  }
  .compare-box {
    display: block !important;
    padding-left: 10% !important;
    padding-right: 10% !important;
    margin: 0% 5% 0% 5% !important;
  }
  .compare-code-calculation-year {
    margin-top: 14px !important;
  }
  .compare-code-dos-year {
    margin-top: 14px !important;
  }
  .hexader-main-block-title {
    font-size: 27px !important;
  }
  .header-main-block-title {
    width: 240px !important;
  }
}
</style>
<script setup>
import { computed, ref, reactive, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import '../../assets/css/patchNotes.css'
import '../../assets/css/__global__.css'

// Props definition with defineProps
const props = defineProps({
  source: String,
})

const store = useStore()
const current_path = ref(window.location.pathname)
const host_name = ref(window.location.host)
const sec_domain = ref(process.env.VUE_APP_SEC_DOMAIN)
const sec_domain_1 = ref(process.env.VUE_APP_SEC_DOMAIN_1)
const explorer_host = ref(window.location.host)
const reference_host = ref(window.location.host)
const show_explorer_gif = ref(false)
const show_reference_gif = ref(false)
const show_toolkit_gif = ref(false)
const marginValue = ref(store.state.topMargin || 0)

const windowSize = reactive({
  width: window.innerWidth,
  height: window.innerHeight,
})

const headerLogoStyle = computed(() => {
  if (
    ((current_path.value === '/' || current_path.value === '/reference') &&
      host_name.value !== sec_domain.value &&
      host_name.value !== sec_domain_1.value) ||
    props.source === 'patch'
  ) {
    return { backgroundColor: '#F3F1EE', marginLeft: '5%' }
  }
  return {}
})

const logoStyle = computed(() => {
  if (
    ((current_path.value === '/' || current_path.value === '/reference') &&
      host_name.value !== sec_domain.value &&
      host_name.value !== sec_domain_1.value) ||
    props.source === 'patch'
  ) {
    return { backgroundColor: '#F3F1EE', display: 'flex', marginLeft: '5%' }
  } else if (current_path.value === '/support') {
    return { backgroundColor: '#F3F1EE', display: 'flex' }
  } else if (current_path.value === '/eula') {
    return {
      backgroundColor: '#F3F1EE',
      display: 'flex',
      marginLeft: '2.5%',
    }
  } else {
    return { backgroundColor: '#F3F1EE', display: 'flex', marginLeft: '5%' }
  }
})

const getImgUrl = computed(() => {
  if (
    (current_path.value === '/' ||
      current_path.value === '/reference' ||
      current_path.value === '/reference/') &&
    host_name.value !== sec_domain.value &&
    host_name.value !== sec_domain_1.value
  ) {
    return {
      hcc_text:
        window.location.host === 'hccreference.com' ||
        window.location.host === 'www.hccreference.com' ||
        window.location.href === 'http://localhost/reference' ||
        current_path.value === '/reference' ||
        current_path.value === '/reference/' ||
        window.location.href.includes('reference')
          ? 'HCC Reference'
          : 'HCC Explorer',
    }
  } else if (current_path.value === '/eula') {
    const eula_text =
      windowSize.width < 1453 ? 'EULA' : 'END USER LICENSE AGREEMENT'
    return {
      hcc_text: eula_text,
      position: 'fixed',
      top: '0px',
      zIndex: '100',
      width: '100%',
    }
  } else if (current_path.value === '/privacy') {
    return {
      hcc_text: 'Privacy',
    }
  } else if (current_path.value === '/support') {
    if (props.source === 'patch') {
      return {
        hcc_text: 'Patch Notes',
        height: '250px',
      }
    }

    return {
      hcc_text: 'Support',
      marginTop: store.state.topMargin || 0,
      position: 'fixed',
      top: '0px',
      zIndex: '100',
      width: '100%',
    }
  } else {
    return {}
  }
})

// Methods
function showGif(value) {
  if (value === 'show_explorer_gif') show_explorer_gif.value = true
  if (value === 'show_reference_gif') show_reference_gif.value = true
  if (value === 'show_toolkit_gif') show_toolkit_gif.value = true
}

function hideGif(value) {
  if (value === 'show_explorer_gif') show_explorer_gif.value = false
  if (value === 'show_reference_gif') show_reference_gif.value = false
  if (value === 'show_toolkit_gif') show_toolkit_gif.value = false
}

function handleResize() {
  windowSize.width = window.innerWidth
  windowSize.height = window.innerHeight
}

// Lifecycle hooks
onMounted(() => {
  window.addEventListener('resize', handleResize)
  handleResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>
