<template>
  <div class="maintenance-page">
    <h1>Under Maintenance</h1>
    <p>The app is currently under maintenance. Please check back later.</p>
    <p>Estimated downtime: {{ downtime }}</p>
    <p>
      For any inquiries, please contact us at
      <a href="mailto:support.epicc@episource.com"
        >support.epicc@episource.com</a
      >
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      downtime: 'Until further notice',
    }
  },
}
</script>

<style scoped>
.maintenance-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.maintenance-page h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.maintenance-page p {
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
