<template>
  <div class="datepicker-container">
    <Datepicker
      :model-value="dateOfBirthValue ? new Date(dateOfBirthValue) : null"
      placeholder="Select Date"
      format="d MMMM yyyy"
      @update:model-value="emitDob"
      :teleport="true"
      :enable-time-picker="false"
      :auto-apply="true"
      :hide-input-icon="false"
      input-class="input-picker"
    >
      <template #dp-input="{ value, onInput, onEnter, onTab, onEsc, onBlur }">
        <input
          id="dob-input"
          type="text"
          :value="value"
          @input="onInput"
          @keydown.enter="onEnter"
          @keydown.tab="onTab"
          @keydown.esc="onEsc"
          @blur="onBlur"
          class="date-input"
          placeholder="Select Date"
          readonly
        />
      </template>
    </Datepicker>
  </div>
</template>

<script setup>
import Datepicker from 'vue3-datepicker'

defineProps({
  calculationYear: Number,
  dateOfBirthValue: String,
})

const emit = defineEmits(['dateOfBirthUpdate'])

const emitDob = (dob) => {
  if (!dob) return
  const dateString = new Date(dob)
  const day = dateString.getDate()
  const month = dateString.getMonth() + 1
  const year = dateString.getFullYear()
  const formattedDate = `${day}/${month}/${year}`

  emit('dateOfBirthUpdate', formattedDate)
}
</script>

<style scoped>
.datepicker-container {
  margin-bottom: 20px;
  max-width: fit-content;
  border: 1px solid #a2a2a2;
  padding: 15px 10px;
}

.field-label {
  display: block;
  color: #555;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
}

.info-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #555;
  color: white;
  font-size: 14px;
  margin-left: 5px;
  cursor: help;
}

.date-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  color: #555;
  border: 1px solid #0074d9;
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
  height: 48px;
  cursor: pointer;
  border: 2px solid black;
}

/* Override datepicker popup styles */
:deep(.dp__main) {
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif;
}

:deep(.dp__instance_calendar) {
  width: 100%;
}

:deep(.dp__active_date) {
  background-color: #4caf50 !important;
  color: white;
}

:deep(.dp__today) {
  border: 1px solid #4caf50;
}

/* Remove any built-in labels from the datepicker component */
:deep(.dp__input_wrap label) {
  display: none !important;
}

/* Hide any potential label that might be added by the component */
:deep(.dp__label_wrap) {
  display: none !important;
}

/* Make sure any component-added label is hidden */
:deep(.dp__input_label) {
  display: none !important;
}
</style>
