import { createStore } from 'vuex'
import api from '../../src/services/api'
import patchNotes from '../data/patchNotesDetails'
function roundOffValueOf(number) {
  return Number.isInteger(number) ? number : Number(number).toFixed(3)
}
export default createStore({
  state: {
    componentData: [
      {
        inputDict: {
          age: 65,
          riskModel: 'CN',
          dosYearModel: 'v24_2022',
          gender: 'm',
          ore: 0,
          baseRate: 800,
          calculationYear: 2022,
          DOB: null,
          commandType: 'member',
          plate: 'S',
          enrollmentDuration: '11',
          memberProfileConditions: [],
          baseConditions: [],
          newConditions: [],
        },
        isSubmitButtonClicked: false,
        responseData: { data: {} },
        compareCodecalculationDict: {},
      },
    ],
    APIErrorFlag: false,
    v05Data: [
      'v08_2024',
      'v08_2023',
      'v08_2022',
      'v05_2021',
      'v05_2020',
      'v05_2019',
      'v05_2018',
      'v05_2017',
    ],
    vData: [
      'v28_2024',
      'v24_2024',
      'v28_2023',
      'v24_2023',
      'v24_2022',
      'v24_2021',
      'v24_2020',
      'v24_2019',
      'v23_2018',
      'v22_2017',
    ],
    hhsData: [
      'hhs_v07_2024',
      'hhs_v07_2023',
      'hhs_v07_2022',
      'hhs_v05_2021',
      'hhs_v05_2020',
      'hhs_v05_2019',
      'hhs_v05_2018',
      'hhs_v05_2017',
    ],
    v21_esrd_data: ['esrd_v21_2020', 'esrd_v21_2021'],
    v05PaceData: ['pace_v05_2023', 'pace_v05_2024'],
    vPaceData: ['pace_v22_2023', 'pace_v22_2024'],
    v21_pace_esrd_data: ['pace_esrd_v21_2023', 'pace_esrd_v21_2024'],
    v24_esrd_data: ['esrd_v24_2022', 'esrd_v24_2023', 'esrd_v24_2024'],
    // PatchNotesCommenting
    // isPatchNotesModal: true && (localStorage.getItem('removePatch') !== 'true' || localStorage.getItem('noOfPatchNotes') !== patchNotes.length),
    isCmsModal: false,
    topMargin: '46px',
    search_member: [],
    search_member1: [],
    search_box: '',
    search_suggestion_loader: false,
    orec_disabled: false,
  },
  mutations: {
    updateStateData(state, payload) {
      let { index, data } = payload
      state.componentData[index].inputDict[data.name] = data.value
      if (data.name === 'commandType') {
        state.componentData[index].responseData.data = {}
      }
      state.componentData[index].isSubmitButtonClicked = false
    },
    deleteRowFromComponentData(state, payload) {
      //delete specified row from component data list
      state.componentData.splice(payload.index, 1)
    },
    updateResponseData(state, responsePayload) {
      let index = responsePayload.index
      let response = responsePayload.apiResponse
      let current = state.componentData[index]
      current.isSubmitButtonClicked = true
      current.responseData = response
      let summary = response.data.Summary
      let command = current.inputDict.commandType
      let calculationDict = current.compareCodecalculationDict
      let totalRaf = 0
      let totalPremium = 0
      function getRafPremium(key1, key2, calculation) {
        let num1 = summary[key1]?.[calculation] || 0
        let num2 = summary[key2]?.[calculation] || 0
        return num1 + num2
      }
      if (command === 'compare') {
        calculationDict['incrementRaf'] = roundOffValueOf(
          getRafPremium('adds', 'upgrades', 'raf_change')
        )
        calculationDict['gapsRaf'] = roundOffValueOf(
          getRafPremium('deletes', 'downgrades', 'raf_change')
        )
        calculationDict['diffRaf'] =
          Number(calculationDict['incrementRaf']) +
          Number(calculationDict['gapsRaf'])
        calculationDict['incrementPremium'] = getRafPremium(
          'adds',
          'upgrades',
          'premium'
        )
        calculationDict['gapsPremium'] = getRafPremium(
          'deletes',
          'downgrades',
          'premium'
        )
        calculationDict['diffPremium'] =
          calculationDict['incrementPremium'] + calculationDict['gapsPremium']
        for (let key in summary) {
          totalRaf += summary[key]?.raf_change || 0
          totalPremium += summary[key]?.premium || 0
        }
        calculationDict['totalRafChange'] = Number(totalRaf).toFixed(3)
        calculationDict['totalPremium'] = totalPremium
        current.compareCodecalculationDict = calculationDict
      }
    },
    addRowToComponentData(state, payload) {
      let current = state.componentData[payload.index].inputDict
      const newComponentData = {
        inputDict: {
          age: current.age,
          riskModel: current.riskModel,
          dosYearModel: current.dosYearModel,
          gender: current.gender,
          ore: current.ore,
          baseRate: current.baseRate,
          calculationYear: current.calculationYear,
          DOB: current.DOB,
          plate: current.plate,
          enrollmentDuration: current.enrollmentDuration,
          commandType: 'member',
          memberProfileConditions: [],
          baseConditions: [],
          newConditions: [],
        },
        isSubmitButtonClicked: false,
        responseData: {
          data: {},
        },
        compareCodecalculationDict: {},
      }

      // Add the new component data to the array
      state.componentData.push(newComponentData)
    },
    setAPIErrorFlag(state, errorFlag) {
      state.APIErrorFlag = errorFlag
    },
  },
  actions: {
    updateStateData({ commit }, payload) {
      commit('updateStateData', payload)
    },
    async handleSubmitRequest({ commit, state }, payload) {
      commit('setAPIErrorFlag', false)
      let index = payload.index
      let current = state.componentData[index].inputDict
      let APIParameters = {
        ver: current.dosYearModel,
        model: current.riskModel,
        age: current.age,
        baserate: current.baseRate,
        calculationYear: current.calculationYear,
        enrollmentDuration: current.enrollmentDuration,
        plate: current.plate,
        DOB: current.DOB,
      }
      try {
        let response
        if (current.commandType === 'member') {
          response = await api.getMemberProfileResult({
            ...APIParameters,
            condition_list: current.memberProfileConditions,
            sex: current.gender,
            orec: current.ore,
          })
        } else if (current.commandType === 'compare') {
          response = await api.getCompareCodeResult({
            ...APIParameters,
            old_list: current.baseConditions,
            new_list: current.newConditions,
            gender: current.gender,
            DOB: current.DOB,
          })
        }
        commit('updateResponseData', { index, apiResponse: response })
      } catch {
        commit('setAPIErrorFlag', true)
      }
    },
    addRowToComponentData({ commit, state }, payload) {
      // Optional: You can add validation or business logic here
      if (payload.index >= 0 && payload.index < state.componentData.length) {
        commit('addRowToComponentData', payload)
        return true // Return success status
      } else {
        console.error(
          'Invalid index in addRowToComponentData action:',
          payload.index
        )
        return false
      }
    },
    deleteRow(context, payload) {
      context.commit('deleteRowFromComponentData', payload)
    },
  },
  getters: {
    getComponentDataByIndex: (state) => (index) => state.componentData[index],
    hasHhsData: (state) => (dosVersion) => state.hhsData.includes(dosVersion),
    hasVData: (state) => (dosVersion) => state.vData.includes(dosVersion),
    hasV05Data: (state) => (dosVersion) => state.v05Data.includes(dosVersion),
    hasv21ESRDData: (state) => (dosVersion) =>
      state.v21_esrd_data.includes(dosVersion),
    hasPaceV05Data: (state) => (dosVersion) =>
      state.v05PaceData.includes(dosVersion),
    hasPaceVData: (state) => (dosVersion) =>
      state.vPaceData.includes(dosVersion),
    hasPaceV21ESRDData: (state) => (dosVersion) =>
      state.v21_pace_esrd_data.includes(dosVersion),
    hasv24ESRDData: (state) => (dosVersion) =>
      state.v24_esrd_data.includes(dosVersion),
    isPatchNotesVisible: (state) => state.isPatchNotesModal,
    isCmsModalVisible: (state) => state.isCmsModal,
    hccCodingValue: () => (codingValue) => {
      return codingValue == 'hcc'
    },
    dxCodingValue: () => (codingValue) => {
      return codingValue == 'dx'
    },
  },
})
