<template>
  <div>
    <v-card>
      <div class="search-card">
        <v-btn
          style="display: none"
          size="small"
          class="close-btn-search"
          @click="resetSearch()"
          fab
          :position="right"
          absolute
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="search-box reference-page">
          <div class="search-inner-box">
            <div class="search-menu" style="width: 100% !important">
              <div class="filter-field-container search-block">
                <span class="codingChoiceLabel">
                  Conditions
                  <v-icon
                    color="black"
                    class="p-1"
                    size="12"
                    v-tooltip="{
                      text: tooltipDataForCondition,
                      location: 'top',
                    }"
                    >fas fa-question-circle</v-icon
                  >
                </span>
                <v-text-field
                  class="dxTextField"
                  placeholder="Search here"
                  color="#0099d9"
                  density="compact"
                  flat
                  variant="outlined"
                  v-model="queryReset"
                  @input="debounceInput"
                  autofocus
                ></v-text-field>
              </div>
              <div
                class="filter-field-container"
                :style="[
                  current_path != '/hccreference/'
                    ? current_path != '/'
                      ? { 'padding-left': '16px' }
                      : { width: '100%' }
                    : { width: '100%' },
                ]"
              >
                <span class="codingChoiceLabel">DX/HCC</span>
                <v-select
                  class="search-coding-choice"
                  flat
                  density="compact"
                  color="#0099d9"
                  style="color: #545454"
                  variant="outlined"
                  :items="codingChoices"
                  v-model="codingValue"
                  item-title="text"
                  item-value="value"
                  @update:model-value="elasticSearch('typeChange')"
                />
              </div>
              <div class="filter-field-container">
                <span class="search-dos-choice">DOS Year/Model</span>
                <v-select
                  class="search-dos-choice"
                  flat
                  density="compact"
                  variant="outlined"
                  color="#0099d9"
                  style="color: #545454"
                  :items="dosChoices"
                  v-model="dosValue"
                  item-title="text"
                  item-value="value"
                  @update:model-value="setDosYear"
                ></v-select>
              </div>

              <div class="filter-field-container">
                <span class="drfChoicelabel">Risk Model</span>
                <template v-if="hasHhsData">
                  <v-select
                    class="search-drf-choice"
                    flat
                    density="compact"
                    color="#0099d9"
                    variant="outlined"
                    :items="drfhhsItems"
                    v-model="DefaultdrfValue"
                    item-title="text"
                    item-value="value"
                    @update:model-value="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasV05Data">
                  <v-select
                    class="search-drf-choice"
                    flat
                    density="compact"
                    color="#0099d9"
                    variant="outlined"
                    :items="drfv05Items"
                    v-model="DefaultdrfValue"
                    item-title="text"
                    item-value="value"
                    @update:model-value="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasv21ESRDData">
                  <v-select
                    class="search-drf-choice"
                    flat
                    density="compact"
                    color="#0099d9"
                    variant="outlined"
                    :items="drfEsrdv21Items"
                    v-model="DefaultdrfValue"
                    item-title="text"
                    item-value="value"
                    @update:model-value="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasv24ESRDData">
                  <v-select
                    class="search-drf-choice"
                    flat
                    density="compact"
                    color="#0099d9"
                    variant="outlined"
                    :items="drfEsrdv24Items"
                    v-model="DefaultdrfValue"
                    item-title="text"
                    item-value="value"
                    @update:model-value="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasPaceV05Data">
                  <v-select
                    class="search-drf-choice"
                    flat
                    density="compact"
                    color="#0099d9"
                    variant="outlined"
                    :items="drfPaceV05Items"
                    v-model="DefaultdrfValue"
                    item-title="text"
                    item-value="value"
                    @update:model-value="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasPaceVData">
                  <v-select
                    class="search-drf-choice"
                    flat
                    density="compact"
                    color="#0099d9"
                    variant="outlined"
                    :items="drfPaceItems"
                    v-model="DefaultdrfValue"
                    item-title="text"
                    item-value="value"
                    @update:model-value="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else-if="hasPaceV21ESRDData">
                  <v-select
                    class="search-drf-choice"
                    flat
                    density="compact"
                    color="#0099d9"
                    variant="outlined"
                    :items="drfPaceEsrdV21Items"
                    v-model="DefaultdrfValue"
                    item-title="text"
                    item-value="value"
                    @update:model-value="elasticSearch('typeChange')"
                  ></v-select>
                </template>
                <template v-else>
                  <v-select
                    class="search-drf-choice"
                    flat
                    density="compact"
                    color="#0099d9"
                    variant="outlined"
                    :items="drfItems"
                    v-model="DefaultdrfValue"
                    item-title="text"
                    item-value="value"
                    @update:model-value="elasticSearch('typeChange')"
                  ></v-select>
                </template>
              </div>
              <div class="filter-field-container age-block">
                <span class="drfChoicelabel">Age</span>
                <v-text-field
                  :model-value="age"
                  density="compact"
                  variant="outlined"
                  type="number"
                  class="agetextfield"
                  @input="onChangeAgeInput"
                  color="#0099d9"
                  :error-messages="invalidAge ? ['Enter valid age'] : []"
                  autocomplete="off"
                ></v-text-field>
              </div>
            </div>
            <div class="search-result-box" @scroll="handleLazyLoading">
              <v-progress-circular
                indeterminate
                color="red"
                class="loaderStyleRef"
                v-if="isActive"
              ></v-progress-circular>

              <table class="search-table">
                <thead>
                  <tr>
                    <th
                      v-if="isCodingValueSetToDX"
                      class="search-header-th-left search-inner-th-individual"
                      style="width: 19.1%"
                    >
                      DX Code
                    </th>
                    <th
                      v-if="isCodingValueSetToHCC"
                      class="search-header-th-left search-inner-th-individual"
                      style="width: 19.1%"
                    >
                      HCC
                      <span>
                        <v-icon
                          color="black"
                          class="p-1"
                          size="12"
                          v-tooltip="{
                            text: tooltipDataForHccRef,
                            location: 'top',
                          }"
                        >
                          fas fa-question-circle
                        </v-icon>
                      </span>
                    </th>
                    <th class="search-inner-th-individual" style="width: 38.6%">
                      Description
                    </th>
                    <th
                      v-if="isCodingValueSetToDX"
                      class="search-inner-th-individual"
                      style="width: 15.7%"
                    >
                      HCC
                    </th>
                    <th
                      v-if="hasHhsData"
                      class="search-inner-th-individual"
                      style="width: 15.7%"
                    >
                      Plate
                    </th>
                    <th
                      v-if="isCodingValueSetToHCC"
                      class="search-inner-th-individual"
                      style="width: 15.7%"
                    >
                      Trump Codes
                    </th>
                    <th
                      class="search-inner-th-individual search-header-th-right"
                      style="width: 25.5%"
                    >
                      RAF
                    </th>
                    <th
                      class="search-header-th-right"
                      style="
                        width: 7.2%;
                        display: none;
                        border: none;
                        position: relative;
                        background: white !important;
                      "
                    ></th>
                  </tr>
                </thead>
                <tr
                  v-for="item in tableData"
                  :key="item.code"
                  :class="{
                    selectedRow: item.code === selectedItem.code,
                  }"
                >
                  <td style="cursor: pointer">
                    <p class="search-table-left" v-if="isCodingValueSetToDX">
                      {{ item.code }}
                    </p>
                    <p v-if="isCodingValueSetToHCC" class="search-table-left">
                      <span @click="changeSearch(item.code)">
                        {{ item.code }}
                      </span>
                    </p>
                  </td>
                  <td class="text-right" style="cursor: pointer">
                    <p class="search-table-left">{{ item.description }}</p>
                  </td>
                  <td v-if="isCodingValueSetToDX">
                    <span v-for="hcc in item.hcc" :key="hcc">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <span v-bind="props" @mouseover="showHccDetail(hcc)">
                            {{ hcc }}
                            <span
                              v-if="
                                item.hcc.length > 1 &&
                                item.hcc.lastIndexOf(hcc) !==
                                  item.hcc.length - 1
                              "
                              >,</span
                            >
                          </span>
                        </template>
                        <span>{{ onHoverData }}</span>
                      </v-tooltip>
                    </span>
                  </td>
                  <td v-if="hasHhsData">
                    <p class="search-table-left" v-if="isCodingValueSetToDX">
                      {{ item.plate }}
                    </p>
                  </td>
                  <td v-if="isCodingValueSetToHCC" class="text-right">
                    <span v-for="trump in item.trumps" :key="trump">
                      <span>
                        {{ trump }}
                        <span
                          v-if="
                            item.trumps.length > 1 &&
                            item.trumps.lastIndexOf(trump) !==
                              item.trumps.length - 1
                          "
                          >,</span
                        >
                      </span>
                    </span>
                  </td>
                  <td>
                    <p class="search-table-right">{{ item.raf }}</p>
                  </td>
                  <td style="border: none; background-color: white">
                    <div
                      v-for="data in selectedDxData"
                      :key="data.code"
                      style="display: none"
                    >
                      <p class="tickmark" v-if="item.code == data.code">
                        <i class="fas fa-check"></i>
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div style="display: none">
            <div class="selectedDxBox">
              <div
                v-if="selectedRowsIDs.length == 0"
                class="no-condition-selected"
              >
                <p>No Conditions Selected</p>
              </div>
              <div v-else class="selectDxTable">
                <p class="selected-condition">Selected Conditions</p>
                <p class="message-over-search-select">
                  Click
                  <i class="fas fa-times"></i> to remove condition from list.
                </p>
                <div class="selected-table">
                  <table class="selected-table-table">
                    <thead>
                      <tr class="selected-table-head-tr">
                        <th
                          style="width: 29%; border-right: 1px solid white"
                          class="search-header-th-left"
                        >
                          Code
                        </th>
                        <th style="width: 59%" class="search-header-th-right">
                          Description
                        </th>
                        <th style="width: 10%; background: white"></th>
                      </tr>
                    </thead>
                    <tr
                      v-for="item in selectedDxData"
                      :key="item.name"
                      class="selected-table-data-tr"
                    >
                      <td>{{ item.code }}</td>
                      <td class="text-right">
                        {{ item.description.substring(0, 60) + '..' }}
                      </td>
                      <td>
                        <div
                          v-for="data in selectedDxData"
                          :key="data.code"
                          class="tickmark"
                        >
                          <p
                            v-if="item.code == data.code"
                            style="cursor: pointer"
                            @click="removeDx(item)"
                            :class="{
                              removeDxRow: item.code === selectedItem.code,
                            }"
                          >
                            <i class="fas fa-times"></i>
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div style="text-align: right">
                <v-btn
                  v-if="selectedRowsIDs.length != 0"
                  class="confirm-selected-button submit button"
                  color="info"
                  @click="fillSelectedDx"
                  >{{ confirm }}</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <MainFooter />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import _ from 'lodash'
import { LRUCache } from 'lru-cache'
import MainFooter from '../common/MainFooter.vue'

// Import JSON data
import riskModelVItems from '../../data/riskModelVItemsSearch.json'
import riskModelV05Items from '../../data/riskModelV05Items.json'
import riskModelHHSItems from '../../data/riskModelHHSItems.json'
import riskModelESRDV21Items from '../../data/riskModelESRDV21Items.json'
import riskModelESRDV24Items from '../../data/riskModelESRDV24Items.json'
import riskModelPaceESRDV21Items from '../../data/riskModelPaceESRDV21Items.json'
import riskModelPaceV05Items from '../../data/riskModelPaceV05Items.json'
import riskModelPaceVItems from '../../data/riskModelPaceVItems.json'
import dosYearData from '../../data/dosYearData.json'

// Create LRU Cache
const options = {
  max: 500,
  ttl: 1000 * 60 * 60, // 1 hour
  maxSize: 5000000, // Add this (example: ~5MB limit for the entire cache)
  // OR
  // maxEntrySize: 1000000, // Or add this (example: ~1MB limit per cache entry)
  sizeCalculation: (value, key) => {
    return value.length * 2 + key.length
  },
}

const cache = new LRUCache(options)

// Initialize reactive data
const age = ref(65)
const current_path = ref(window.location.pathname)
const onHoverData = ref('')
const searchCodeData = ref([])
const searchTerm = ref('')
const queryReset = ref('')
const tooltipDataForHccRef = ref(
  'Click on Hcc to show all the related Dx Codes'
)
const tooltipDataForCondition = ref(
  'Conditions eligible as per selected dos year model.'
)
const codingChoices = [
  { text: 'DX', value: 'dx' },
  { text: 'HCC', value: 'hcc' },
]
const codingValue = ref('dx') // default coding method
const dosChoices = dosYearData
const buttonStyle = ref({ cursor: 'default' })
const dosValue = ref('v24_2022') // default DOS Value
const confirm = ref('Confirm')
const invalidAge = ref(false)

const dxTableHeaders = reactive([
  { text: 'DX Code', sortable: false, value: 'code' },
  { text: 'Description', value: 'description' },
  { text: 'HCC', value: 'hcc' },
  { text: 'RAF', value: 'raf' },
  { text: '', value: '', class: 'blankColHeader' },
])

const local = ref([])
const dxData = ref([])
const selectedDxData = ref([])
const selectedRowsIDs = ref([])
const selectedItem = ref({ code: -1 })
const tableData = ref({})
const drfPaceItems = riskModelPaceVItems
const drfPaceV05Items = riskModelPaceV05Items
const drfPaceEsrdV21Items = riskModelPaceESRDV21Items
const drfItems = riskModelVItems
const drfv05Items = riskModelV05Items
const drfhhsItems = riskModelHHSItems
const drfEsrdv21Items = riskModelESRDV21Items
const drfEsrdv24Items = riskModelESRDV24Items
const DefaultdrfValue = ref('CN')
const search_url = '/api/search'
const hover_url = '/api/search/hover'
const isActive = ref(false)
const dialog = ref(false)
const jsonTable = ref(null)
const d = ref(null)
const totalEntries = ref(0)
const endData = ref(false)

// Computed properties
const isCodingValueSetToDX = computed(() => codingValue.value === 'dx')
const isCodingValueSetToHCC = computed(() => codingValue.value === 'hcc')

const hasHhsData = computed(() => dosValue.value.includes('hhs'))
const hasV05Data = computed(() => dosValue.value.includes('v05'))
const hasv21ESRDData = computed(() => dosValue.value.includes('v21_esrd'))
const hasv24ESRDData = computed(() => dosValue.value.includes('v24_esrd'))
const hasPaceV05Data = computed(() => dosValue.value.includes('pace_v05'))
const hasPaceVData = computed(() => dosValue.value.includes('pace_v'))
const hasPaceV21ESRDData = computed(() =>
  dosValue.value.includes('pace_v21_esrd')
)

const returnSearchDrfValue = computed(() => {
  return DefaultdrfValue.value
})

// Methods
const debounceInput = _.debounce(function (e) {
  if (e !== 'age') {
    searchTerm.value = e
    totalEntries.value = 0
    tableData.value = {}
    endData.value = false
  }
  elasticSearch()
}, 500)

const showHccDetail = (item) => {
  const item_hcc = item
  if (cache.has(item_hcc)) {
    onHoverData.value = cache.get(item_hcc)
  } else {
    let drfValue = returnSearchDrfValue.value
    let hover_data = {
      dx_hcc: 'hcc',
      dos_year: dosValue.value,
      drf: drfValue,
      hcc: item_hcc,
    }

    axios.post(hover_url, hover_data).then((response) => {
      if (typeof response.data === 'object') {
        let data_received = { ...response.data }
        onHoverData.value = data_received.description
        cache.set(data_received.code, data_received.description)
      }
    })
  }
}

const changeTable = () => {
  if (codingValue.value === 'hcc') {
    dxTableHeaders[0].text = 'HCC'
    dxTableHeaders[2].text = 'Trump Codes'
  } else {
    dxTableHeaders[0].text = 'DX Code'
    dxTableHeaders[2].text = 'HCC'
  }
}

const getDosValue = (dosVal) => {
  let start = dosVal.indexOf('/') + 1
  let end = dosVal.length
  return dosVal.slice(start, end).trim()
}

const onChangeAgeInput = (value) => {
  age.value = value
  debounceInput('age')
}

const setDosYear = () => {
  if (hasHhsData.value) {
    DefaultdrfValue.value = 'ADULT'
  } else if (hasV05Data.value) {
    DefaultdrfValue.value = 'D1'
  } else if (hasv21ESRDData.value) {
    DefaultdrfValue.value = 'C1'
  } else if (hasv24ESRDData.value) {
    DefaultdrfValue.value = 'C3'
  } else if (hasPaceV05Data.value) {
    DefaultdrfValue.value = 'P7'
  } else if (hasPaceV21ESRDData.value) {
    DefaultdrfValue.value = 'PA'
  } else if (hasPaceVData.value) {
    DefaultdrfValue.value = 'PK'
  } else {
    DefaultdrfValue.value = 'CN'
  }
  elasticSearch('typeChange')
}

const handleLazyLoading = ({
  target: { scrollTop, clientHeight, scrollHeight },
}) => {
  if (scrollTop + clientHeight >= scrollHeight - 150) {
    if (!isActive.value && !endData.value) {
      elasticSearch()
    }
  }
}

const elasticSearch = (e) => {
  if (e === 'typeChange') {
    totalEntries.value = 0
    tableData.value = {}
    endData.value = false
  }

  let drfValue = returnSearchDrfValue.value
  changeTable()
  isActive.value = true

  let search_data = {
    dx_hcc: codingValue.value,
    dos_year: getDosValue(dosValue.value),
    drf: drfValue,
    search: searchTerm.value,
    start: totalEntries.value,
  }

  axios.post(search_url, search_data).then((response) => {
    isActive.value = false
    if (typeof response.data === 'object') {
      if (Object.keys(response.data).length < 20) endData.value = true
      for (let key in Object.keys(response.data)) {
        tableData.value[Number(totalEntries.value) + Number(key)] =
          response.data[key]
      }
      totalEntries.value = Object.keys(tableData.value).length
    }
  })
}

const resetSearch = () => {
  // Reset all reactive variables to their initial state
  age.value = 65
  onHoverData.value = ''
  searchCodeData.value = []
  searchTerm.value = ''
  queryReset.value = ''
  codingValue.value = 'dx'
  dosValue.value = 'v24_2022'
  DefaultdrfValue.value = 'CN'
  local.value = []
  dxData.value = []
  selectedDxData.value = []
  selectedRowsIDs.value = []
  selectedItem.value = { code: -1 }
  tableData.value = {}
  isActive.value = false
  dialog.value = false
  jsonTable.value = null
  d.value = null
  totalEntries.value = 0
  endData.value = false

  debounceInput(' ')
  // Emit confirm action to parent
  // $emit('confirmAction');
}

const fillSelectedDx = () => {
  localStorage.setItem('selectedSearchData', '')
  selectedDxData.value.forEach((data) => {
    searchCodeData.value.push(data.code)
  })
  localStorage.setItem('selectedSearchData', searchCodeData.value)
  resetSearch()
}

const changeSearch = (item_code) => {
  queryReset.value = item_code
  codingValue.value = 'dx'
  debounceInput(queryReset.value)
  elasticSearch()
}

const removeDx = (item) => {
  const index = selectedDxData.value.findIndex((dx) => dx.code === item.code)
  if (index !== -1) {
    selectedDxData.value.splice(index, 1)
    // Also remove from selectedRowsIDs
    const rowIndex = selectedRowsIDs.value.indexOf(item.code)
    if (rowIndex !== -1) {
      selectedRowsIDs.value.splice(rowIndex, 1)
    }
  }
}

// Watch for changes
watch(codingValue, () => {
  changeTable()
})

// Initialize the component
onMounted(() => {
  elasticSearch()
})

// Expose needed methods/properties for template
defineExpose({
  debounceInput,
  showHccDetail,
  changeTable,
  onChangeAgeInput,
  setDosYear,
  handleLazyLoading,
  elasticSearch,
  resetSearch,
  fillSelectedDx,
  changeSearch,
  removeDx,
})
</script>

<style scoped src="../../assets/css/search.css"></style>
