<template>
  <div>
    <div class="main pt-5 member-box">
      <div class="member-box-between">
        <div class="member-box-label condition-text">
          Conditions
          <v-tooltip
            :text="tooltipDataForCondition"
            location="bottom"
            theme="epicc"
          >
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" color="black" class="p-1" size="12" dark>
                fas fa-question-circle
              </v-icon>
            </template>
          </v-tooltip>
        </div>
        <div class="box conditioninput">
          <div style="position: relative">
            <CodeInput
              ref="revalidateCodeInput"
              :tagList="memberProfileData.inputDict.memberProfileConditions"
              :tagstring="tag"
              searchBox="tag1"
              :componentDataIndex="componentDataIndex"
              :isClearTag="isClear"
              :riskModel="memberProfileData.inputDict.riskModel"
              :dosYearModel="memberProfileData.inputDict.dosYearModel"
              :isEmptyTagList="isEmptyTags"
              :commandType="memberProfileData.inputDict.commandType"
              @getTag="updateTagList"
              @removeTag="updateTagList"
              @clearTagFlag="isClear = false"
              @invalidChips="getInvalidChips"
              @emptyTagsFlag="isEmptyTags = false"
            />
            <div
              v-if="searchBox === 'tag1'"
              class="member-search-suggestion"
              :class="[
                searchMember.length > 0 && searchBox === 'tag1'
                  ? 'suggestion-box'
                  : '',
                searchBox,
                searchSuggestionLoader && searchBox === 'tag1'
                  ? 'show-box'
                  : '',
              ]"
            >
              <v-progress-circular
                v-if="searchSuggestionLoader"
                indeterminate
                color="red"
                size="32"
                class="member-search-loader"
              ></v-progress-circular>
              <ul v-if="searchBox === 'tag1'">
                <li
                  v-for="item in searchMember"
                  :key="item"
                  @click="addTagFromList(item)"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="member-box-between">
        <div class="member-box-label agelabel">
          Age:
          <v-tooltip :text="tooltipDataForAge" location="bottom" theme="epicc">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" color="black" class="p-1" size="12" dark>
                fas fa-question-circle
              </v-icon>
            </template>
          </v-tooltip>
        </div>
        <div class="box ageinput">
          <AgeInput
            :age="memberProfileData.inputDict.age"
            :isAgeInvalid="isAgeInvalid"
            v-on:onAge="getAge"
            :disableAge="disableAge"
            v-on:validAge="isAgeInvalid = false"
          />
        </div>
        <div class="member-box-label member-box-sec-row">Gender:</div>
        <div class="box genderinput">
          <Gender
            :gender="memberProfileData.inputDict.gender"
            v-on:onGender="getGender"
          />
        </div>
      </div>
      <div class="member-box-between member-box-mobile">
        <div class="member-box-label">DOS Year/Model:</div>
        <div class="box dRiskinput">
          <DosYearModel
            :dosYearModel="memberProfileData.inputDict.dosYearModel"
            v-on:onDosYear="getDosYear"
          />
        </div>
        <div class="member-box-label member-box-sec-row">
          Original reason for entitlement :
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-icon color="black" class="p-1" size="12" dark v-bind="props"
                >fas fa-question-circle</v-icon
              >
            </template>
            <span>{{ tooltipDataForOrec }}</span>
          </v-tooltip>
        </div>
        <div class="box oceinput">
          <oreComponent
            v-on:onOre="getOre"
            :ore="memberProfileData.inputDict.ore"
          />
        </div>
      </div>
      <div class="member-box-between risk-model-col">
        <div class="member-box-label">
          Risk Model:
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-icon color="black" class="p-1" size="12" dark v-bind="props">
                fas fa-question-circle
              </v-icon>
            </template>
            <span>{{ tooltipDataForRiskModel }}</span>
          </v-tooltip>
        </div>
        <div class="box dosyearinput">
          <RiskModel
            :dosYear="memberProfileData.inputDict.dosYearModel"
            v-on:onRiskModel="getRiskModel"
            :riskModel="memberProfileData.inputDict.riskModel"
            :key="dosYear"
          />
        </div>
        <div class="member-box-label member-box-sec-row">
          Base Rate :
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-icon color="black" class="p-1" size="12" dark v-bind="props"
                >fas fa-question-circle</v-icon
              >
            </template>
            <span>{{ tooltipDataForBaserate }}</span>
          </v-tooltip>
        </div>
        <div class="box baserateinput">
          <BaseRateInput
            :baseRate="memberProfileData.inputDict.baseRate"
            :isBaseRateInvalid="isBaseRateInvalid"
            v-on:onBaseRate="getBaseRate"
            v-on:validBaseRate="isBaseRateInvalid = false"
          />
        </div>
      </div>
      <div class="member-box-between">
        <div class="member-box-label agelabel">
          Date Of Birth:
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-icon color="black" class="p-1" size="12" dark v-bind="props"
                >fas fa-question-circle</v-icon
              >
            </template>
            <span>{{ tooltipDataForDob }}</span>
          </v-tooltip>
        </div>
        <div class="box calculationyear-input">
          <DOBInput
            :calculationYear="memberProfileData.inputDict.calculationYear"
            :dateOfBirthValue="memberProfileData.inputDict.DOB"
            v-on:dateOfBirthUpdate="setDateOfBirth"
          />
        </div>
      </div>
      <div class="member-box-between">
        <div class="member-box-label agelabel">
          Plate:
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-icon color="black" class="p-1" size="12" dark v-bind="props"
                >fas fa-question-circle</v-icon
              >
            </template>
            <span>{{ tooltipDataForPlate }}</span>
          </v-tooltip>
        </div>
        <div class="box dosyearinput">
          <plateInput
            :plateValue="memberProfileData.inputDict.plate"
            v-on:updatePlateValue="setPlateValue"
          />
        </div>
      </div>

      <div class="member-box-between member-box-mobile">
        <div class="member-box-label agelabel">
          Calculation Year:
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-icon color="black" class="p-1" size="12" dark v-bind="props"
                >fas fa-question-circle</v-icon
              >
            </template>
            <span>{{ tooltipDataForCalculationYear }}</span>
          </v-tooltip>
        </div>
        <div class="box calculationyear-input">
          <calculationYearInput
            :error="dobErrorMessage"
            :dob="memberProfileData.inputDict.DOB"
            :calculationYear="memberProfileData.inputDict.calculationYear"
            v-on:calculationYearUpdate="setCalculationYear"
          />
        </div>
      </div>
      <div class="member-box-between risk-model-col">
        <div class="member-box-label platelabel">Enrollment Duration:</div>
        <div class="box enrollmentinput">
          <EnrollmentInput
            :enrollmentDuration="memberProfileData.inputDict.enrollmentDuration"
            v-on:updateEnrollmentValue="setEnrollmentValue"
          />
        </div>
      </div>

      <div class="submitclear submitclear-member">
        <v-btn class="submit button" @click="submit">Submit</v-btn>
        <v-btn class="clear button" @click="clearResult">Clear</v-btn>
        <v-btn
          class="add"
          :class="[
            (componentDataIndex === store.state.componentData.length - 1 &&
              store.state.componentData[componentDataIndex]
                ?.isSubmitButtonClicked) == true
              ? 'showBtn'
              : 'hideBtn',
          ]"
          @click="addRow"
        >
          Add new
        </v-btn>
        <v-btn
          v-if="componentDataIndex !== 0"
          class="close button"
          @click="deleteRow"
        >
          <v-icon
            color="white"
            class="p-1"
            size="20"
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: 'Close',
            }"
          >
            fas fa-trash
          </v-icon>
        </v-btn>
        <v-snackbar v-model="snackbar" class="snackbar">
          <div class="snackbar-text">
            {{ errorMessage }}
          </div>
          <v-btn
            class="snackbar-button"
            color="pink"
            text
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </v-snackbar>
      </div>
      <div class="progress-bar">
        <v-progress-circular
          v-show="isWaitingForResult"
          color="light-blue"
          indeterminate
          rounded
          height="6"
        ></v-progress-circular>
      </div>
      <MemberProfileResultTableVue
        v-if="APIResponse.length != 0"
        :componentDataIndex="componentDataIndex"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import CodeInput from '../../common/CodeInput.vue'
import '../../../assets/css/member.css'
import AgeInput from '../../common/AgeInput.vue'
import RiskModel from '../../common/RiskModel.vue'
import DosYearModel from '../../common/DosYearModel.vue'
import Gender from '../../common/Gender.vue'
import OreComponent from '../../common/OreComponent.vue'
import BaseRateInput from '../../common/BaseRateInput.vue'
import MemberProfileResultTableVue from '../results/MemberProfileResultTable.vue'
import CalculationYearInput from '../../common/CalculationYearInput.vue'
import DOBInput from '../../common/DOBInput.vue'
import PlateInput from '../../common/PlateInput.vue'
import EnrollmentInput from '../../common/EnrollmentInput.vue'
import moment from 'moment'
import {
  scrollToBottom,
  scrollToBottomContainer,
} from '../../common/helperFunctions'

// Props definition
const props = defineProps({
  componentDataIndex: {
    type: Number,
    required: true,
  },
})

const store = useStore()

// Computed properties
const memberProfileData = computed(() =>
  store.getters.getComponentDataByIndex(props.componentDataIndex)
)

const APIResponse = computed(
  () =>
    store.getters.getComponentDataByIndex(props.componentDataIndex)
      ?.responseData?.data
)

const isButtonVisible = computed(() => {
  return (
    props.componentDataIndex === store.state.componentData.length - 1 &&
    store.state.componentData[props.componentDataIndex]
      ?.isSubmitButtonClicked === true
  )
})

// Reactive state
const tag = ref('')
const tagList = ref([])
const age = ref(null)
const calculationYear = ref(null)
const baseRate = ref(null)
const gender = ref('')
const ore = ref(null)
const riskModel = ref('')
const dosYear = ref('')
const plate = ref('')
const enrollmentDuration = ref('')
const DOB = ref('')
const isClear = ref(false)
const snackbar = ref(false)
const errorMessage = ref('')
const invalidChips = ref([])
const isEmptyTags = ref(false)
const isAgeInvalid = ref(false)
const isBaseRateInvalid = ref(false)
const isCalculationYearInvalid = ref(false)
const isWaitingForResult = ref(false)
const disableAge = ref(false)
const DOBMoment = ref('')
const dobErrorMessage = ref(null)

// Constant values
const HHS_DEFAULT_RISK_MODEL = 'ADULT'
const V05_DEFAULT_RISK_MODEL = 'D1'
const V_DEFAULT_RISK_MODEL = 'CN'
const V24_ESRD_DEFAULT_RISK_MODEL = 'C3'
const V21_ESRD_DEFAULT_RISK_MODEL = 'C1'
const PACE_V21_ESRD_DEFAULT_RISK_MODEL = 'PA'
const PACE_V_DEFAULT_RISK_MODEL = 'PK'
const PACE_V05_DEFAULT_RISK_MODEL = 'P7'

// Tooltip messages
const tooltipDataForCondition =
  'Conditions eligible as per selected dos year model.'
const tooltipDataForBaserate = 'Base reimbursement premium for member'
const tooltipDataForCalculationYear =
  '1st Jan of the input calculation year will be used along with DOB to calculate age'
const tooltipDataForRiskModel =
  'List of risk models eligible as per selected dos year model'
const tooltipDataForPlate =
  'Applicable only when the Commercial Model(s) are in use'
const tooltipDataForOrec =
  'Not applicable when the Commercial Model(s) are in use'
const tooltipDataForDob =
  'DOB will not be considered in any calculation(s) if age is provided'
const tooltipDataForAge = 'Acceptable age values are from 0 to 110'

// Methods
function updateTagList(newtagList) {
  tagList.value = newtagList
  store.dispatch('updateStateData', {
    index: props.componentDataIndex,
    data: { name: 'memberProfileConditions', value: tagList.value },
  })
}

function getInvalidChips(chips) {
  invalidChips.value = chips
}

function setFieldValue(value, fieldName) {
  let currentIndex = props.componentDataIndex
  let updatedData = {
    name: fieldName,
    value: value,
  }
  updateCommandInputValue(currentIndex, updatedData)
  let nextIndex = currentIndex + 1
  if (isNextPickCommand(nextIndex)) {
    updateCommandInputValue(nextIndex, updatedData)
  }
}

function setEnrollmentValue(value) {
  enrollmentDuration.value = value
  setFieldValue(value, 'enrollmentDuration')
}

function calculateAge(date, calculationYear) {
  let parseDate = new Date(date)
  let dobYear = parseDate.getFullYear()
  let dobMonth = parseDate.getMonth() + 1
  let dobDay = parseDate.getDate()

  let currYear = new Date().getFullYear()
  let currMonth = new Date().getMonth() + 1
  let currDay = new Date().getDate()
  var dobDate = moment([dobYear, dobMonth, dobDay])

  var calculationYearDate = moment([calculationYear, '01', '01'])
  let diffYear = Math.abs(calculationYearDate.diff(dobDate, 'year', false))
  if (dobYear == calculationYear) {
    calculationYearDate = moment([currYear, currMonth, currDay])
  }
  let diffMonth = Math.abs(calculationYearDate.diff(dobDate, 'month', false))

  if (diffYear > 0) {
    return Math.trunc(diffYear)
  } else if (diffMonth > 0) {
    return (diffMonth / 12).toFixed(2)
  } else return
}

function setCalculatedAge(calculatedAge, calculationYear) {
  let yearInDob = new Date(DOBMoment.value).getFullYear()
  if (calculatedAge && yearInDob < calculationYear) {
    dobErrorMessage.value = null
    getAge(calculatedAge)
  } else if (yearInDob >= calculationYear) {
    dobErrorMessage.value = 'Calculation year should be greater than DOB year.'
    getAge(0)
  }
}

function setDateOfBirth(date, dateForMoment) {
  DOB.value = date
  DOBMoment.value = dateForMoment
  setFieldValue(date, 'DOB')
  let calculatedAge = calculateAge(
    dateForMoment,
    memberProfileData.value.inputDict.calculationYear
  )
  setCalculatedAge(
    calculatedAge,
    memberProfileData.value.inputDict.calculationYear
  )
}

function setPlateValue(value) {
  plate.value = value
  setFieldValue(value, 'plate')
}

function setCalculationYear(value) {
  calculationYear.value = value
  let calculatedAge = calculateAge(DOBMoment.value, value)
  setCalculatedAge(calculatedAge, value)
  setFieldValue(value, 'calculationYear')
}

function getAge(value) {
  age.value = value
  setFieldValue(value, 'age')
}

function getBaseRate(value) {
  baseRate.value = value
  let currentIndex = props.componentDataIndex
  let baseRateData = { name: 'baseRate', value: baseRate.value }
  updateCommandInputValue(currentIndex, baseRateData)
  let nextIndex = currentIndex + 1
  if (isNextPickCommand(nextIndex)) {
    updateCommandInputValue(nextIndex, baseRateData)
  }
}

function getGender(value) {
  gender.value = value
  setFieldValue(value, 'gender')
}

function setDefaultRiskModel(value) {
  var defaultRiskModelValue = ''
  if (store.getters.hasHhsData(value)) {
    defaultRiskModelValue = HHS_DEFAULT_RISK_MODEL
  } else if (store.getters.hasV05Data(value)) {
    defaultRiskModelValue = V05_DEFAULT_RISK_MODEL
  } else if (store.getters.hasv21ESRDData(value)) {
    defaultRiskModelValue = V21_ESRD_DEFAULT_RISK_MODEL
  } else if (store.getters.hasv24ESRDData(value)) {
    defaultRiskModelValue = V24_ESRD_DEFAULT_RISK_MODEL
  } else if (store.getters.hasPaceV21ESRDData(value)) {
    defaultRiskModelValue = PACE_V21_ESRD_DEFAULT_RISK_MODEL
  } else if (store.getters.hasPaceVData(value)) {
    defaultRiskModelValue = PACE_V_DEFAULT_RISK_MODEL
  } else if (store.getters.hasPaceV05Data(value)) {
    defaultRiskModelValue = PACE_V05_DEFAULT_RISK_MODEL
  } else {
    defaultRiskModelValue = V_DEFAULT_RISK_MODEL
  }
  getRiskModel(defaultRiskModelValue)
}

function getDosYear(value) {
  dosYear.value = value
  setFieldValue(dosYear.value, 'dosYearModel')
  setDefaultRiskModel(dosYear.value)

  // Adjust store state access
  store.state.orec_disabled = dosYear.value.startsWith('hhs')
}

function getOre(value) {
  ore.value = value
  let currentIndex = props.componentDataIndex
  let oreData = { name: 'ore', value: ore.value }
  updateCommandInputValue(currentIndex, oreData)
  let nextIndex = currentIndex + 1
  if (isNextPickCommand(nextIndex)) {
    updateCommandInputValue(nextIndex, oreData)
  }
}

function getRiskModel(value) {
  riskModel.value = value
  let currentIndex = props.componentDataIndex
  let riskModelData = { name: 'riskModel', value: riskModel.value }
  updateCommandInputValue(currentIndex, riskModelData)
  let nextIndex = currentIndex + 1
  if (isNextPickCommand(nextIndex)) {
    updateCommandInputValue(nextIndex, riskModelData)
  }
}

function isNextPickCommand(nextIndex) {
  let nextComponentData = store.getters.getComponentDataByIndex(nextIndex)
  if (typeof nextComponentData != 'undefined') {
    return nextComponentData.inputDict.commandType == 'pick'
  }
  return false
}

function updateCommandInputValue(index, InputData) {
  store.dispatch('updateStateData', {
    index: index,
    data: InputData,
  })
}

function isTagInvalid(tag) {
  return invalidChips.value.includes(tag)
}

function submit() {
  if (areAllTagsInvalid()) {
    errorMessage.value = 'All condition codes are invalid'
    snackbar.value = true
  } else if (!areInputsInvalid()) {
    isWaitingForResult.value = true
    store
      .dispatch('handleSubmitRequest', {
        index: props.componentDataIndex,
      })
      .then(() => {
        isWaitingForResult.value = false
      })
  }
  setTimeout(() => scrollToBottom('member', props.componentDataIndex), 500)
}

// Add row function
const addRow = () => {
  // Add a try-catch to help debug any errors
  try {
    store
      .dispatch('addRowToComponentData', {
        index: props.componentDataIndex,
      })
      .then((success) => {
        if (success) {
          // Scroll to the bottom after a brief delay
          setTimeout(() => {
            scrollToBottomContainer(props.componentDataIndex + 1)
          }, 300)
        }
      })
  } catch (error) {
    console.error('Error in addRow function:', error)
  }
}

function areInputsInvalid() {
  isAgeInvalid.value = age.value < 0 || age.value > 110
  isBaseRateInvalid.value = baseRate.value < 0 || isNaN(baseRate.value)
  return isAgeInvalid.value || isBaseRateInvalid.value
}

function areAllTagsInvalid() {
  return !areInputsInvalid() &&
    tagList.value.length != 0 &&
    !tagList.value.every(isTagInvalid)
    ? false
    : true
}

function deleteRow() {
  store.dispatch('deleteRow', {
    index: props.componentDataIndex,
  })
}

function clearResult() {
  isClear.value = true
  isEmptyTags.value = false
  isAgeInvalid.value = false
  isBaseRateInvalid.value = false
  tagList.value = []
  invalidChips.value = []
  isWaitingForResult.value = false

  store.dispatch('updateStateData', {
    index: props.componentDataIndex,
    data: { name: 'memberProfileConditions', value: tagList.value },
  })

  store.dispatch('clearResult', {
    index: props.componentDataIndex,
    commandType: 'member',
  })
}

function addTagFromList(item) {
  // Note: This method might need adjustment based on how you handle refs in Vue 3
  // You may need to use a template ref or a different approach
  // this.$root.$refs.CodeInput1.tag = item
  // let keyevent = { key: 'Enter' }
  // this.$root.$refs.CodeInput1.addTag(keyevent)
}
</script>

<style>
.snackbar > div > div {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.snackbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.add {
  /* Button is hidden by default */
  display: none;
}

.submitclear .hideBtn {
  display: none;
}

.showBtn {
  /* Make button visible when condition is true */
  display: block;
}

.showBtn {
  display: inline;
  color: #008c50 !important;
  border: 1px solid #008c50 !important;
}
</style>
