<template>
  <div class="ocreComponent">
    <div class="orebox-2">
      <v-select
        :disabled="orecDisabled"
        dense
        flat
        outlined
        :items="orecDisabled ? [] : items"
        item-title="text"
        item-value="value"
        v-model="selectedOre"
        color="0099D9_1"
        style="color: rgb(84, 84, 84) !important"
      ></v-select>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
// Props
const props = defineProps({
  componentDataIndex: Number,
  ore: Number,
})
// Emits
const emit = defineEmits(['onOre'])
// Store
const store = useStore()
const orecDisabled = computed(() => store.state.orec_disabled)
// Dropdown options
const items = [
  { text: 'Old Age', value: 0 },
  { text: 'Disability', value: 1 },
  { text: 'End Stage Renal Disease', value: 2 },
  { text: 'Both Disability and ESRD', value: 3 },
]
// Reactive selection
const selectedOre = computed({
  get: () => props.ore,
  set: (value) => {
    emit('onOre', Number(value))
  },
})
</script>
