<script>
import * as XLSX from 'xlsx' // Correct import for named exports from 'xlsx'
import PreviewFile from './PreviewFile.vue'

export default {
  name: 'CSVUpload',
  components: {
    previewFile: PreviewFile,
  },
  data() {
    return {
      fileName: 'Upload excel sheet or csv',
      file: null,
      error: '',
      excelData: [],
      previewed: false,
    }
  },
  computed: {
    _error() {
      return this.error
    },
  },
  methods: {
    parseSheet() {
      if (this.file) {
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          const binaryString = e.target.result
          const workbook = XLSX.read(binaryString, { type: 'binary' })
          /* Get first worksheet */
          const workSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[workSheetName]
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
          this.excelData = data
        }

        reader.readAsBinaryString(this.file)
      }
    },
    handleCSVUpload(event) {
      this.fileName = event.target.files ? event.target.files[0].name : null
      this.file = event.target.files ? event.target.files[0] : null
      if (this.file) this.error = null
    },
    showPreview() {
      if (!this.file) {
        this.error = 'Please upload a file...'
        return
      }
      this.previewed = true
      this.parseSheet()
    },
    uploadBulkData() {
      // Handle file upload logic here
    },
  },
}
</script>
