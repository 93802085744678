<template>
  <div>
    <div
      class="compare-summary"
      v-if="Object.keys(compareCodeTableJson).length !== 0"
      :id="'compare-result-table-' + componentDataIndex"
    >
      <!-- HCC column table starts here -->
      <div class="summary-table">
        <!-- calculation table -->
        <table class="summary-table-2">
          <!-- Table Head -->
          <thead>
            <tr>
              <th style="width: 67.3%">Net Summary</th>
              <th style="width: 15.5%">RAF</th>
              <th style="width: 16.8%">Monthly Premium</th>
            </tr>
          </thead>
          <!-- Table Body -->
          <tbody>
            <tr>
              <td class="left-alignment">
                Incremental Adds
                <i
                  class="fas fa-question-circle"
                  style="color: black; font-size: 12px; padding: 1px"
                  v-tooltip="{
                    content:
                      'Additional RAF from adding New Conditions to Base Conditions (e.g. results of retrospective chart audit).',
                    theme: 'epicc',
                    arrow: true,
                  }"
                ></i>
              </td>
              <td class="right-alignment">
                {{ compareCodeTableCalcDict.incrementRaf }}
              </td>
              <td class="right-alignment">
                {{ compareCodeTableCalcDict.incrementPremium | toCurrency }}
              </td>
            </tr>
            <tr>
              <td class="left-alignment">
                HCC Gaps
                <i
                  class="fas fa-question-circle"
                  style="color: black; font-size: 12px; padding: 1px"
                  v-tooltip="{
                    content:
                      'HCCs in Base Condition list missing or downgraded in New Condition List (e.g. Year-over-year suspect list)',
                    theme: 'epicc',
                    arrow: true,
                  }"
                ></i>
              </td>
              <td class="right-alignment">
                {{ compareCodeTableCalcDict.gapsRaf }}
              </td>
              <td class="right-alignment">
                {{ compareCodeTableCalcDict.gapsPremium | toCurrency }}
              </td>
            </tr>
            <tr>
              <td class="left-alignment">
                Total Difference
                <i
                  class="fas fa-question-circle"
                  style="color: black; font-size: 12px; padding: 1px"
                  v-tooltip="{
                    content: 'Total RAF difference between the two lists',
                    theme: 'epicc',
                    arrow: true,
                  }"
                ></i>
              </td>
              <td class="right-alignment">
                {{ compareCodeTableCalcDict.diffRaf }}
              </td>
              <td class="right-alignment">
                {{ compareCodeTableCalcDict.diffPremium | toCurrency }}
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>Condition Differences</th>
              <th v-if="compareCodeTableJson.flag === 'dx'">Dx</th>
              <th>HCC</th>
              <th>RAF</th>
              <th>Monthly Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="
                Object.keys(compareCodeTableJson.Summary.adds.HCC).length === 0
              "
            >
              <td>Adds</td>
              <td v-if="compareCodeTableJson.flag === 'dx'">--</td>
              <td>--</td>
              <td>--</td>
              <td>--</td>
            </tr>
            <!-- Additional rows and logic -->
          </tbody>

          <thead>
            <tr>
              <th>Downgrades</th>
              <th v-if="compareCodeTableJson.flag === 'dx'">Dx</th>
              <th>HCC</th>
              <th>RAF</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="
                Object.keys(compareCodeTableJson.Summary.downgrades.HCC.From)
                  .length === 0
              "
            >
              <td>Downgrades</td>
              <td v-if="compareCodeTableJson.flag === 'dx'">--</td>
              <td>--</td>
              <td>--</td>
              <td>--</td>
            </tr>
            <tr v-else>
              <td>Downgrades</td>
              <td v-if="compareCodeTableJson.flag === 'dx'">
                <span
                  v-for="(from_dxcode, index) in compareCodeTableJson.Summary
                    .downgrades.Dx.From"
                  :key="index"
                >
                  {{ from_dxcode }}
                  <span
                    v-if="
                      index <
                      compareCodeTableJson.Summary.downgrades.Dx.From.length - 1
                    "
                    >,
                  </span>
                </span>
              </td>
              <td>
                <span
                  v-for="(from_hcc, index) in compareCodeTableJson.Summary
                    .downgrades.HCC.From"
                  :key="index"
                >
                  {{ from_hcc }}
                  <span
                    v-if="
                      index <
                      compareCodeTableJson.Summary.downgrades.HCC.From.length -
                        1
                    "
                    >,
                  </span>
                </span>
              </td>
              <td>
                {{
                  compareCodeTableJson.Summary.downgrades.raf_change | roundOff
                }}
              </td>
              <td>
                {{
                  compareCodeTableJson.Summary.downgrades.premium | toCurrency
                }}
              </td>
            </tr>
          </tbody>

          <!-- dow ends-->
          <tr
            v-if="
              Object.keys(compareCodeTableJson.Summary.deletes.HCC).length == 0
            "
          >
            <td class="left-alignment">Deletes</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">--</td>
            <td>--</td>
            <td>--</td>
            <td>--</td>
          </tr>
          <tr v-else>
            <td class="left-alignment">Deletes</td>
            <td v-if="compareCodeTableJson.flag == 'dx'">
              <div
                class="right-alignment nested-td float-left"
                v-for="(dxcode, dxcode_deletes_index) in compareCodeTableJson
                  .Summary.deletes.Dx"
                :key="dxcode_deletes_index"
              >
                <p
                  v-if="
                    dxcode_deletes_index !=
                    compareCodeTableJson.Summary.deletes.Dx.length - 1
                  "
                >
                  {{ dxcode + ', ' }}
                </p>
                <p v-else>{{ dxcode }}</p>
              </div>
            </td>

            <td>
              <div
                class="right-alignment nested-td float-left"
                v-for="(hcccode, hcccode_deletes_index) in compareCodeTableJson
                  .Summary.deletes.HCC"
                :key="hcccode_deletes_index"
              >
                <p
                  v-if="
                    hcccode_deletes_index !=
                    compareCodeTableJson.Summary.deletes.HCC.length - 1
                  "
                >
                  <span v-if="hcccode != null">{{ hcccode + ',' }}</span>
                  <span v-else>--</span>
                </p>
                <p v-else>
                  <span v-if="hcccode != null">{{ hcccode }}</span>
                  <span v-else>--</span>
                </p>
              </div>
            </td>

            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.deletes.raf_change | roundOff }}
            </td>
            <td class="right-alignment">
              {{ compareCodeTableJson.Summary.deletes.premium | toCurrency }}
            </td>
          </tr>
          <thead>
            <tr>
              <th>Deletes</th>
              <th v-if="compareCodeTableJson.flag === 'dx'">Dx</th>
              <th>HCC</th>
              <th>RAF</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="
                Object.keys(compareCodeTableJson.Summary.deletes.HCC).length ===
                0
              "
            >
              <td>Deletes</td>
              <td v-if="compareCodeTableJson.flag === 'dx'">--</td>
              <td>--</td>
              <td>--</td>
              <td>--</td>
            </tr>
            <tr v-else>
              <td>Deletes</td>
              <td v-if="compareCodeTableJson.flag === 'dx'">
                <span
                  v-for="(dxcode, index) in compareCodeTableJson.Summary.deletes
                    .Dx"
                  :key="index"
                >
                  {{ dxcode }}
                  <span
                    v-if="
                      index < compareCodeTableJson.Summary.deletes.Dx.length - 1
                    "
                    >,
                  </span>
                </span>
              </td>
              <td>
                <span
                  v-for="(hcccode, index) in compareCodeTableJson.Summary
                    .deletes.HCC"
                  :key="index"
                >
                  <span v-if="hcccode !== null">{{ hcccode }}</span>
                  <span v-else>--</span>
                  <span
                    v-if="
                      index <
                      compareCodeTableJson.Summary.deletes.HCC.length - 1
                    "
                    >,
                  </span>
                </span>
              </td>
              <td class="right-alignment">
                {{ roundOff(compareCodeTableJson.Summary.deletes.raf_change) }}
              </td>
              <td class="right-alignment">
                {{ compareCodeTableJson.Summary.deletes.premium | toCurrency }}
              </td>
            </tr>
          </tbody>

          <thead>
            <tr>
              <th>No Change</th>
              <th v-if="compareCodeTableJson.flag === 'dx'">Dx</th>
              <th>HCC</th>
              <th>RAF</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-if="
                Object.keys(compareCodeTableJson.Summary.no_change).length === 0
              "
            >
              <td>No Change</td>
              <td v-if="compareCodeTableJson.flag === 'dx'">--</td>
              <td>--</td>
              <td>--</td>
              <td>--</td>
            </tr>
            <tr v-else>
              <td>No Change</td>
              <td v-if="compareCodeTableJson.flag === 'dx'">
                <div
                  v-if="compareCodeTableJson.Summary.no_change.Dx.length === 0"
                >
                  --
                </div>
                <template v-else>
                  <span
                    v-for="(no_change_dxcode, index) in compareCodeTableJson
                      .Summary.no_change.Dx"
                    :key="index"
                  >
                    {{ no_change_dxcode }}
                    <span
                      v-if="
                        index <
                        compareCodeTableJson.Summary.no_change.Dx.length - 1
                      "
                      >,
                    </span>
                  </span>
                </template>
              </td>
              <td
                v-if="compareCodeTableJson.Summary.no_change.HCC.length === 0"
              >
                --
              </td>
              <td v-else>
                <span
                  v-for="(no_change_hcc_code, index) in compareCodeTableJson
                    .Summary.no_change.HCC"
                  :key="index"
                >
                  {{ no_change_hcc_code }}
                  <span
                    v-if="
                      index <
                      compareCodeTableJson.Summary.no_change.HCC.length - 1
                    "
                    >,
                  </span>
                </span>
              </td>
              <td class="right-alignment">
                {{
                  compareCodeTableJson.Summary.no_change.raf_change
                    ? compareCodeTableJson.Summary.no_change.raf_change |
                      roundOff
                    : '--'
                }}
              </td>
              <td class="right-alignment">
                {{
                  compareCodeTableJson.Summary.no_change.premium
                    ? compareCodeTableJson.Summary.no_change.premium |
                      toCurrency
                    : '--'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="detail-box">
        <div class="detail-button">
          <button
            class="grey-btn"
            @click="toggleDetailView(true)"
            v-if="!isDetailViewVisible"
          >
            Detailed Output
          </button>
          <button class="grey-btn" @click="toggleDetailView(false)" v-else>
            Close
          </button>
        </div>
        <br />
        <!-- detailed table starts here -->
        <div class="detail-table">
          <table v-if="isDetailViewVisible" class="summary-table-3">
            <thead>
              <tr>
                <th style="width: 24.3%">Condition Differences</th>
                <th style="width: 13.2%">Base Condition</th>
                <th style="width: 21.7%">New Condition</th>
                <th style="width: 17.6%">RAF Change</th>
                <th style="width: 22.2%">Monthly Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="compareCodeTableJson.Detailed.adds.length !== 0">
                <td class="left-alignment">Adds</td>
                <td class="left-alignment">
                  <div
                    v-for="(baseAddCodes, baseAddIndex) in compareCodeTableJson
                      .Detailed.adds"
                    :key="baseAddIndex"
                  >
                    <template v-if="baseAddCodes.new_condition.length > 1">
                      <p
                        v-for="len in baseAddCodes.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </template>
                    <p
                      v-for="(
                        baseConditions, baseConIndex
                      ) in baseAddCodes.base_condition"
                      :key="baseConIndex"
                    >
                      {{ baseConditions }}
                    </p>
                    <template
                      v-if="compareCodeTableJson.Detailed.adds.length >= 2"
                    >
                      <hr
                        v-if="baseAddCodes.base_condition.length > 0"
                        v-show="
                          baseAddIndex !==
                          compareCodeTableJson.Detailed.adds.length - 1
                        "
                        class="hr-style-base"
                      />
                    </template>
                  </div>
                </td>
                <td class="left-alignment">
                  <div
                    v-for="(newAddCodes, newAddIndex) in compareCodeTableJson
                      .Detailed.adds"
                    :key="newAddIndex"
                  >
                    <p
                      v-for="(
                        newConditions, newConIndex
                      ) in newAddCodes.new_condition"
                      :key="newConIndex"
                    >
                      {{ newConditions }}
                    </p>
                    <template
                      v-if="compareCodeTableJson.Detailed.adds.length >= 2"
                    >
                      <hr
                        v-show="
                          newAddIndex !==
                          compareCodeTableJson.Detailed.adds.length - 1
                        "
                        class="hr-style-new-condition"
                      />
                    </template>
                  </div>
                </td>
                <td class="right-alignment">
                  <div
                    v-for="(addCodeRaf, addCodeRafIndex) in compareCodeTableJson
                      .Detailed.adds"
                    :key="addCodeRafIndex"
                  >
                    <template v-if="addCodeRaf.new_condition.length > 1">
                      <p
                        v-for="len in addCodeRaf.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </template>
                    <p>{{ roundOff(addCodeRaf.raf_change) }}</p>
                    <template
                      v-if="compareCodeTableJson.Detailed.adds.length >= 2"
                    >
                      <hr
                        v-show="
                          addCodeRafIndex !==
                          compareCodeTableJson.Detailed.adds.length - 1
                        "
                        class="hr-style-raf-change"
                      />
                    </template>
                  </div>
                </td>
                <td class="right-alignment">
                  <div
                    v-for="(addCodePre, addCodePreIndex) in compareCodeTableJson
                      .Detailed.adds"
                    :key="addCodePreIndex"
                  >
                    <template v-if="addCodePre.new_condition.length > 1">
                      <p
                        v-for="len in addCodePre.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </template>
                    <p>{{ addCodePre.premium | toCurrency }}</p>
                    <template
                      v-if="compareCodeTableJson.Detailed.adds.length >= 2"
                    >
                      <hr
                        v-show="
                          addCodePreIndex !==
                          compareCodeTableJson.Detailed.adds.length - 1
                        "
                        class="hr-style-pre-change"
                      />
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr v-if="compareCodeTableJson.Detailed.upgrades.length != 0">
                <td class="left-alignment">Upgrades</td>
                <td>
                  <div
                    class="left-alignment"
                    v-for="(uBaseCodes, uBaseIndex) in compareCodeTableJson
                      .Detailed.upgrades"
                    :key="uBaseIndex"
                  >
                    <span v-if="uBaseCodes.new_condition.length > 1">
                      <p
                        v-for="len in uBaseCodes.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p
                      v-for="(
                        uBaseConditions, uBaseConIndex
                      ) in uBaseCodes.base_condition"
                      :key="uBaseConIndex"
                    >
                      {{ uBaseConditions }}
                    </p>
                    <span
                      v-if="compareCodeTableJson.Detailed.upgrades.length >= 2"
                    >
                      <hr
                        v-if="uBaseCodes != null"
                        v-bind:style="[
                          uBaseIndex ==
                          compareCodeTableJson.Detailed.upgrades.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-base"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="left-alignment"
                    v-for="(uNewCodes, uNewIndex) in compareCodeTableJson
                      .Detailed.upgrades"
                    :key="uNewIndex"
                  >
                    <p
                      v-for="(
                        uNewConditions, uNewConIndex
                      ) in uNewCodes.new_condition"
                      :key="uNewConIndex"
                    >
                      {{ uNewConditions }}
                    </p>
                    <span
                      v-if="compareCodeTableJson.Detailed.upgrades.length >= 2"
                    >
                      <hr
                        v-if="uNewCodes != null"
                        v-bind:style="[
                          uNewIndex ==
                          compareCodeTableJson.Detailed.upgrades.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-new-condition"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="right-alignment"
                    v-for="(
                      upgradeCodeRaf, upgradeCodeRafIndex
                    ) in compareCodeTableJson.Detailed.upgrades"
                    :key="upgradeCodeRafIndex"
                  >
                    <span v-if="upgradeCodeRaf.new_condition.length > 1">
                      <p
                        v-for="len in upgradeCodeRaf.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>

                    <p>{{ upgradeCodeRaf.raf_change | roundOff }}</p>
                    <span
                      v-if="compareCodeTableJson.Detailed.upgrades.length >= 2"
                    >
                      <hr
                        v-if="upgradeCodeRaf != null"
                        v-bind:style="[
                          upgradeCodeRafIndex ==
                          compareCodeTableJson.Detailed.upgrades.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-raf-change"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="right-alignment"
                    v-for="(
                      upgradeCodePre, upgradeCodePreIndex
                    ) in compareCodeTableJson.Detailed.upgrades"
                    :key="upgradeCodePreIndex"
                  >
                    <span v-if="upgradeCodePre.new_condition.length > 1">
                      <p
                        v-for="len in upgradeCodePre.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p>{{ upgradeCodePre.premium | toCurrency }}</p>
                    <span
                      v-if="compareCodeTableJson.Detailed.upgrades.length >= 2"
                    >
                      <hr
                        v-if="upgradeCodePre != null"
                        v-bind:style="[
                          upgradeCodePreIndex ==
                          compareCodeTableJson.Detailed.upgrades.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-pre-change"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr v-if="compareCodeTableJson.Detailed.downgrades.length != 0">
                <td class="left-alignment">Downgrades</td>
                <td>
                  <div
                    class="left-alignment"
                    v-for="(dBaseCodes, dBaseIndex) in compareCodeTableJson
                      .Detailed.downgrades"
                    :key="dBaseIndex"
                  >
                    <span v-if="dBaseCodes.new_condition.length > 1">
                      <p
                        v-for="len in dBaseCodes.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p
                      v-for="(
                        dBaseConditions, dBaseConIndex
                      ) in dBaseCodes.base_condition"
                      :key="dBaseConIndex"
                    >
                      {{ dBaseConditions }}
                    </p>
                    <span
                      v-if="
                        compareCodeTableJson.Detailed.downgrades.length >= 2
                      "
                    >
                      <hr
                        v-if="dBaseCodes != null"
                        v-bind:style="[
                          dBaseIndex ==
                          compareCodeTableJson.Detailed.downgrades.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-base"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="left-alignment"
                    v-for="(dNewCodes, dNewIndex) in compareCodeTableJson
                      .Detailed.downgrades"
                    :key="dNewIndex"
                  >
                    <p
                      v-for="(
                        dNewConditions, dNewConIndex
                      ) in dNewCodes.new_condition"
                      :key="dNewConIndex"
                    >
                      {{ dNewConditions }}
                    </p>
                    <span
                      v-if="
                        compareCodeTableJson.Detailed.downgrades.length >= 2
                      "
                    >
                      <hr
                        v-if="dNewCodes != null"
                        v-bind:style="[
                          dNewIndex ==
                          compareCodeTableJson.Detailed.downgrades.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-new-condition"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="right-alignment"
                    v-for="(
                      downCodeRaf, downCodeRafIndex
                    ) in compareCodeTableJson.Detailed.downgrades"
                    :key="downCodeRafIndex"
                  >
                    <span v-if="downCodeRaf.new_condition.length > 1">
                      <p
                        v-for="len in downCodeRaf.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p>{{ downCodeRaf.raf_change | roundOff }}</p>
                    <span
                      v-if="
                        compareCodeTableJson.Detailed.downgrades.length >= 2
                      "
                    >
                      <hr
                        v-if="downCodeRaf != null"
                        v-bind:style="[
                          downCodeRafIndex ==
                          compareCodeTableJson.Detailed.downgrades.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-raf-change"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="right-alignment"
                    v-for="(
                      downCodePre, downCodePreIndex
                    ) in compareCodeTableJson.Detailed.downgrades"
                    :key="downCodePreIndex"
                  >
                    <span v-if="downCodePre.new_condition.length > 1">
                      <p
                        v-for="len in downCodePre.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p>{{ downCodePre.premium | toCurrency }}</p>
                    <span
                      v-if="
                        compareCodeTableJson.Detailed.downgrades.length >= 2
                      "
                    >
                      <hr
                        v-if="downCodePre != null"
                        v-bind:style="[
                          downCodePreIndex ==
                          compareCodeTableJson.Detailed.downgrades.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-pre-change"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr v-if="compareCodeTableJson.Detailed.deletes.length != 0">
                <td class="left-alignment">Deletes</td>
                <td>
                  <div
                    class="left-alignment"
                    v-for="(delBaseCodes, delBaseIndex) in compareCodeTableJson
                      .Detailed.deletes"
                    :key="delBaseIndex"
                  >
                    <span v-if="delBaseCodes.new_condition.length > 1">
                      <p
                        v-for="len in delBaseCodes.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p
                      v-for="(
                        delBaseConditions, delBaseConIndex
                      ) in delBaseCodes.base_condition"
                      :key="delBaseConIndex"
                    >
                      {{ delBaseConditions }}
                    </p>
                    <span
                      v-if="compareCodeTableJson.Detailed.deletes.length >= 2"
                    >
                      <hr
                        v-if="delBaseCodes != null"
                        v-bind:style="[
                          delBaseIndex ==
                          compareCodeTableJson.Detailed.deletes.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-base"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="left-alignment"
                    v-for="(delNewCodes, delNewIndex) in compareCodeTableJson
                      .Detailed.deletes"
                    :key="delNewIndex"
                  >
                    <p
                      v-for="(
                        delNewConditions, delNewConIndex
                      ) in delNewCodes.new_condition"
                      :key="delNewConIndex"
                    >
                      {{ delNewConditions }}
                    </p>
                    <span
                      v-if="compareCodeTableJson.Detailed.deletes.length >= 2"
                    >
                      <hr
                        v-if="delNewConditions != null"
                        v-bind:style="[
                          delNewIndex ==
                          compareCodeTableJson.Detailed.deletes.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-new-condition"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="right-alignment"
                    v-for="(
                      deleteCodeRaf, deleteCodeRafIndex
                    ) in compareCodeTableJson.Detailed.deletes"
                    :key="deleteCodeRafIndex"
                  >
                    <span v-if="deleteCodeRaf.new_condition.length > 1">
                      <p
                        v-for="len in deleteCodeRaf.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p>{{ deleteCodeRaf.raf_change | roundOff }}</p>
                    <span
                      v-if="compareCodeTableJson.Detailed.deletes.length >= 2"
                    >
                      <hr
                        v-if="deleteCodeRaf != null"
                        v-bind:style="[
                          deleteCodeRafIndex ==
                          compareCodeTableJson.Detailed.deletes.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-raf-change"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="right-alignment"
                    v-for="(
                      deleteCodePre, deleteCodePreIndex
                    ) in compareCodeTableJson.Detailed.deletes"
                    :key="deleteCodePreIndex"
                  >
                    <span v-if="deleteCodePre.new_condition.length > 1">
                      <p
                        v-for="len in deleteCodePre.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p>{{ deleteCodePre.premium | toCurrency }}</p>
                    <span
                      v-if="compareCodeTableJson.Detailed.deletes.length >= 2"
                    >
                      <hr
                        v-if="deleteCodePre != null"
                        v-bind:style="[
                          deleteCodePreIndex ==
                          compareCodeTableJson.Detailed.deletes.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-pre-change"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr v-if="compareCodeTableJson.Detailed.no_change.length != 0">
                <td class="left-alignment">No Change</td>
                <td>
                  <div
                    class="left-alignment"
                    v-for="(
                      noChangeBase, noChangeBaseIndex
                    ) in compareCodeTableJson.Detailed.no_change"
                    :key="noChangeBaseIndex"
                  >
                    <span v-if="noChangeBase.new_condition.length > 1">
                      <p
                        v-for="len in noChangeBase.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p
                      v-for="(
                        ncBaseConditions, ncBaseConIndex
                      ) in noChangeBase.base_condition"
                      :key="ncBaseConIndex"
                    >
                      {{ ncBaseConditions }}
                    </p>
                    <span
                      v-if="compareCodeTableJson.Detailed.no_change.length >= 2"
                    >
                      <hr
                        v-if="noChangeBase != null"
                        v-bind:style="[
                          noChangeBaseIndex ==
                          compareCodeTableJson.Detailed.no_change.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-base"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="left-alignment"
                    v-for="(
                      noChangeNew, noChangeNewIndex
                    ) in compareCodeTableJson.Detailed.no_change"
                    :key="noChangeNewIndex"
                  >
                    <p
                      v-for="(
                        ncNewConditions, ncNewConIndex
                      ) in noChangeNew.new_condition"
                      :key="ncNewConIndex"
                    >
                      {{ ncNewConditions }}
                    </p>
                    <span
                      v-if="compareCodeTableJson.Detailed.no_change.length >= 2"
                    >
                      <hr
                        v-if="noChangeNew != null"
                        v-bind:style="[
                          noChangeNewIndex ==
                          compareCodeTableJson.Detailed.no_change.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-new-condition"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="right-alignment"
                    v-for="(
                      noChangeRaf, noChangeRafIndex
                    ) in compareCodeTableJson.Detailed.no_change"
                    :key="noChangeRafIndex"
                  >
                    <span v-if="noChangeRaf.new_condition.length > 1">
                      <p
                        v-for="len in noChangeRaf.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p>{{ noChangeRaf.raf_change | roundOff }}</p>
                    <span
                      v-if="compareCodeTableJson.Detailed.no_change.length >= 2"
                    >
                      <hr
                        v-if="noChangeRaf != null"
                        v-bind:style="[
                          noChangeRafIndex ==
                          compareCodeTableJson.Detailed.no_change.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-raf-change"
                      />
                    </span>
                  </div>
                </td>

                <td>
                  <div
                    class="right-alignment"
                    v-for="(
                      noChangePre, noChangePreIndex
                    ) in compareCodeTableJson.Detailed.no_change"
                    :key="noChangePreIndex"
                  >
                    <span v-if="noChangePre.new_condition.length > 1">
                      <p
                        v-for="len in noChangePre.new_condition.length - 1"
                        :key="len"
                        class="hidden-block"
                      >
                        --
                      </p>
                    </span>
                    <p>{{ noChangePre.premium | toCurrency }}</p>
                    <span
                      v-if="compareCodeTableJson.Detailed.no_change.length >= 2"
                    >
                      <hr
                        v-if="noChangePre != null"
                        v-bind:style="[
                          noChangePreIndex ==
                          compareCodeTableJson.Detailed.no_change.length - 1
                            ? { display: 'none' }
                            : { display: 'block' },
                        ]"
                        class="hr-style-pre-change"
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td class="left-alignment">Total</td>
                <td colspan="2"></td>
                <td class="right-alignment">
                  {{ compareCodeTableCalcDict.totalRafChange }}
                </td>
                <td class="right-alignment">
                  {{ compareCodeTableCalcDict.totalPremium | toCurrency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped src="../../../assets/css/comparetable.css"></style>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
  componentDataIndex: Number,
})

const store = useStore()

const compareCodeTableJson = computed(() => {
  return store.getters.getComponentDataByIndex(props.componentDataIndex)
    .responseData.data
})

const compareCodeTableCalcDict = computed(() => {
  return store.getters.getComponentDataByIndex(props.componentDataIndex)
    .compareCodecalculationDict
})

const isDetailButtonVisible = ref(true)
const isCloseButtonVisible = ref(false)
</script>
