<template>
  <div
    class="container-fluid support-page-container"
    ref="support-container-ref"
  >
    <v-dialog
      class="patch-notes-model"
      v-model="this.$store.state.isPatchNotesModal"
      @click:outside="handleModal()"
      :max-width="this.window.width < 768 ? '90%' : '60%'"
    >
      <!-- <patchNotes v-model="this.$store.state.isPatchNotesModal" /> -->
    </v-dialog>
    <div v-if="showNotif" class="top-review-bar">
      <p class="rate-us">
        <a
          target="_blank"
          href="https://appsource.microsoft.com/en-us/product/office/WA200001947?tab=Reviews#"
          style="font-weight: bold"
          class="leave-a-rev-link"
        >
          <span>Leave A Review</span> &nbsp;
        </a>
        |&nbsp;
        <span>
          Help us polish our product! Send your suggestions, improvements, or
          bug reports to
        </span>
        <a
          target="_blank"
          class="mail-link"
          v-tippy="{
            theme: 'epicc',
            arrow: false,
            content: showCopyToolTip == false ? 'Click to copy' : 'Copied',
          }"
          @click="
            ;(showCopyToolTip = showCopyToolTip == false ? true : false) &&
              copyToClipboard('support.epicc@episource.com')
          "
          @mouseleave="showCopyToolTip = false"
        >
          support.epicc@episource.com
        </a>
      </p>

      <button class="close-notif" @click="closeNotif()">
        <v-icon class="icon-light mdi-close-support">mdi-close</v-icon>
      </button>
    </div>
    <div class="each-box-heading top-heading">
      <div class="heading-box col-sm-12 col-md-12">Overview</div>
    </div>
    <div>
      <div class="each-topic-container">
        <div class="">
          <p class="head-content">
            <b>Episource’s Risk Adjustment Toolkit</b> delivers a comprehensive
            set of tools for analyzing Medicare Advantage risk adjustment data.
            Powered by Risk Adjustment Toolkit, the industry’s leading Risk
            Adjustment Analytics engine, the Excel add-in empowers health plans
            and provider groups to better understand their members’ risk scores,
            identify DX/HCC gaps, and track risk program ROI.
          </p>
        </div>
        <div
          class="subheading-box"
          style="margin-bottom: 0px; padding-left: 0px; margin-top: 20px"
        >
          Features:
        </div>
        <div class="">
          <ul class="feature-content">
            <li>
              Calculate member risk scores and forecast premiums based on
              demographic and diagnosis information
            </li>
            <li>Perform analysis on DX codes and/or HCCs</li>
            <li>
              Get descriptions and reference details about DX codes or HCCs
              without leaving Excel
            </li>
            <li>
              Identify Year-over-Year HCC gaps and calculate their financial
              impacts
            </li>
            <li>
              Calculate impact and ROI of incremental codes identified in risk
              adjustment programs
            </li>
            <li>
              Full implementation of the CMS HCC Models, including hierarchies,
              age/sex edits, and interaction codes
            </li>
            <li>Coverage of all risk factor types</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="each-box-heading marginTop">
      <div class="heading-box col-sm-12 col-md-12">Function Arguments</div>
    </div>
    <div>
      <div class="each-topic-container">
        <div class="table-container" style="margin-left: -8px">
          <v-dialog
            v-model="this.$store.state.isCmsModal"
            max-width="75%"
            @click:outside="toggleCmsModal()"
          >
            <div class="cms-container">
              <CmsModal v-model="this.$store.state.isCmsModal" />
            </div>
          </v-dialog>
          <table class="table-style-main">
            <thead>
              <tr class="tr-style">
                <th style="width: 18%">Function Parameters</th>
                <th>Description</th>
              </tr>
            </thead>
            <tr
              v-for="(data, index) in table_data[0].description_option"
              v-bind:key="data.function_parameters"
            >
              <td class="function-parameters">
                {{ data.function_parameters }}
                <br /><span
                  style="margin-top: 7px; display: block"
                  v-if="index === 2"
                >
                  (CMS Model + DOS Year)<b>*</b></span
                >
              </td>
              <td :class="['description-column-td-'] + index">
                {{ data.description }}
                <table v-if="index === 2" class="ver-table">
                  <thead>
                    <tr>
                      <th
                        rowspan="2"
                        class="ver-table-title"
                        @click="toggleCmsModal()"
                      >
                        Health Insurance Plans
                        <v-icon
                          color="#008C50"
                          class="p-1"
                          size="15"
                          style="margin-left: 5px; padding-bottom: 1.5px"
                          dark
                          v-tippy="{
                            theme: 'epicc-green',
                            arrow: true,
                            content: `Click to see more details`,
                            animation: 'fade',
                          }"
                          >fas fa-question-circle</v-icon
                        >
                      </th>
                      <th colspan="8" class="ver-table-dos">DOS Year</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr class="ver-table-yr-row">
                      <th>2017</th>
                      <th>2018</th>
                      <th>2019</th>
                      <th>2020</th>
                      <th>2021</th>
                      <th>2022</th>
                      <th>2023</th>
                      <th>2024</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td class="ver-table-col-headings">Medicare Part C</td>
                      <td>v22_2017</td>
                      <td>v23_2018</td>
                      <td>v24_2019</td>
                      <td>v24_2020</td>
                      <td>v24_2021</td>
                      <td>v24_2022</td>
                      <td>v24_2023<br />v28_2023<br />pace_v22_2023</td>
                      <td>v24_2024<br />v28_2024<br />pace_v22_2024</td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td class="ver-table-col-headings">Medicare Part D</td>
                      <td>v05_2017</td>
                      <td>v05_2018</td>
                      <td>v05_2019</td>
                      <td>v05_2020</td>
                      <td>v05_2021</td>
                      <td>v08_2022</td>
                      <td>v08_2023<br />pace_v05_2023</td>
                      <td>v08_2024<br />pace_v05_2024</td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td class="ver-table-col-headings">
                        Affordable Care Act
                      </td>
                      <td>hhs_v05_2017</td>
                      <td>hhs_v05_2018</td>
                      <td>hhs_v05_2019</td>
                      <td>hhs_v05_2020</td>
                      <td>hhs_v05_2021</td>
                      <td>hhs_v07_2022</td>
                      <td>hhs_v07_2023</td>
                      <td>hhs_v07_2024</td>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <td class="ver-table-col-headings">
                        Medicare Part E (ESRD)
                      </td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>esrd_v21_2020</td>
                      <td>esrd_v21_2021</td>
                      <td>esrd_v24_2022</td>
                      <td>esrd_v24_2023<br />pace_esrd_v21_2024</td>
                      <td>esrd_v24_2024<br />pace_esrd_v21_2023</td>
                    </tr>
                  </thead>
                </table>

                <div v-if="index === 3" class="cms-models-table-container">
                  <div class="cms-models-table item1">
                    <h4>Medicare Part - C</h4>
                    <Table
                      :table_data="
                        table_data[0].description_option[3].part_c_data
                      "
                    ></Table>
                  </div>
                  <div class="cms-models-table item2">
                    <h4>Medicare Part - D</h4>
                    <div class="part-d-models-container">
                      <Table
                        :table_data="
                          table_data[0].description_option[3].part_d_data
                        "
                      ></Table>
                    </div>
                  </div>
                  <div class="cms-models-table item3">
                    <h4>Affordable Care Act</h4>
                    <Table
                      style="margin-right: 20px !important"
                      :table_data="
                        table_data[0].description_option[3].commercial_data
                      "
                    ></Table>
                  </div>
                  <div class="cms-models-table item4">
                    <h4>Medicare Part - E (v21)</h4>
                    <div class="part-d-models-container">
                      <Table
                        :table_data="
                          table_data[0].description_option[3].esrd_data_v21
                        "
                      ></Table>
                    </div>
                  </div>
                  <div class="cms-models-table item5">
                    <h4>Medicare Part - E (v24)</h4>
                    <div class="part-d-models-container">
                      <Table
                        :table_data="
                          table_data[0].description_option[3].esrd_data_v24
                        "
                      ></Table>
                    </div>
                  </div>
                </div>

                <div v-if="index === 6" class="plate-table-container">
                  <div class="plate-table">
                    <h4>Plates provided by our Toolkit:</h4>
                    <ul class="plate-list">
                      <li
                        v-for="data in table_data[0].description_option[6]
                          .plate_data"
                        v-bind:key="data.plate"
                      >
                        <span>{{ data.plate }}</span
                        >{{ ' - ' }}<span>{{ data.description }}</span>
                      </li>
                    </ul>
                    <!-- <Table :table_data="table_data[0].description_option[6].plate_data" style="margin-top: 8px;"></Table> -->
                  </div>
                </div>
                <ul class="plate-list" v-if="index === 9">
                  <li>{{ data.part_c }}</li>
                  <li>{{ data.part_d }}</li>
                  <li>{{ data.aid }}</li>
                  <li>{{ data.commercial }}</li>
                </ul>
              </td>
            </tr>
          </table>

          <p style="margin-top: 15px; margin-left: 8px">
            <b>*As available in the Risk Adjustment Toolkit</b>
          </p>
        </div>
      </div>
    </div>
    <div class="each-box-heading marginTop">
      <div class="heading-box col-sm-12 col-md-12">Functions</div>
    </div>
    <div>
      <div class="each-topic-container">
        <div class="table-container" style="margin-left: -8px">
          <Table
            :table_data="table_data[1].full_list_of_functions_and_more_examples"
            :headers_required="false"
            :start_index="0"
          ></Table>
        </div>
      </div>
    </div>
    <div class="each-box-heading marginTop">
      <div class="heading-box col-sm-12 col-md-12">Some Examples:</div>
    </div>
    <div>
      <div class="each-topic-container">
        <div class="example-container">
          <div class="subheading-box">
            <div class="subheading-box-content">
              1. Conversion of DX to HCC, calculation of demo RAF, HCC RAF and
              total RAF:
              <v-icon
                color="black"
                class="p-1"
                size="16"
                dark
                v-tippy="{
                  theme: 'epicc',
                  arrow: true,
                  content: 'Copy command to clipboard',
                }"
              >
                fas fa-info-circle</v-icon
              >
            </div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[2].conversion_of_dx_to_hcc_example"
              :headers_required="true"
              :start_index="0"
            >
            </Table>
          </div>
          <div class="sub-subheading-box">Output for table (1):</div>
          <div class="table-container">
            <Table
              :table_data="table_data[2].conversion_of_dx_hcc_output"
              :headers_required="true"
              :start_index="6"
            >
            </Table>
          </div>
        </div>
        <div class="example-container">
          <div class="">
            <div class="subheading-box">
              <div class="subheading-box-content">
                2. Calculation of incremental HCC/DX, RAF change and ROI:
                <v-icon
                  color="black"
                  class="p-1"
                  size="16"
                  dark
                  v-tippy="{
                    theme: 'epicc',
                    arrow: true,
                    content: 'Copy command to clipboard',
                  }"
                >
                  fas fa-info-circle</v-icon
                >
              </div>
            </div>
            <div class="sub-subheading-box">
              a) Calculation with incremental DX:
            </div>
          </div>
          <div class="table-container">
            <Table
              :table_data="
                table_data[3].conversion_of_incremental_dx_raf_change_and_roi
              "
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="sub-subheading-box">Output for table (2.a):</div>
          <div class="table-container">
            <Table
              :table_data="table_data[3].conversion_of_incremental_dx_output"
              :headers_required="true"
              :start_index="8"
            ></Table>
          </div>
          <br />
          <div class="">
            <div class="sub-subheading-box">
              b) Calculation with incremental HCC:
            </div>
          </div>
          <div class="table-container">
            <Table
              :table_data="
                table_data[3].conversion_of_incremental_hcc_raf_change_and_roi
              "
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="sub-subheading-box">Output for table (2.b):</div>
          <div class="table-container">
            <Table
              :table_data="table_data[3].conversion_of_incremental_hcc_output"
              :headers_required="true"
              :start_index="7"
            ></Table>
          </div>
        </div>
        <div class="example-container">
          <div class="">
            <div class="subheading-box">
              <div class="subheading-box-content">
                <div class="subheading-box-content">
                  3. HCC and DX Gaps YOY:
                  <v-icon
                    color="black"
                    class="p-1"
                    size="16"
                    dark
                    v-tippy="{
                      theme: 'epicc',
                      arrow: true,
                      content: 'Copy command to clipboard',
                    }"
                  >
                    fas fa-info-circle</v-icon
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <div class="sub-subheading-box">a) DX Gaps YOY:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[4].dx_gaps_yoy"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="">
            <div class="sub-subheading-box">Output for table (3.a)</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[4].dx_gaps_yoy_output"
              :headers_required="true"
              :start_index="7"
            ></Table>
          </div>
          <br />
          <div class="">
            <div class="sub-subheading-box">b) HCC Gaps YOY:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[4].hcc_gaps_yoy"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="">
            <div class="sub-subheading-box">Output for table (3.b)</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[4].hcc_gaps_yoy_output"
              :headers_required="true"
              :start_index="7"
            ></Table>
          </div>
        </div>
        <div class="example-container">
          <div class="">
            <div class="subheading-box">
              <div class="subheading-box-content">4. Member Profile:</div>
            </div>
            <div class="sub-subheading-box map-1">Input Table:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[5].raf_profile.input"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="">
            <div class="sub-subheading-box">Command:</div>
          </div>
          <div class="table-container">
            <table class="table-style-main">
              <thead>
                <tr>
                  <th
                    class="command-cell"
                    v-html="table_data[5].raf_profile.command"
                  ></th>
                  <th
                    class="copy-to-clip-cell"
                    @click="
                      copyToClipboard(
                        table_data[5].raf_profile.non_formatted_command
                      )
                    "
                    v-tippy="{
                      theme: 'epicc',
                      arrow: false,
                      content: 'Copy command to clipboard',
                      distance: '2',
                    }"
                    aria-hidden="true"
                  >
                    <v-icon class="fa fa-clipboard p-4"></v-icon>
                  </th>
                </tr>
              </thead>
            </table>
          </div>

          <br />
          <div class="">
            <div class="sub-subheading-box">Output Table:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[5].raf_profile.output"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
        </div>

        <div class="example-container">
          <div class="">
            <div class="subheading-box">
              <div class="subheading-box-content">5. DX Profile Compare:</div>
            </div>
            <div class="sub-subheading-box map-1">Claims Input Table:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[6].dx_profile_compare.claims_input_table"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <br />
          <div class="">
            <div class="sub-subheading-box map-2">Coding Input Table:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[6].dx_profile_compare.coding_input_table"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="">
            <div class="sub-subheading-box">Command:</div>
          </div>
          <div class="table-container">
            <table class="table-style-main">
              <tbody>
                <tr>
                  <td
                    class="command-cell"
                    v-html="table_data[6].dx_profile_compare.command"
                  ></td>
                  <td
                    class="copy-to-clip-cell"
                    @click="
                      copyToClipboard(
                        table_data[6].dx_profile_compare.non_formatted_command
                      )
                    "
                    v-tippy="{
                      theme: 'epicc',
                      arrow: false,
                      content: 'Copy command to clipboard',
                      distance: '2',
                    }"
                    aria-hidden="true"
                  >
                    <v-icon
                      class="fa fa-clipboard p-4"
                      aria-hidden="true"
                    ></v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div class="">
            <div class="sub-subheading-box">Output Table:</div>
          </div>
          <div class="table-container overflow-scroll-x">
            <Table
              :table_data="table_data[6].dx_profile_compare.output_table"
              :headers_required="true"
              :start_index="0"
            >
            </Table>
          </div>
        </div>

        <div class="example-container">
          <div class="">
            <div class="subheading-box">
              <div class="subheading-box-content">6. HCC Profile Compare:</div>
            </div>
            <div class="sub-subheading-box map-1">Claims Input Table:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[7].hcc_profile_compare.claims_input_table"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <br />
          <div class="">
            <div class="sub-subheading-box map-2">Coding Input Table:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[7].hcc_profile_compare.coding_input_table"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="">
            <div class="sub-subheading-box">Command:</div>
          </div>
          <div class="table-container">
            <table class="table-style-main">
              <tbody>
                <tr>
                  <td
                    class="command-cell"
                    v-html="table_data[7].hcc_profile_compare.command"
                  ></td>
                  <td
                    class="copy-to-clip-cell"
                    @click="
                      copyToClipboard(
                        table_data[7].hcc_profile_compare.non_formatted_command
                      )
                    "
                    v-tippy="{
                      theme: 'epicc',
                      arrow: false,
                      content: 'Copy command to clipboard',
                      distance: '2',
                    }"
                    aria-hidden="true"
                  >
                    <v-icon
                      class="fa fa-clipboard p-4"
                      aria-hidden="true"
                    ></v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div class="">
            <div class="sub-subheading-box">Output Table:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[7].hcc_profile_compare.output_table"
              :headers_required="true"
              :start_index="0"
            >
            </Table>
          </div>
        </div>

        <div class="example-container">
          <div class="">
            <div class="subheading-box">
              <div class="subheading-box-content">
                7. Disease Duration:
                <v-icon
                  color="black"
                  class="p-1"
                  size="16"
                  dark
                  v-tippy="{
                    theme: 'epicc',
                    arrow: true,
                    content: 'Copy command to clipboard',
                  }"
                >
                  fas fa-info-circle</v-icon
                >
              </div>
            </div>
          </div>
          <div class="">
            <div class="sub-subheading-box">a) Disease Duration with DX:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[8].disease_duration.table1"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="sub-subheading-box">Output for table (7.a)</div>
          <div class="table-container">
            <Table
              :table_data="table_data[8].disease_duration.table1Output"
              :headers_required="true"
              :start_index="2"
            >
            </Table>
          </div>
          <br />
          <div class="">
            <div class="sub-subheading-box">b) Disease Duration with HCC:</div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[8].disease_duration.table2"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
          <div class="sub-subheading-box">Output for table (7.b)</div>
          <div class="table-container">
            <Table
              :table_data="table_data[8].disease_duration.table2Output"
              :headers_required="true"
              :start_index="2"
            >
            </Table>
          </div>
        </div>
        <div class="example-container">
          <div class="">
            <div class="subheading-box">
              <div class="subheading-box-content">
                8. Some more examples:
                <v-icon
                  color="black"
                  class="p-1"
                  size="16"
                  dark
                  v-tippy="{
                    theme: 'epicc',
                    arrow: true,
                    content: 'Copy command to clipboard',
                  }"
                >
                  fas fa-info-circle</v-icon
                >
              </div>
            </div>
          </div>
          <div class="table-container">
            <Table
              :table_data="table_data[9].some_more_examples"
              :headers_required="true"
              :start_index="0"
            ></Table>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-to-top-button">
      <v-btn
        v-if="showScrollButton"
        @click="handleScrollToTop"
        icon
        color="primary"
        class="scroll-button"
        v-tippy="{
          theme: 'epicc-green',
          arrow: true,
          content: 'Back to top',
        }"
      >
        <!-- <p style="color: black; margin-right: 5px;">Scroll to top </p> -->
        <v-icon color="black" size="30">mdi-arrow-up</v-icon>
      </v-btn>
    </div>
    <Footer></Footer>
  </div>
</template>
<!-- <style src="../../assets/css/patchNotes.css"></style> -->
<script>
import table_data from '../../data/data.json'
// import PatchNotes from '../common/PatchNotes.vue'
import Table from '../common/Table.vue'
import Footer from '../common/Footer.vue'
import CmsModal from '../common/CmsModal.vue'
import { scrollToTop } from '../common/helperFunctions'
import patchNotesItems from '../../data/patchNotesDetails.js'
export default {
  data() {
    return {
      table_data: table_data,
      patchNotesDialog: true,
      local: localStorage.getItem('removePatch'),
      showNotif: true,
      showCopyToolTip: false,
      showScrollButton: false,
      window: {
        width: 0,
        height: 0,
      },
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  components: {
    // patchNotes: PatchNotes,
    Table,
    Footer,
    CmsModal,
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 200) {
        this.showScrollButton = true
      } else {
        this.showScrollButton = false
      }
    },
    handleScrollToTop() {
      scrollToTop()
      this.showScrollButton = false
    },
    toggleModal(value) {
      this.patchNotesDialog = value
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      if (this.window.width <= 410 && this.showNotif == true) {
        this.$store.state.topMargin = '110px'
      } else if (this.window.width <= 602 && this.showNotif == true) {
        this.$store.state.topMargin = '100px'
      } else if (this.window.width < 1130 && this.showNotif == true) {
        this.$store.state.topMargin = '65px'
      } else if (this.showNotif == true) {
        this.$store.state.topMargin = '46px'
      } else {
        this.$store.state.topMargin = '0px'
      }
    },
    handleModal() {
      this.$store.commit('togglePatchNotesModal')
      // PatchNotesCommenting
      // localStorage.setItem('noOfPatchNotes', patchNotesItems.length)
    },
    copyToClipboard(message) {
      navigator.clipboard
        .writeText(message)
        .then(() => {})
        .catch(() => {})
    },
    toggleCmsModal() {
      this.$store.commit('updateCmsModal')
    },
    closeNotif() {
      // this.$refs['support-container-ref'].style.marginTop = '80px'
      this.showNotif = false
      this.$store.state.topMargin = '0'
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style>
.heading-box {
  background: #f3f1ee;
  font-size: 24px;
  font-weight: 400;
  color: #008c50;
  padding: 1px 20px 1px 20px;
  width: max-content;
  margin-left: 60px;
  border-bottom: 1px solid lightgrey;
}
.patch-notes-model {
  max-width: 60% !important;
}
.subheading-box {
  width: 99.1%;
  margin-bottom: 10px;
  margin-top: 10px;
  /* background: #0099d9; */
  min-height: 36px;
  font-weight: 400;
  font-size: 20px;
  color: black;
  margin-top: 10px;
  padding-top: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subheading-box-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sub-subheading-box {
  width: 99.1%;
  margin-bottom: 10px;
  margin-top: 10px;
  /* background: #0099d9; */
  min-height: 36px;
  font-weight: 400;
  font-size: 18px;
  color: black;
  margin-top: 10px;
  padding-top: 3px;
  margin-left: 8px;
}

.head-content {
  font-size: 16px;
  font-weight: 400;
  width: 98%;
}

.header-main-block-title {
  /* margin-right: 33px !important; */
  /* font-size: normal !important; */
  text-align: center !important;
  /* top: 45% !important; */
}

.hexader-main-block-title {
  font-size: min(2.5vw, 30px);
  color: #008c50 !important;
}

.feature-content {
  font-size: 16px;
  font-weight: 400;
  padding-left: 18px !important;
}

.feature-content li {
  margin: 5px 0px;
}

.tr-style {
  background: #008c50;
  color: white;
  height: 36px;
}

th {
  padding: 5px;
}

th,
td {
  border: 1.5px solid #ddd;
}

td {
  font-weight: 400;
  padding: 7px;
}

.each-topic-container {
  margin-left: 60px;
  padding-top: 18px;
}

.each-box-heading {
  /* margin-top: 40px; */
  padding-top: 40px;
}

.command-cell {
  word-break: break-all;
}

.copy-to-clip-cell {
  border-left: 0px;
  width: 50px;
  text-align: center;
  font-size: 20px;
  box-shadow: 0 0 3px #ccc;
  background: #eee;
}

.copy-to-clip-cell:hover {
  cursor: pointer;
}

.example-container {
  margin-bottom: 70px;
  margin-left: -8px;
}

.table-container {
  width: 98%;
  overflow-x: auto;
}

/* Version table */
.ver-table {
  width: 97%;
}

.ver-table,
.ver-table th,
.ver-table td {
  border: 1px solid black;
}

.ver-table-title,
.ver-table-yr-row td,
.ver-table-dos {
  font-weight: 600;
}

.ver-table-col-headings {
  font-weight: 500;
}

.ver-table-title:hover {
  cursor: pointer;
}

.ver-table-dos {
  text-align: center;
}

td.description-column-td-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.cms-container {
  width: auto;
  height: auto;
  transition: 2s ease;
  background: #fcfcfc !important;
  padding: 20px;
  top: 23%;
}

.plate-table {
  margin-top: 15px;
}

.cms-models-table-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
}

.function-parameters {
  text-align: center;
  color: #008c50;
  font-weight: 600;
}

.cms-models-container {
  text-align: center;
}

.cms-models-table h4 {
  text-align: center;
}

.plate-table-container .table-style-main {
  margin: 0;
  width: 20%;
}

.plate-list {
  padding-left: 15px !important;
  margin-top: 5px;
  font-weight: bold;
}

.rate-us > a {
  color: #fff !important;
  font-size: 18px;
  /* font-weight: bold; */
}

.top-review-bar {
  position: fixed;
  top: 0;
  background: #008c50;
  width: 100%;
  font-size: 18px;
  display: flex;
  color: #fff;
  font-weight: normal;
  text-align: center;
  align-items: center;
  padding: 10px;
  justify-content: center;
  gap: 15px;
  z-index: 100;
  padding-left: 20px;
  padding-right: 20px;
}

.mail-link {
  color: #fff !important;
  text-decoration: underline !important;
}

.close-notif {
  font-weight: bold;
  transition: 0.5s ease;
}

.icon-light::before {
  color: #fff !important;
}

.mdi:before,
.mdi-set {
  color: #fff !important;
}

.leave-a-rev-link {
  font-weight: bold;
}
.leave-a-rev-link span:hover {
  text-decoration: underline !important;
}
.formulas {
  margin-right: 33px;
}
.map-1 {
  color: #e64a19;
}

.map-2 {
  color: #0047ab;
  /* color: #800020; */
}
.p-1 {
  top: -1px;
  cursor: pointer;
}

.item1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.item2 {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.item3 {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.item4 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.item5 {
  grid-column: 3 / 4;
  grid-row: 1 / 3;
}

.cms-models-table {
  padding: 10px 20px;
}
</style>
