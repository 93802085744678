<template>
  <div class="dosyearmodel">
    <v-select
      class="dosyearmodel dymbox-2"
      dense
      flat
      outlined
      :items="items"
      item-title="label"
      item-value="value"
      v-model="selectedDosYear"
      color="0099D9_1"
      style="color: #545454"
    ></v-select>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import dosYearData from '../../data/dosYearData.json'
// Props
const props = defineProps({
  componentDataIndex: Number,
  dosYearModel: String,
})
// Emits
const emit = defineEmits(['onDosYear'])
// Dropdown options (Ensure JSON has correct structure)
const items = dosYearData.map((item) => ({
  label: item.text || item.label, // Ensure a display label exists
  value: item.value,
}))
// Compute the selected value dynamically
const selectedDosYear = computed({
  get: () =>
    props.dosYearModel === 'v24_2022' ? 'v24_2022' : props.dosYearModel,
  set: (value) => {
    emit('onDosYear', getDosValue(value))
  },
})
// Helper function to extract DOS value
function getDosValue(dosValue) {
  if (dosValue.includes('/')) {
    return dosValue.split('/').pop().trim() // Extracts the last part after '/'
  }
  return dosValue
}
</script>
