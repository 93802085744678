<template>
  <div>
    <div class="main pt-5 compare-box">
      <div class="compare-box-between">
        <div class="compare-box-label condition-text">
          Base Conditions
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForBaseCondition,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="conditioninput-1">
          <div style="position: relative">
            <code-input
              id="code-input-1"
              ref="baseCodeInputRef"
              :tagList="baseConditionTagList"
              searchBox="tag1"
              :tagstring="tag1"
              :componentDataIndex="componentDataIndex"
              :riskModel="riskModel"
              :dosYearModel="dosYearModel"
              @getTag="updateTagLists($event, 'baseConditions')"
              @removeTag="updateTagLists($event, 'baseConditions')"
              :isClearTag="isClear"
              :isEmptyTagList="isBaseTagListEmpty"
              :commandType="commandType"
              @clearTagFlag="isClear = false"
              @invalidChips="getInvalidChips($event, baseInvalidChips)"
              @emptyTagsFlag="isBaseTagListEmpty = false"
            ></code-input>
            <div
              v-if="store.state.search_box === 'tag1'"
              class="member-search-suggestion"
              :class="[
                store.state.search_member.length > 0 &&
                store.state.search_box === 'tag1'
                  ? 'suggestion-box'
                  : '',
                store.state.search_suggestion_loader &&
                store.state.search_box === 'tag1'
                  ? 'show-box'
                  : '',
              ]"
            >
              <v-progress-circular
                indeterminate
                color="red"
                size="32"
                class="compare-code-loader"
                v-if="store.state.search_suggestion_loader"
              ></v-progress-circular>
              <ul v-if="store.state.search_box === 'tag1'">
                <li
                  v-for="item in store.state.search_member"
                  :key="item"
                  @click="addTagFromList(item, 'tag1')"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label condition-text">
          New Conditions
          <v-icon
            tabindex="-1"
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForNewCondition,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="conditioninput-2">
          <div style="position: relative">
            <code-input
              ref="newCodeInputRef"
              :tagList="newConditionTagList"
              searchBox="tag2"
              :tagstring="tag2"
              :componentDataIndex="componentDataIndex"
              :riskModel="riskModel"
              :dosYearModel="dosYearModel"
              @getTag="updateTagLists($event, 'newConditions')"
              @removeTag="updateTagLists($event, 'newConditions')"
              :isClearTag="isClear2"
              @clearTagFlag="isClear2 = false"
              :isEmptyTagList="isNewTagListEmpty"
              :commandType="commandType"
              @invalidChips="getInvalidChips($event, newInvalidChips)"
              @emptyTagsFlag="isNewTagListEmpty = false"
            ></code-input>
            <div
              v-if="store.state.search_box === 'tag2'"
              class="member-search-suggestion"
              :class="[
                store.state.search_member.length > 0 &&
                store.state.search_box === 'tag2'
                  ? 'suggestion-box'
                  : '',
                store.state.search_suggestion_loader &&
                store.state.search_box === 'tag2'
                  ? 'show-box'
                  : '',
              ]"
            >
              <v-progress-circular
                indeterminate
                color="red"
                size="32"
                class="compare-code-loader"
                v-if="store.state.search_suggestion_loader"
              ></v-progress-circular>
              <ul>
                <li
                  v-for="item in store.state.search_member"
                  :key="item"
                  @click="addTagFromList(item, 'tag2')"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>

          <v-dialog v-model="dialog" max-width="80%">
            <SearchComponent
              v-model="dialog"
              @confirm-action="toggleModal(false)"
            />
          </v-dialog>
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label agelabel">
          Age:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForAge,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="ageinput">
          <age-input
            @on-age="getAge"
            :component-data-index="componentDataIndex"
            :age="ageValue"
            :is-age-invalid="isAgeInvalid"
            @valid-age="isAgeInvalid = false"
          />
        </div>
        <div class="dosyearinput compare-box-sec-row">
          <div class="compare-box-label">
            Risk Model:
            <v-icon
              color="black"
              class="p-1"
              size="12"
              dark
              v-tippy="{
                theme: 'epicc',
                arrow: true,
                content: tooltipDataForRiskModel,
              }"
              >fas fa-question-circle</v-icon
            >
          </div>
          <div class="dRiskinput">
            <risk-model
              :dos-year="dosYearModel"
              @on-risk-model="getRiskModel"
              :component-data-index="componentDataIndex"
              :risk-model="riskModel"
            />
          </div>
        </div>
      </div>
      <div class="compare-box-between compare-code-dos-year">
        <div class="compare-box-label">DOS Year/Model:</div>
        <dos-year-model
          @on-dos-year="getDosYear"
          :component-data-index="componentDataIndex"
          :dos-year-model="dosYearModel"
        />
        <div class="baserateinput compare-box-sec-row">
          <div class="compare-box-label">
            Base Rate :
            <v-icon
              color="black"
              class="p-1"
              size="12"
              dark
              v-tippy="{
                theme: 'epicc',
                arrow: true,
                content: tooltipDataForBaserate,
              }"
              >fas fa-question-circle</v-icon
            >
          </div>
          <base-rate-input
            @on-base-rate="getBaseRate"
            :component-data-index="componentDataIndex"
            :base-rate="baseRateValue"
            @valid-base-rate="isBaseRateInvalid = false"
            :is-base-rate-invalid="isBaseRateInvalid"
          />
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label agelabel">Gender:</div>
        <div class="ageinput">
          <gender
            :gender="genderValue"
            :component-data-index="componentDataIndex"
            @on-gender="setGender"
          />
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label platelabel">
          Plate:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForPlate,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box dosyearinput">
          <plate-input
            :plate-value="plateValue"
            @update-plate-value="setPlateValue"
          />
        </div>
      </div>

      <div class="compare-box-between">
        <div class="compare-box-label platelabel">Enrollment Duration:</div>
        <div class="box enrollmentinput">
          <enrollment-input
            :enrollment-duration="enrollmentDuration"
            @update-enrollment-value="setEnrollmentValue"
          />
        </div>
      </div>
      <div class="compare-box-between">
        <div class="compare-box-label platelabel">
          Date Of Birth:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForDob,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="box enrollmentinput">
          <dob-input
            :calculation-year="calculationYear"
            :date-of-birth-value="DOB"
            @date-of-birth-update="setDateOfBirth"
          />
        </div>
      </div>
      <div class="compare-box-between compare-code-calculation-year">
        <div class="compare-box-label agelabel">
          Calculation Year:
          <v-icon
            color="black"
            class="p-1"
            size="12"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: tooltipDataForCalculationYear,
            }"
            >fas fa-question-circle</v-icon
          >
        </div>
        <div class="ageinput">
          <calculation-year-input
            :error="dobErrorMessage"
            :dob="DOB"
            :calculation-year="calculationYear"
            :component-data-index="componentDataIndex"
            @calculation-year-update="setCalculationYear"
          />
        </div>
      </div>

      <div class="submitclear submitclear-compare-code">
        <v-btn class="submit button" @click="submit">Submit</v-btn>
        <v-btn class="clear button" @click="clearResult">Clear</v-btn>

        <v-btn
          class="add"
          :class="isButtonVisible ? 'showBtn' : 'hideBtn'"
          @click="addRow"
        >
          Add new
        </v-btn>

        <v-btn
          v-if="componentDataIndex !== 0"
          class="close button"
          @click="deleteRow"
        >
          <v-icon
            color="white"
            class="p-1"
            size="20"
            dark
            v-tippy="{
              theme: 'epicc',
              arrow: true,
              content: 'Close',
            }"
            >fas fa-trash</v-icon
          >
        </v-btn>
      </div>
      <div class="compare-code-summary">
        <v-snackbar v-model="snackbar" class="snackbar">
          <div class="snackbar-text">
            {{ errorMessage }}
          </div>
          <template #actions>
            <v-btn color="pink" text @click="snackbar = false">Close</v-btn>
          </template>
        </v-snackbar>
        <div class="progress-bar">
          <v-progress-circular
            v-show="isWaitingForResult"
            color="light-blue"
            indeterminate
            rounded
            height="6"
          ></v-progress-circular>
        </div>

        <result-table
          v-if="APIResponse.length !== 0"
          class="compare-result"
          :component-data-index="componentDataIndex"
        />
        <v-btn
          class="output-btn"
          color="grey"
          @click="showDetailedOutput"
          v-if="isDetailButtonVisible"
          >Detailed Output</v-btn
        >
        <v-btn
          class="output-btn"
          color="grey"
          @click="hideDetailedOutput"
          v-if="isCloseButtonVisible"
          >Close</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import {
  scrollToBottom,
  scrollToBottomContainer,
} from '../../common/helperFunctions'

// Import components with consistent naming
import CodeInput from '../../common/CodeInput.vue'
import AgeInput from '../../common/AgeInput.vue'
import RiskModel from '../../common/RiskModel.vue'
import DosYearModel from '../../common/DosYearModel.vue'
import BaseRateInput from '../../common/BaseRateInput.vue'
import SearchComponent from '../../common/SearchComponent.vue'
import ResultTable from '../results/CompareCodeResultTable.vue'
import CalculationYearInput from '../../common/CalculationYearInput.vue'
import DobInput from '../../common/DOBInput.vue'
import Gender from '../../common/Gender.vue'
import PlateInput from '../../common/PlateInput.vue'
import EnrollmentInput from '../../common/EnrollmentInput.vue'

import '../../../assets/css/compare.css'

// Props definition
const props = defineProps({
  componentDataIndex: {
    type: Number,
    required: true,
  },
})

// Use store
const store = useStore()

// Refs for form inputs
const tag1 = ref('')
const tag2 = ref('')
const isClear = ref(false)
const isClear2 = ref(false)
const calculationYear = ref(null)
const DOB = ref('')
const enrollmentDuration = ref('')
const baseCodeInputRef = ref(null)
const newCodeInputRef = ref(null)
const dialog = ref(false)
const snackbar = ref(false)
const isDetailButtonVisible = ref(false)
const isCloseButtonVisible = ref(false)
const isWaitingForResult = ref(false)

// Refs for collection values
const baseConditionTagList = ref([])
const newConditionTagList = ref([])
const baseInvalidChips = ref([])
const newInvalidChips = ref([])

// Reactive state for error handling and tooltips
const state = reactive({
  tooltipDataForBaseCondition:
    'Conditions eligible as per selected dos year model.',
  tooltipDataForNewCondition:
    'List of codes against which comparison is to be made',
  tooltipDataForBaserate: 'Base reimbursement premium for member',
  tooltipDataForCalculationYear:
    '1st Jan of the input calculation year will be used along with DOB to calculate age',
  tooltipDataForRiskModel:
    'List of risk models eligible as per selected dos year model',
  tooltipDataForDob:
    'DOB will not be considered in any calculation(s) if age is provided',
  tooltipDataForAge: 'Acceptable age values are from 0 to 110',
  tooltipDataForPlate: 'Applicable only when the Commercial Model is in use',
  errorMessage: '',
  invalidTags: [],
  isBaseTagListEmpty: false,
  isNewTagListEmpty: false,
  isAgeInvalid: false,
  isBaseRateInvalid: false,
  DobMoment: '',
  dobErrorMessage: null,
  searchData: '',
  newConditionsList: [],
  HHS_DEFAULT_RISK_MODEL: 'ADULT',
  V05_DEFAULT_RISK_MODEL: 'D1',
  V_DEFAULT_RISK_MODEL: 'CN',
  V24_ESRD_DEFAULT_RISK_MODEL: 'C3',
  V21_ESRD_DEFAULT_RISK_MODEL: 'C1',
  PACE_V21_ESRD_DEFAULT_RISK_MODEL: 'PA',
  PACE_V_DEFAULT_RISK_MODEL: 'PK',
  PACE_V05_DEFAULT_RISK_MODEL: 'P7',
})

// Computed props from store data
const compareCodeData = computed(() =>
  store.getters.getComponentDataByIndex(props.componentDataIndex)
)

const APIResponse = computed(
  () =>
    store.getters.getComponentDataByIndex(props.componentDataIndex)
      ?.responseData?.data || []
)

// Input values with getters/setters to properly sync with store
const ageValue = computed(() => compareCodeData.value?.inputDict.age)
const baseRateValue = computed(() => compareCodeData.value?.inputDict.baseRate)
const genderValue = computed(() => compareCodeData.value?.inputDict.gender)
const plateValue = computed(() => compareCodeData.value?.inputDict.plate)
const dosYearModel = computed(
  () => compareCodeData.value?.inputDict.dosYearModel
)
const riskModel = computed(() => compareCodeData.value?.inputDict.riskModel)
const commandType = computed(() => compareCodeData.value?.inputDict.commandType)

// Initialize values from store on mount
onMounted(() => {
  if (compareCodeData.value) {
    const data = compareCodeData.value.inputDict

    // Initialize tag lists
    if (data.baseConditions)
      baseConditionTagList.value = [...data.baseConditions]
    if (data.newConditions) newConditionTagList.value = [...data.newConditions]

    // Initialize other form values
    if (data.DOB) {
      DOB.value = data.DOB
      state.DobMoment = moment(data.DOB).format()
    }

    if (data.calculationYear) calculationYear.value = data.calculationYear
    if (data.enrollmentDuration)
      enrollmentDuration.value = data.enrollmentDuration
  }

  // Set default risk model if DOS year is already set but risk model isn't
  if (dosYearModel.value && !riskModel.value) {
    setDefaultRiskModel(dosYearModel.value)
  }
})

// Watch for changes in DOS year to update risk model
watch(
  () => dosYearModel.value,
  (newValue) => {
    if (newValue && !riskModel.value) {
      setDefaultRiskModel(newValue)
    }
  }
)

// Event handlers and methods
const updateTagLists = (newTagList, listName) => {
  if (listName === 'baseConditions') {
    baseConditionTagList.value = [...newTagList]
    store.dispatch('updateStateData', {
      index: props.componentDataIndex,
      data: { name: 'baseConditions', value: newTagList },
    })
  } else {
    newConditionTagList.value = [...newTagList]
    store.dispatch('updateStateData', {
      index: props.componentDataIndex,
      data: { name: 'newConditions', value: newTagList },
    })
  }
}

const getInvalidChips = (chips, chipTypeRef) => {
  // Update the reactive ref array
  chipTypeRef.value = [...chips]
}

const setFieldValue = (fieldValue, fieldName) => {
  const currentIndex = props.componentDataIndex
  const data = {
    name: fieldName,
    value: fieldValue,
  }

  // Update current component data
  store.dispatch('updateStateData', {
    index: currentIndex,
    data: data,
  })

  // Check if next component should also be updated
  const nextIndex = currentIndex + 1
  if (isNextPickCommand(nextIndex)) {
    store.dispatch('updateStateData', {
      index: nextIndex,
      data: data,
    })
  }
}

const setEnrollmentValue = (value) => {
  enrollmentDuration.value = value
  setFieldValue(value, 'enrollmentDuration')
}

const calculateAge = (date, calculationYearValue) => {
  if (!date || !calculationYearValue) return null

  // Parse date using moment.js
  const parseDate = moment(date)
  if (!parseDate.isValid()) return null

  const dobYear = parseDate.year()
  const dobMonth = parseDate.month() + 1
  const dobDay = parseDate.date()

  const currYear = moment().year()
  const currMonth = moment().month() + 1
  const currDay = moment().date()

  // Create moment objects for calculation
  const dobDate = moment([dobYear, dobMonth - 1, dobDay])
  let calculationYearDate = moment([calculationYearValue, 0, 1]) // January 1st of calculation year

  // If DOB is in the same year as calculation year, use current date
  if (dobYear == calculationYearValue) {
    calculationYearDate = moment([currYear, currMonth - 1, currDay])
  }

  // Calculate differences
  const diffYear = calculationYearDate.diff(dobDate, 'year', true)

  // Ensure non-negative age
  return diffYear >= 0 ? Math.floor(diffYear) : null
}

const setCalculatedAge = (calculatedAge, calculationYearValue) => {
  if (!state.DobMoment) return

  const yearInDob = moment(state.DobMoment).year()
  if (calculatedAge !== null && yearInDob < calculationYearValue) {
    state.dobErrorMessage = null
    getAge(calculatedAge)
  } else if (yearInDob >= calculationYearValue) {
    state.dobErrorMessage = 'Calculation year should be greater than DOB year.'
    getAge(0)
  }
}

const setDateOfBirth = (date, dateForMoment) => {
  DOB.value = date
  state.DobMoment = dateForMoment
  setFieldValue(date, 'DOB')

  if (calculationYear.value) {
    const calculatedAge = calculateAge(dateForMoment, calculationYear.value)
    setCalculatedAge(calculatedAge, calculationYear.value)
  }
}

const setGender = (value) => {
  setFieldValue(value, 'gender')
}

const setPlateValue = (value) => {
  setFieldValue(value, 'plate')
}

const setCalculationYear = (value) => {
  calculationYear.value = value

  if (state.DobMoment) {
    const calculatedAge = calculateAge(state.DobMoment, value)
    setCalculatedAge(calculatedAge, value)
  }

  setFieldValue(value, 'calculationYear')
}

const getAge = (value) => {
  setFieldValue(value, 'age')
}

const getBaseRate = (value) => {
  setFieldValue(value, 'baseRate')
}

const setDefaultRiskModel = (value) => {
  let defaultRiskModelValue = ''
  if (store.getters.hasHhsData(value)) {
    defaultRiskModelValue = state.HHS_DEFAULT_RISK_MODEL
  } else if (store.getters.hasV05Data(value)) {
    defaultRiskModelValue = state.V05_DEFAULT_RISK_MODEL
  } else if (store.getters.hasv21ESRDData(value)) {
    defaultRiskModelValue = state.V21_ESRD_DEFAULT_RISK_MODEL
  } else if (store.getters.hasv24ESRDData(value)) {
    defaultRiskModelValue = state.V24_ESRD_DEFAULT_RISK_MODEL
  } else if (store.getters.hasPaceV21ESRDData(value)) {
    defaultRiskModelValue = state.PACE_V21_ESRD_DEFAULT_RISK_MODEL
  } else if (store.getters.hasPaceVData(value)) {
    defaultRiskModelValue = state.PACE_V_DEFAULT_RISK_MODEL
  } else if (store.getters.hasPaceV05Data(value)) {
    defaultRiskModelValue = state.PACE_V05_DEFAULT_RISK_MODEL
  } else {
    defaultRiskModelValue = state.V_DEFAULT_RISK_MODEL
  }
  getRiskModel(defaultRiskModelValue)
}

const getDosYear = (value) => {
  setDefaultRiskModel(value)
  setFieldValue(value, 'dosYearModel')

  // Revalidate tags in both code input components
  if (
    baseCodeInputRef.value &&
    typeof baseCodeInputRef.value.revalidateTags === 'function'
  ) {
    baseCodeInputRef.value.revalidateTags(value)
  }

  if (
    newCodeInputRef.value &&
    typeof newCodeInputRef.value.revalidateTags === 'function'
  ) {
    newCodeInputRef.value.revalidateTags(value)
  }
}

const getRiskModel = (value) => {
  setFieldValue(value, 'riskModel')
}

const isNextPickCommand = (nextIndex) => {
  const nextComponentData = store.getters.getComponentDataByIndex(nextIndex)
  if (nextComponentData) {
    return nextComponentData.inputDict.commandType === 'pick'
  }
  return false
}

const isTagInvalid = (tag) => {
  state.invalidTags = [...baseInvalidChips.value, ...newInvalidChips.value]
  return state.invalidTags.includes(tag)
}

const isTagListEmpty = () => {
  state.isBaseTagListEmpty = baseConditionTagList.value.length === 0
  state.isNewTagListEmpty = newConditionTagList.value.length === 0
  return state.isBaseTagListEmpty || state.isNewTagListEmpty
}

const areInputsInvalid = () => {
  const ageVal = ageValue.value
  const baseRateVal = baseRateValue.value

  state.isAgeInvalid = ageVal === null || ageVal < 0 || ageVal > 110
  state.isBaseRateInvalid =
    baseRateVal === null || baseRateVal < 0 || isNaN(parseFloat(baseRateVal))

  return state.isAgeInvalid || state.isBaseRateInvalid || isTagListEmpty()
}

const areAllTagsInvalid = () => {
  // If there are no tags or inputs are invalid, return true
  if (isTagListEmpty() || areInputsInvalid()) return true

  // Check if all tags in both lists are invalid
  const allBaseInvalid =
    baseConditionTagList.value.length > 0 &&
    baseConditionTagList.value.every(isTagInvalid)
  const allNewInvalid =
    newConditionTagList.value.length > 0 &&
    newConditionTagList.value.every(isTagInvalid)

  return allBaseInvalid || allNewInvalid
}

const submit = () => {
  if (areAllTagsInvalid()) {
    state.errorMessage = 'All condition codes are invalid'
    snackbar.value = true
  } else if (!areInputsInvalid()) {
    isWaitingForResult.value = true
    store
      .dispatch('handleSubmitRequest', {
        index: props.componentDataIndex,
      })
      .then(() => {
        isWaitingForResult.value = false
      })
      .catch((error) => {
        console.error('Error during submit:', error)
        isWaitingForResult.value = false
        state.errorMessage = 'Error submitting request'
        snackbar.value = true
      })
  } else {
    state.errorMessage = 'Please correct invalid inputs'
    snackbar.value = true
  }

  setTimeout(() => scrollToBottom('compare', props.componentDataIndex), 500)
}

const addRow = () => {
  try {
    store
      .dispatch('addRowToComponentData', {
        index: props.componentDataIndex,
      })
      .then(() => {
        setTimeout(
          () => scrollToBottomContainer(props.componentDataIndex + 1),
          300
        )
      })
  } catch (error) {
    console.error('Error in addRow function:', error)
  }
}

const clearResult = () => {
  isClear.value = true
  isClear2.value = true
  isDetailButtonVisible.value = false
  isCloseButtonVisible.value = false
  baseConditionTagList.value = []
  newConditionTagList.value = []
  state.isBaseTagListEmpty = false
  state.isNewTagListEmpty = false
  state.isAgeInvalid = false
  state.isBaseRateInvalid = false
  isWaitingForResult.value = false

  // Update store with cleared values
  store.dispatch('updateStateData', {
    index: props.componentDataIndex,
    data: { name: 'baseConditions', value: [] },
  })

  store.dispatch('updateStateData', {
    index: props.componentDataIndex,
    data: { name: 'newConditions', value: [] },
  })

  store.dispatch('clearResult', {
    index: props.componentDataIndex,
    commandType: 'compare',
  })

  // Reset invalid chips
  baseInvalidChips.value = []
  newInvalidChips.value = []

  // Reset refs if available
  if (
    baseCodeInputRef.value &&
    typeof baseCodeInputRef.value.resetInvalidChips === 'function'
  ) {
    baseCodeInputRef.value.resetInvalidChips()
  }

  if (
    newCodeInputRef.value &&
    typeof newCodeInputRef.value.resetInvalidChips === 'function'
  ) {
    newCodeInputRef.value.resetInvalidChips()
  }
}

const deleteRow = () => {
  store.dispatch('deleteRow', {
    index: props.componentDataIndex,
  })
}

const toggleModal = (value) => {
  dialog.value = value

  // Process selected search data from localStorage
  let searchedCodeString = localStorage.getItem('selectedSearchData')
  if (searchedCodeString) {
    let searchedCodeList = searchedCodeString.split(',')
    state.searchData = searchedCodeString

    // Process each code in the list
    searchedCodeList.forEach((item) => {
      item = item.trim()
      if (item.length > 0) {
        // Add to appropriate tag list based on UI state
        if (dialog.value) {
          // Pending implementation - depends on how the dialog uses tags
        }
      }
    })

    // Reset localStorage
    localStorage.removeItem('selectedSearchData')
  }
}

const splitAndGetNewConditionValue = (
  textvalue,
  separators = [' ', ',', ':', ';']
) => {
  if (textvalue) {
    let tempChar = separators[0] // Use first token as temporary join character
    for (let i = 1; i < separators.length; i++) {
      textvalue = textvalue.split(separators[i]).join(tempChar)
    }
    state.newConditionsList = textvalue
      .split(tempChar)
      .filter((item) => item.trim() !== '')
  }
}

const addTagFromList = (item, tagNum) => {
  if (!item || !tagNum) return

  // Trim the item to avoid whitespace issues
  item = item.trim()
  if (item === '') return

  // Using DOM queries as fallback - consider switching to refs
  if (tagNum === 'tag1') {
    const codeInput = document.querySelector('#CodeInput1')
    if (codeInput) {
      codeInput.tag = item
      codeInput.addTag({ key: 'Enter' })
    }
  } else if (tagNum === 'tag2') {
    const codeInput = document.querySelector('#CodeInput2')
    if (codeInput) {
      codeInput.tag = item
      codeInput.addTag({ key: 'Enter' })
    }
  }
}

// Visibility computed property
const isButtonVisible = computed(() => {
  return (
    props.componentDataIndex === store.state.componentData.length - 1 &&
    store.state.componentData[props.componentDataIndex]
      ?.isSubmitButtonClicked === true
  )
})
</script>
<style>
.snackbar > div > div {
  display: flex;
  align-content: center;
  justify-content: space-around;
}
.snackbar-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.compare-code-summary {
  grid-column-start: 1;
  grid-column-end: 5;
}
.submitclear-compare-code {
  grid-column-start: 1;
  grid-column-end: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}
.submitclear .hideBtn {
  display: none;
}

.showBtn {
  /* Make button visible when condition is true */
  display: block;
}
@media only screen and (max-device-width: 1024px) {
  .compare-result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .summary-table {
    margin: 0px !important;
    padding: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    gap: 10px;
  }
  .summary-table-2 {
    margin: 0px !important;
    width: 100% !important;
  }
  .detail-box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    margin-top: 15px !important;
  }
  .detail-table {
    width: 100%;
    overflow-x: scroll;
  }
  .submitclear-compare-code {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
  }
  .submitclear-compare-code > button {
    margin: 0px !important;
    font-size: 12px !important;
    width: 75px !important;
  }
  .detail-button > button {
    min-width: 85px !important;
    font-size: 12px !important;
    height: 40px !important;
  }
  .summary-table-header {
    width: 100% !important;
  }
}
</style>
