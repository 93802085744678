<template>
  <table class="table-style-main">
    <thead>
      <tr v-if="headers_required == true">
        <td class="excel-notations-cell"></td>
        <td
          class="excel-notations-cell excel-notations-cell-alphabetical"
          v-for="(data, key, index) in table_data[0]"
          :key="index"
        >
          {{ columnHeaders[index + Number(start_index)] }}
        </td>
      </tr>
      <tr class="tr-style">
        <td v-if="headers_required == true" class="excel-notations-cell">1</td>
        <td
          v-for="(data, key, index) in table_data[0]"
          :key="index"
          class="table-titles"
        >
          {{
            key === 'command'
              ? 'Functions'
              : key === 'argument'
                ? 'Arguments'
                : key
          }}
        </td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in table_data" :key="data.name">
        <td
          v-if="headers_required == true"
          class="excel-notations-cell excel-notations-cell-numeric"
        >
          {{ index + 2 }}
        </td>
        <td
          v-for="(d, key, ind) in table_data[0]"
          :key="ind"
          v-tippy="
            data[key] != null &&
            typeof data[key] == 'string' &&
            data[key].indexOf('=epi.') !== -1
              ? {
                  theme: 'epicc',
                  arrow: true,
                  content:
                    showCopyToolTip == false
                      ? 'Copy command to clipboard'
                      : 'Copied',
                }
              : {
                  onshow: () => false,
                }
          "
          @click="
            ;(showCopyToolTip = showCopyToolTip == false ? true : false) &&
              data[key] != null &&
              typeof data[key] == 'string' &&
              data[key].indexOf('=epi.') !== -1 &&
              copyToClipboard(data[key])
          "
          v-bind:class="[
            data[key] != null &&
            typeof data[key] == 'string' &&
            data[key].indexOf('=epi.') !== -1
              ? 'hover-cells'
              : '',
          ]"
          @mouseleave="showCopyToolTip = false"
        >
          {{ data[key] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['table_data', 'headers_required', 'start_index'],
  data() {
    return {
      // columnHeaders: getColumns(50)
      columnHeaders: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
      showCopyToolTip: false,
    }
  },
  methods: {
    getColumns(maxCols) {
      let cols = []
      let ALPHABETS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      for (let i = 0; i < maxCols; i++) {
        if (i < 26) {
          cols.push(ALPHABETS[i])
        } else {
          let firstChar = ALPHABETS[i / 26 - 1]
          let secondChar = ALPHABETS[i % 26]
          cols.push(`${firstChar}${secondChar}`)
        }
      }
      return cols
    },
    copyToClipboard(message) {
      navigator.clipboard
        .writeText(message)
        .then(() => {})
        .catch(() => {})
    },
  },
}
</script>

<style>
.heading-box {
  background: #008c50;
  font-size: 24px;
  font-weight: 400;
  color: white;
  padding: 1px 1px 1px 20px;
}

.subheading-box {
  width: 99.1%;
  margin-bottom: 10px;
  margin-top: 10px;
  /* background: #0099d9; */
  min-height: 36px;
  font-weight: 400;
  font-size: 20px;
  color: black;
  margin-top: 10px;
  padding-left: 10px;
  padding-top: 3px;
}

.head-content {
  font-size: 16px;
  font-weight: 400;
}

.feature-content {
  font-size: 16px;
  font-weight: 400;
}

.table-style-main {
  width: 99%;
  border-collapse: collapse;
}

.tr-style {
  background: #008c50;
  color: white;
  height: 36px;
}

th {
  padding: 5px;
}

th,
td {
  border: 1.5px solid #ddd;
  position: relative;
}

td {
  font-weight: 400;
  padding: 7px;
}

.excel-notations-cell {
  background: #f2f1f1 !important;
  border: 0.4px solid #bbb;
  color: black;
  justify-content: center;
  text-align: center;
}

.excel-notations-cell-numeric {
  width: 30px !important;
}

.excel-notations-cell-alphabetical {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
}

.table-titles {
  text-transform: capitalize;
}
.formulas {
  position: absolute;
  right: 0px;
  top: 2px;
}
.hover-cells {
  cursor: pointer;
}
.hover-cells:hover {
  color: #636363;
}
</style>
