<template>
  <div style="width: 90%; margin: 0px 0px 0px 5%" class="reference-container">
    <HccRef />
  </div>
</template>

<style scoped>
.reference-container {
  margin-top: 200px !important;
}
</style>
<script>
import HccReference from '../common/HccReference.vue'
export default {
  components: {
    HccRef: HccReference,
  },
}
</script>
