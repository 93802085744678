import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Views/Home.vue'
import HccReference from '@/components/Views/HccReferencePage.vue'
import PageNotFound from '@/components/Views/404.vue'
import Support from '@/components/Views/Support.vue'
import EULA from '@/components/Views/EULA.vue'
import Privacy from '@/components/Views/Privacy.vue'
import Upload from '@/components/Views/Upload.vue'
import BulkCalculation from '@/components/Views/BulkCalcutionPage.vue'
import UnderMaintenancePage from '@/components/Views/Undermaintainence.vue'
const IS_UNDER_MAINTENANCE = false
const default_route = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/reference',
    component: HccReference,
  },
  {
    path: '/upload',
    component: Upload,
  },
  {
    path: '/upload/:id',
    component: BulkCalculation,
  },
  {
    path: '/privacy',
    component: Privacy,
  },
  {
    path: '/eula',
    component: EULA,
  },
  {
    path: '/support',
    component: Support,
  },
  {
    path: '/:catchAll(.*)', // Updated for Vue Router 4 to handle 404
    component: PageNotFound,
  },
]
const sec_domain_route = [
  {
    path: '/',
    component: HccReference,
  },
  {
    path: '/privacy',
    component: Privacy,
  },
  {
    path: '/eula',
    component: EULA,
  },
  {
    path: '/support',
    component: Support,
  },
  {
    path: '/:catchAll(.*)', // Updated 404 route
    component: PageNotFound,
  },
]

const under_maintenance_routes = [
  {
    path: '/:pathMatch(.*)*',
    component: UnderMaintenancePage,
  },
]

const support_route = [
  {
    path: '/support',
    component: Support,
  },
]
let routes = default_route
if (
  window.location.host === 'hccreference.com' ||
  window.location.host === 'www.hccreference.com'
) {
  routes = sec_domain_route
}
if (IS_UNDER_MAINTENANCE && window.location.pathname != '/support') {
  routes = under_maintenance_routes
} else if (window.location.pathname == '/support') {
  routes = support_route
}
const router = createRouter({
  history: createWebHistory(),
  routes,
})
export default router
