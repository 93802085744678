<template>
  <footer class="footer">
    <div class="outer-div">
      <img src="../../assets/img/epi-logo.svg" class="footer-main-img" />
      <a class="footer-mail-link" href="mailto:support.epicc@episource.com"
        ><i class="fa fa-envelope" aria-hidden="true"></i
        >support.epicc@episource.com</a
      >
    </div>
  </footer>
</template>

<style scoped>
.footer {
  padding: 25px 40px;
  text-align: center;
  background: #f3f1ee;
}
.outer-div {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.footer-main-img {
  height: 28px;
  padding-left: 22px;
}

.fa-envelope {
  margin-right: 8px;
  display: flex;
  align-content: end;
  justify-content: center;
  flex-direction: column;
}

.footer-mail-link {
  transform: translateY(max(0.2vw, 3px));
  font-size: max(0.9vw, 15px);
  color: rgb(84, 84, 84);
  font-weight: normal;
  padding-right: 7px;
  display: flex;
  align-content: start;
  justify-content: center;
  padding-bottom: 2px;
}

@media only screen and (max-device-width: 767px) {
  .footer-main-img {
    height: 20px;
    padding-left: 22px;
  }
  .footer-mail-link {
    transform: translateY(max(0.2vw, 3px));
    font-size: 14px;
    color: rgb(84, 84, 84);
    font-weight: normal;
    padding-right: 7px;
  }
  .outer-div {
    gap: 10px !important;
    display: flex;
    align-items: flex-end;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
