<template>
  <v-layout>
    <v-select
      class="plateInput"
      dense
      flat
      outlined
      full-width
      :disabled="isDisabled"
      :items="items"
      item-title="label"
      item-value="value"
      v-model="selectedPlateValue"
      color="0099D9_1"
      style="color: rgb(84, 84, 84) !important"
    ></v-select>
  </v-layout>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import plateData from '../../data/plateData.json'
// Vuex Store
const store = useStore()
// Props
const props = defineProps({
  componentDataIndex: Number,
  plateValue: String,
})
// Emits
const emit = defineEmits(['updatePlateValue'])
// Ensure proper item structure for Vuetify
const items = plateData.map((item) => ({
  label: item.text || item.label, // Adjust based on JSON structure
  value: item.value,
}))
// Computed property for v-model binding
const selectedPlateValue = computed({
  get: () => props.plateValue,
  set: (value) => {
    emit('updatePlateValue', value)
  },
})
// Computed property to check if 'hhs' exists in `dosYearModel`
const isDisabled = computed(() => {
  const dosYearModel = store.state?.componentData?.[0]?.inputDict?.dosYearModel
  if (!dosYearModel) return false // If undefined, default to false (enabled)
  return dosYearModel.toLowerCase().includes('hhs') // Disable if 'hhs' exists
})
</script>
