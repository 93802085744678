<template>
  <div class="color-indicators">
    <div v-if="getAvailableTags()" class="text-left">
      <span class="dot-green"></span>
      <span class="label">Codes are valid in this DOS Year/Model</span>
    </div>
    <div v-if="hasNotFoundCodes()" class="text-left">
      <span class="dot-amber"></span>
      <span class="label">
        Codes are not valid in this DOS Year/Model but are valid in another DOS
        Year/Model
      </span>
    </div>
    <div v-if="hasInvalidChips()" class="text-left">
      <span class="dot-red"></span>
      <span class="label">Codes are not valid in any DOS year/Model</span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'

// Define props
const props = defineProps({
  invalidChips: {
    type: Array,
    default: () => [],
  },
  tags: {
    type: Array,
    default: () => [],
  },
  notFoundCodes: {
    type: Array,
    default: () => [],
  },
})

// Method to get available tags
const getAvailableTags = () => {
  const newTagsList = props.tags.filter(
    (tag) =>
      !props.invalidChips.includes(tag) && !props.notFoundCodes.includes(tag)
  )
  return newTagsList.length > 0
}

// Method to check for invalid chips
const hasInvalidChips = () => {
  return props.invalidChips.length > 0
}

// Method to check for not found codes
const hasNotFoundCodes = () => {
  return props.notFoundCodes.length > 0 && props.tags.length > 0
}
</script>

<style scoped>
/* CSS will remain the same, using the imported stylesheet */
@import '../../assets/css/chipsIndicator.css';
</style>
