<template>
  <v-layout>
    <v-select
      class="plateInput"
      dense
      flat
      outlined
      full-width
      :items="items"
      item-title="label"
      item-value="value"
      v-model="selectedEnrollmentDuration"
      color="0099D9_1"
      style="color: rgb(84, 84, 84) !important"
    ></v-select>
  </v-layout>
</template>
<script setup>
import { computed } from 'vue'
import enrollmentData from '../../data/enrollmentData.json'
// Props
const props = defineProps({
  componentDataIndex: Number,
  enrollmentDuration: String,
})
// Emits
const emit = defineEmits(['updateEnrollmentValue'])
// Ensure proper item structure for Vuetify
const items = enrollmentData.map((item) => ({
  label: item.text || item.label, // Adjust based on JSON structure
  value: item.value,
}))
// Computed property for v-model binding
const selectedEnrollmentDuration = computed({
  get: () => props.enrollmentDuration,
  set: (value) => {
    emit('updateEnrollmentValue', value)
  },
})
</script>
